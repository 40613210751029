import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

// Starter Kit
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';

// App
import appReducer from './appReducer';

// Account
import accountReducer from './accountReducer';
import meReducer from './meReducer';
import mystatusReducer from './mystatusReducer';
import mytenantReducer from './mytenantReducer';
import mymemberReducer from './mymemberReducer';
import myorganizationReducer from './myorganizationReducer';
import mydelegateReducer from './mydelegateReducer';
import mycontactReducer from './mycontactReducer';
import mysiteReducer from './mysiteReducer';
import mydelegatesiteReducer from './mydelegatesiteReducer';

// Manage
import applicationsReducer from './applicationsReducer';
import appointmentsReducer from './appointmentsReducer';
import appservicesReducer from './appservicesReducer';
import contractsReducer from './contractsReducer';
import countriesReducer from './countriesReducer';
import currenciesReducer from './currenciesReducer';
import deploymentsReducer from './deploymentsReducer';
import deploymentitemsReducer from './deploymentitemsReducer';
import functionsReducer from './functionsReducer';
import languagesReducer from './languagesReducer';
import licensesReducer from './licensesReducer';
import loginsReducer from './loginsReducer';
import membersReducer from './membersReducer';
import organizationsReducer from './organizationsReducer';
import revisionsReducer from './revisionsReducer';
import servicefunctionsReducer from './servicefunctionsReducer';
import servicemodulesReducer from './servicemodulesReducer';
import systemsReducer from './systemsReducer';
import tenantsReducer from './tenantsReducer';
import usersReducer from './usersReducer';
import webhooksReducer from './webhooksReducer';

// Organization
import absencesReducer from './absencesReducer';
import absencetypesReducer from './absencetypesReducer';
import actioncontactsReducer from './actioncontactsReducer';
import activityattachmentsReducer from './activityattachmentsReducer';
import activitiesReducer from './activitiesReducer';
import adviceattachmentsReducer from './adviceattachmentsReducer';
import advicesReducer from './advicesReducer';
import areasReducer from './areasReducer';
import assignmentattachmentsReducer from './assignmentattachmentsReducer';
import assignmentsReducer from './assignmentsReducer';
import assortmentproductsReducer from './assortmentproductsReducer';
import assortmentsReducer from './assortmentsReducer';
import attachmentsReducer from './attachmentsReducer';
import attendattachmentsReducer from './attendattachmentsReducer';
import attendsReducer from './attendsReducer';
import attendeeattachmentsReducer from './attendeeattachmentsReducer';
import attendeecatsReducer from './attendeecatsReducer';
import attendeesReducer from './attendeesReducer';
import authorsReducer from './authorsReducer';
import billingrunsReducer from './billingrunsReducer';
import billingsReducer from './billingsReducer';
import blogsReducer from './blogsReducer';
import bookingattachmentsReducer from './bookingattachmentsReducer';
import bookingsReducer from './bookingsReducer';
import bundlesReducer from './bundlesReducer';
import businesspartnerattachmentsReducer from './businesspartnerattachmentsReducer';
import businesspartnercatsReducer from './businesspartnercatsReducer';
import businesspartnergroupsReducer from './businesspartnergroupsReducer';
import businesspartnersReducer from './businesspartnersReducer';
import calendarsReducer from './calendarsReducer';
import capacitiesReducer from './capacitiesReducer';
import catalogsReducer from './catalogsReducer';
import channelsReducer from './channelsReducer';
import channelsyncsReducer from './channelsyncsReducer';
import channelusersReducer from './channelusersReducer';
import chaptersReducer from './chaptersReducer';
import commentattachmentsReducer from './commentattachmentsReducer';
import commentsReducer from './commentsReducer';
import competencesReducer from './competencesReducer';
import consumptionsReducer from './consumptionsReducer';
import contactattachmentsReducer from './contactattachmentsReducer';
import contactsReducer from './contactsReducer';
import datasReducer from './datasReducer';
import delegatesitesReducer from './delegatesitesReducer';
import delegatesReducer from './delegatesReducer';
import departmentsReducer from './departmentsReducer';
import docsReducer from './docsReducer';
import educationsReducer from './educationsReducer';
import emailattachmentsReducer from './emailattachmentsReducer';
import emailsReducer from './emailsReducer';
import emailstatesReducer from './emailstatesReducer';
import employeeattachmentsReducer from './employeeattachmentsReducer';
import employeesReducer from './employeesReducer';
import eventcatsReducer from './eventcatsReducer';
import followsReducer from './followsReducer';
import forumsReducer from './forumsReducer';
import gradesReducer from './gradesReducer';
import guidelinesReducer from './guidelinesReducer';
import guidesReducer from './guidesReducer';
import installationsReducer from './installationsReducer';
import institutesReducer from './institutesReducer';
import iterationsReducer from './iterationsReducer';
import jobattachmentsReducer from './jobattachmentsReducer';
import jobcatsReducer from './jobcatsReducer';
import jobgroupsReducer from './jobgroupsReducer';
import jobrelationsReducer from './jobrelationsReducer';
import jobrelationtypesReducer from './jobrelationtypesReducer';
import jobtagsReducer from './jobtagsReducer';
import mailinglistcontactsReducer from './mailinglistcontactsReducer';
import mailinglistsReducer from './mailinglistsReducer';
import menuitemsReducer from './menuitemsReducer';
import menusReducer from './menusReducer';
import messageattachmentsReducer from './messageattachmentsReducer';
import messagesReducer from './messagesReducer';
import messagetagsReducer from './messagetagsReducer';
import newsletterattachmentsReducer from './newsletterattachmentsReducer';
import newslettercatsReducer from './newslettercatsReducer';
import newslettercontactsReducer from './newslettercontactsReducer';
import newslettergroupsReducer from './newslettergroupsReducer';
import newslettersReducer from './newslettersReducer';
import newslettertagsReducer from './newslettertagsReducer';
import notificationrunsReducer from './notificationrunsReducer';
import notificationsReducer from './notificationsReducer';
import optinsReducer from './optinsReducer';
import optoutsReducer from './optoutsReducer';
import pagecatsReducer from './pagecatsReducer';
import pagesReducer from './pagesReducer';
import pageattachmentsReducer from './pageattachmentsReducer';
import pagetagsReducer from './pagetagsReducer';
import parentsReducer from './parentsReducer';
import plansReducer from './plansReducer';
import pricingsReducer from './pricingsReducer';
import productattachmentsReducer from './productattachmentsReducer';
import productgroupsReducer from './productgroupsReducer';
import productpricesReducer from './productpricesReducer';
import productsReducer from './productsReducer';
import producttagsReducer from './producttagsReducer';
import projectattachmentsReducer from './projectattachmentsReducer';
import projectcatsReducer from './projectcatsReducer';
import projectgroupsReducer from './projectgroupsReducer';
import projecttagsReducer from './projecttagsReducer';
import purchasecontractattachmentsReducer from './purchasecontractattachmentsReducer';
import purchasecontractlinesReducer from './purchasecontractlinesReducer';
import purchasecontractsReducer from './purchasecontractsReducer';
import purchasedocattachmentsReducer from './purchasedocattachmentsReducer';
import purchasedoclinesReducer from './purchasedoclinesReducer';
import ratingsReducer from './ratingsReducer';
import receiversReducer from './receiversReducer';
import relationsReducer from './relationsReducer';
import resourceattachmentsReducer from './resourceattachmentsReducer';
import resourcecatsReducer from './resourcecatsReducer';
import resourcegroupsReducer from './resourcegroupsReducer';
import salescontractattachmentsReducer from './salescontractattachmentsReducer';
import salescontractlinesReducer from './salescontractlinesReducer';
import salescontractsReducer from './salescontractsReducer';
import salesdocattachmentsReducer from './salesdocattachmentsReducer';
import salesdoclinesReducer from './salesdoclinesReducer';
import sitesReducer from './sitesReducer';
import skillsReducer from './skillsReducer';
import tagsReducer from './tagsReducer';
import taskattachmentsReducer from './taskattachmentsReducer';
import tasktagsReducer from './tasktagsReducer';
import threadsReducer from './threadsReducer';
import workmodelsReducer from './workmodelsReducer';

// Organization - Inheritance
// - Resource
import resourcesReducer from './resourcesReducer';
import devicesReducer from './resource-devicesReducer';
import locationsReducer from './resource-locationsReducer';
import personsReducer from './resource-personsReducer';
// - Project
import projectsReducer from './projectsReducer';
import abonnementsReducer from './project-abonnementsReducer';
import businessesReducer from './project-businessesReducer';
import eventsReducer from './project-eventsReducer';
import semestersReducer from './project-semestersReducer';
import solutionsReducer from './project-solutionsReducer';
import travelsReducer from './project-travelsReducer';
// - Job
import jobsReducer from './jobsReducer';
import bugsReducer from './job-bugsReducer';
import casesReducer from './job-casesReducer';
import complaintsReducer from './job-complaintsReducer';
import coursesReducer from './job-coursesReducer';
import featuresReducer from './job-featuresReducer';
import ideasReducer from './job-ideasReducer';
import requirementsReducer from './job-requirementsReducer';
import servicesReducer from './job-servicesReducer';
import showsReducer from './job-showsReducer';
import toursReducer from './job-toursReducer';
// - Task
import tasksReducer from './tasksReducer';
import lessonsReducer from './task-lessonsReducer';
import seatsReducer from './task-seatsReducer';
import sessionsReducer from './task-sessionsReducer';
// - SalesDoc
import salesdocsReducer from './salesdocsReducer';
import salesquotesReducer from './salesdoc-quotesReducer';
import salesordersReducer from './salesdoc-ordersReducer';
import salesinvoicesReducer from './salesdoc-invoicesReducer';
// - PurchaseDoc
import purchasedocsReducer from './purchasedocsReducer';
import purchasequotesReducer from './purchasedoc-quotesReducer';
import purchaseordersReducer from './purchasedoc-ordersReducer';
import purchaseinvoicesReducer from './purchasedoc-invoicesReducer';

const rootReducer = combineReducers({
  // Starter Kit
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  form: formReducer,

  // App
  app: appReducer,

  // Account
  account: accountReducer,
  me: meReducer,
  mystatus: mystatusReducer,
  mytenant: mytenantReducer,
  mymember: mymemberReducer,
  myorganization: myorganizationReducer,
  mydelegate: mydelegateReducer,
  mycontact: mycontactReducer,
  mysite: mysiteReducer,
  mydelegatesite: mydelegatesiteReducer,

  // Manage
  applications: applicationsReducer,
  appointments: appointmentsReducer,
  appservices: appservicesReducer,
  contracts: contractsReducer,
  countries: countriesReducer,
  currencies: currenciesReducer,
  deployments: deploymentsReducer,
  deploymentitems: deploymentitemsReducer,
  functions: functionsReducer,
  languages: languagesReducer,
  licenses: licensesReducer,
  logins: loginsReducer,
  members: membersReducer,
  organizations: organizationsReducer,
  revisions: revisionsReducer,
  servicefunctions: servicefunctionsReducer,
  servicemodules: servicemodulesReducer,
  systems: systemsReducer,
  tenants: tenantsReducer,
  users: usersReducer,
  webhooks: webhooksReducer,

  // Organization
  absences: absencesReducer,
  absencetypes: absencetypesReducer,
  actioncontacts: actioncontactsReducer,
  activityattachments: activityattachmentsReducer,
  activities: activitiesReducer,
  adviceattachments: adviceattachmentsReducer,
  advices: advicesReducer,
  areas: areasReducer,
  assignmentattachments: assignmentattachmentsReducer,
  assignments: assignmentsReducer,
  assortmentproducts: assortmentproductsReducer,
  assortments: assortmentsReducer,
  attachments: attachmentsReducer,
  attendattachments: attendattachmentsReducer,
  attends: attendsReducer,
  attendeeattachments: attendeeattachmentsReducer,
  attendeecats: attendeecatsReducer,
  attendees: attendeesReducer,
  authors: authorsReducer,
  billingruns: billingrunsReducer,
  billings: billingsReducer,
  blogs: blogsReducer,
  bookingattachments: bookingattachmentsReducer,
  bookings: bookingsReducer,
  bundles: bundlesReducer,
  businesspartnerattachments: businesspartnerattachmentsReducer,
  businesspartnercats: businesspartnercatsReducer,
  businesspartnergroups: businesspartnergroupsReducer,
  businesspartners: businesspartnersReducer,
  calendars: calendarsReducer,
  capacities: capacitiesReducer,
  catalogs: catalogsReducer,
  channels: channelsReducer,
  channelsyncs: channelsyncsReducer,
  channelusers: channelusersReducer,
  chapters: chaptersReducer,
  commentattachments: commentattachmentsReducer,
  comments: commentsReducer,
  competences: competencesReducer,
  consumptions: consumptionsReducer,
  contactattachments: contactattachmentsReducer,
  contacts: contactsReducer,
  datas: datasReducer,
  delegatesites: delegatesitesReducer,
  delegates: delegatesReducer,
  departments: departmentsReducer,
  docs: docsReducer,
  educations: educationsReducer,
  emailattachments: emailattachmentsReducer,
  emailstates: emailstatesReducer,
  emails: emailsReducer,
  employeeattachments: employeeattachmentsReducer,
  employees: employeesReducer,
  eventcats: eventcatsReducer,
  follows: followsReducer,
  forums: forumsReducer,
  grades: gradesReducer,
  guidelines: guidelinesReducer,
  guides: guidesReducer,
  installations: installationsReducer,
  institutes: institutesReducer,
  iterations: iterationsReducer,
  jobattachments: jobattachmentsReducer,
  jobcats: jobcatsReducer,
  jobgroups: jobgroupsReducer,
  jobrelations: jobrelationsReducer,
  jobrelationtypes: jobrelationtypesReducer,
  jobtags: jobtagsReducer,
  mailinglistcontacts: mailinglistcontactsReducer,
  mailinglists: mailinglistsReducer,
  menuitems: menuitemsReducer,
  menus: menusReducer,
  messageattachments: messageattachmentsReducer,
  messages: messagesReducer,
  messagetags: messagetagsReducer,
  newsletterattachments: newsletterattachmentsReducer,
  newslettercats: newslettercatsReducer,
  newslettercontacts: newslettercontactsReducer,
  newslettergroups: newslettergroupsReducer,
  newsletters: newslettersReducer,
  newslettertags: newslettertagsReducer,
  notificationruns: notificationrunsReducer,
  notifications: notificationsReducer,
  optins: optinsReducer,
  optouts: optoutsReducer,
  pageattachments: pageattachmentsReducer,
  pagecats: pagecatsReducer,
  pages: pagesReducer,
  pagetags: pagetagsReducer,
  parents: parentsReducer,
  plans: plansReducer,
  pricings: pricingsReducer,
  productattachments: productattachmentsReducer,
  productgroups: productgroupsReducer,
  productprices: productpricesReducer,
  products: productsReducer,
  producttags: producttagsReducer,
  projectattachments: projectattachmentsReducer,
  projectcats: projectcatsReducer,
  projectgroups: projectgroupsReducer,
  projecttags: projecttagsReducer,
  purchasecontractattachments: purchasecontractattachmentsReducer,
  purchasecontractlines: purchasecontractlinesReducer,
  purchasecontracts: purchasecontractsReducer,
  purchasedocattachments: purchasedocattachmentsReducer,
  purchasedoclines: purchasedoclinesReducer,
  ratings: ratingsReducer,
  receivers: receiversReducer,
  relations: relationsReducer,
  resourceattachments: resourceattachmentsReducer,
  resourcecats: resourcecatsReducer,
  resourcegroups: resourcegroupsReducer,
  salescontractattachments: salescontractattachmentsReducer,
  salescontractlines: salescontractlinesReducer,
  salescontracts: salescontractsReducer,
  salesdocattachments: salesdocattachmentsReducer,
  salesdoclines: salesdoclinesReducer,
  sites: sitesReducer,
  skills: skillsReducer,
  tags: tagsReducer,
  taskattachments: taskattachmentsReducer,
  tasktags: tasktagsReducer,
  threads: threadsReducer,
  workmodels: workmodelsReducer,

  // Organization - Inheritance
  // - Resource
  resources: resourcesReducer,
  devices: devicesReducer,
  locations: locationsReducer,
  persons: personsReducer,
  // - Project
  projects: projectsReducer,
  abonnements: abonnementsReducer,
  businesses: businessesReducer,
  events: eventsReducer,
  semesters: semestersReducer,
  solutions: solutionsReducer,
  travels: travelsReducer,
  // - Job
  jobs: jobsReducer,
  bugs: bugsReducer,
  cases: casesReducer,
  complaints: complaintsReducer,
  courses: coursesReducer,
  features: featuresReducer,
  ideas: ideasReducer,
  requirements: requirementsReducer,
  services: servicesReducer,
  shows: showsReducer,
  tours: toursReducer,
  // - Task
  tasks: tasksReducer,
  lessons: lessonsReducer,
  seats: seatsReducer,
  sessions: sessionsReducer,
  // - SalesDoc
  salesdocs: salesdocsReducer,
  salesquotes: salesquotesReducer,
  salesorders: salesordersReducer,
  salesinvoices: salesinvoicesReducer,
  // - PurchaseDoc
  purchasedocs: purchasedocsReducer,
  purchasequotes: purchasequotesReducer,
  purchaseorders: purchaseordersReducer,
  purchaseinvoices: purchaseinvoicesReducer
});

export default rootReducer;
