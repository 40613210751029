import produce from 'immer';
import * as Actions from 'src/actions/types';
import * as AccountActions from 'src/actions/accountActions';
import * as Entities from 'src/config/Entities';

const initialState = {
  navigation: {
    tab: 'details'
  },
  site: null,
  fetching: false,
  mode: Entities.EntityMode.view
};

const mysiteReducer = (state = initialState, action) => {
  switch (action.type) {
    // NAVIGATION
    case Actions.ME_SITE_NAVIGATION_SET: {
      const { navigation } = action.payload;
      return produce(state, draft => {
        const newNav = {
          ...state.navigation,
          ...navigation
        };
        draft.navigation = newNav;
      });
    }

    // ENTITY
    case Actions.ME_SITE_MODE: {
      const { mode } = action.payload;
      return produce(state, draft => {
        draft.mode = mode;
      });
    }
    case Actions.ME_SITE_REQUEST: {
      return produce(state, draft => {
        draft.fetching = true;
      });
    }
    case Actions.ME_SITE_RECEIVE:
    case Actions.ME_SITE_UPDATE: {
      const { entity } = action.payload;
      return produce(state, draft => {
        draft.site = entity;
        draft.fetching = false;
      });
    }
    case Actions.ME_SITE_FINISHED: {
      return produce(state, draft => {
        draft.fetching = false;
      });
    }

    //------------------------------
    // From Account
    //------------------------------

    case AccountActions.LOGIN_REQUEST:
    case AccountActions.LOGOUT: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.site = null;
        draft.fetching = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default mysiteReducer;
