import { Users as ContactIcon } from 'react-feather';
import { Cast as BlogIcon } from 'react-feather';
import { AtSign as BusinessPartnerIcon } from 'react-feather';
import { Grid as CategoryIcon } from 'react-feather';
//import { PieChart as DashboardIcon } from 'react-feather';
//import { List as DatalistIcon } from 'react-feather';
//import { Cloud as DeploymentIcon } from 'react-feather';
import { BookOpen as DocumentationIcon } from 'react-feather';
import { Copy as GroupIcon } from 'react-feather';
import { Compass as GuideIcon } from 'react-feather';
import { Image as ImageIcon } from 'react-feather';
//import { Layout as MenuIcon } from 'react-feather';
import { Calendar as ScheduleIcon } from 'react-feather';
//import { Sliders as SettingsIcon } from 'react-feather';
import { File as SinglePageIcon } from 'react-feather';
import * as Enum from 'src/config/Enumerations';

export const getNavigationTree = t => {
  const navConfig = [
    //#region CMS
    {
      subheader: t('navigation2121:CMS.Group'),
      access: Enum.AccessLevel.User,
      items: [
        //#region BLOG
        {
          title: t('navigation2121:CMS.Blog.Header'),
          access: Enum.AccessLevel.User,
          icon: BlogIcon,
          items: [
            {
              title: t('navigation2121:CMS.Blog.Category'),
              access: Enum.AccessLevel.User,
              href: '/app/blogs'
            },
            {
              title: t('navigation2121:CMS.Blog.Pages'),
              access: Enum.AccessLevel.User,
              href: '/app/posts'
            }
          ]
        },
        //#endregion
        //#region PLAN
        {
          title: t('navigation2121:CMS.Event.Header'),
          access: Enum.AccessLevel.User,
          icon: ScheduleIcon,
          items: [
            {
              title: t('navigation2121:CMS.Event.Category'),
              access: Enum.AccessLevel.User,
              href: '/app/plans'
            },
            {
              title: t('navigation2121:CMS.Event.Pages'),
              access: Enum.AccessLevel.User,
              href: '/app/events'
            }
          ]
        },
        //#endregion
        //#region DOC
        {
          title: t('navigation2121:CMS.Doc.Header'),
          access: Enum.AccessLevel.User,
          icon: DocumentationIcon,
          items: [
            {
              title: t('navigation2121:CMS.Doc.Category'),
              access: Enum.AccessLevel.User,
              href: '/app/docs'
            },
            {
              title: t('navigation2121:CMS.Doc.Pages'),
              access: Enum.AccessLevel.User,
              href: '/app/articles'
            }
          ]
        },
        //#endregion
        //#region GUIDELINE
        {
          title: t('navigation2121:CMS.Guide.Header'),
          access: Enum.AccessLevel.User,
          icon: GuideIcon,
          items: [
            {
              title: t('navigation2121:CMS.Guide.Category'),
              access: Enum.AccessLevel.User,
              href: '/app/guidelines'
            },
            {
              title: t('navigation2121:CMS.Guide.Pages'),
              access: Enum.AccessLevel.User,
              href: '/app/actions'
            }
          ]
        }
        //#endregion
      ]
    },
    //#endregion

    //#region SITE
    {
      subheader: t('navigation2121:Site.Group'),
      access: Enum.AccessLevel.User,
      items: [
        {
          title: t('navigation2121:Site.MediaManager'),
          access: Enum.AccessLevel.User,
          icon: ImageIcon,
          href: '/app/mediamanager'
        },
        {
          title: t('navigation2121:Site.SinglePage'),
          access: Enum.AccessLevel.User,
          icon: SinglePageIcon,
          href: '/app/singles'
        }
      ]
    },
    //#endregion

    //#region CRM
    {
      subheader: t('navigation2121:CRM.Group'),
      access: Enum.AccessLevel.User,
      items: [
        {
          title: t('navigation2121:CRM.BusinessPartner.BusinessPartner'),
          access: Enum.AccessLevel.User,
          icon: BusinessPartnerIcon,
          href: '/app/businesspartners'
        },
        {
          title: t('navigation2121:CRM.BusinessPartner.Group'),
          access: Enum.AccessLevel.User,
          icon: GroupIcon,
          href: '/app/businesspartnergroups'
        },
        {
          title: t('navigation2121:CRM.BusinessPartner.Cat'),
          access: Enum.AccessLevel.User,
          icon: CategoryIcon,
          href: '/app/businesspartnercats'
        },
        {
          title: t('navigation2121:CRM.Contact'),
          access: Enum.AccessLevel.User,
          icon: ContactIcon,
          href: '/app/contacts'
        }
      ]
    }
    //#endregion
  ];

  return navConfig;
};
