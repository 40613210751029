export const Paths = {
  urlTerms: 'https://appsolut.at/terms/',
  urlPrivacy: 'https://appsolut.at/privacy/'
};

export const ApplicationClient = {
  appName: 'appsolut365 Web Client',
  appKey:
    '447837d6ebf0bf92c53786dcbf673baf.cd2dbbc91bc54053315966b41a479663eb21f744d2518b6eee44915b93bd31f7d8453be3fdc7baaea658a3708972a0f1baf2e5439b8e4d876dd9fb5d974127ecb9cc8c89c0aacb2dfd751e376426187d'
};

export const GraphQLServer = {
  url: 'https://graph.appsolut365.com/graphql',
  //url: 'http://localhost:4000/graphql',
  inputErrorCode: 'BAD_USER_INPUT'
};

const RestBaseUrl = 'https://graph.appsolut365.com';
//const RestBaseUrl = 'http://localhost:4000';

const RestRelease = 'v1';
export const RestServer = {
  urlAuth: `${RestBaseUrl}/api/${RestRelease}/auth`,
  urlApi: `${RestBaseUrl}/api/${RestRelease}`
};
