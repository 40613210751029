import axios from 'axios';
import { RestServer } from 'src/config/Application';

export const toAuth = axios.create({
  baseURL: RestServer.urlAuth
});

export const toAPI = axios.create({
  baseURL: RestServer.urlApi
});
