// App
export const AUTH_LOGIN_REQUEST = '@app/auth-login-request';
export const AUTH_LOGIN_SUCCESS = '@app/auth-login-success';
export const AUTH_SILENT_LOGIN = '@app/auth-silent-login';
export const AUTH_LOGOUT = '@app/auth-logout';
export const AUTH_PROFILE_UPDATE = '@app/auth-profile-update';
export const APP_ORGANIZATION_SELECT = '@app/organization-select';
export const ONBOARDING_NEEDED = '@app/onboarding-needed';
export const ONBOARDING_RUNNING = '@app/onboarding-running';
export const ONBOARDING_STEP = '@app/onboarding-step';
export const ONBOARDING_FINISHED = '@app/onboarding-finished';
export const ONBOARDING_RECEIVE = '@app/onboarding-receive';
export const ERRORS_ADD = '@app/errors-add';
export const ERRORS_REMOVE = '@app/errors-remove';
export const ERRORS_CLEAR = '@app/errors-clear';
export const SIDEBAR_OPEN = '@app/sidebar-open';
export const SIDEBAR_CLOSE = '@app/sidebar-close';
export const APP_ASSET_CLEAR = '@app/asset-clear';
export const APP_ASSET_REQUEST = '@app/asset-request';
export const APP_ASSET_RECEIVE = '@app/asset-receive';

// Account
export const ACCOUNT_UPDATE = '@account/entity-update';
export const ME_NAVIGATION_SET = '@me/navigation-set';
export const ME_MODE = '@me/entity-mode';
export const ME_REQUEST = '@me/entity-request';
export const ME_RECEIVE = '@me/entity-receive';
export const ME_UPDATE = '@me/entity-update';
export const ME_FINISHED = '@me/finished';
export const ME_STATUS_NAVIGATION_SET = '@me/status/navigation-set';
export const ME_STATUS_MODE = '@me/status/entity-mode';
export const ME_STATUS_REQUEST = '@me/status/entity-request';
export const ME_STATUS_RECEIVE = '@me/status/entity-receive';
export const ME_STATUS_UPDATE = '@me/status/entity-update';
export const ME_STATUS_FINISHED = '@me/status/finished';
export const ME_TENANT_NAVIGATION_SET = '@me/tenant/navigation-set';
export const ME_TENANT_LIST_REQUEST = '@me/tenant/list-request';
export const ME_TENANT_LIST_RECEIVE = '@me/tenant/list-receive';
export const ME_TENANT_MODE = '@me/tenant/entity-mode';
export const ME_TENANT_REQUEST = '@me/tenant/entity-request';
export const ME_TENANT_RECEIVE = '@me/tenant/entity-receive';
export const ME_TENANT_UPDATE = '@me/tenant/entity-update';
export const ME_TENANT_FINISHED = '@me/tenant/finished';
export const ME_MEMBER_NAVIGATION_SET = '@me/member/navigation-set';
export const ME_MEMBER_MODE = '@me/member/entity-mode';
export const ME_MEMBER_REQUEST = '@me/member/entity-request';
export const ME_MEMBER_RECEIVE = '@me/member/entity-receive';
export const ME_MEMBER_UPDATE = '@me/member/entity-update';
export const ME_MEMBER_FINISHED = '@me/member/finished';
export const ME_ORG_NAVIGATION_SET = '@me/org/navigation-set';
export const ME_ORG_LIST_REQUEST = '@me/org/list-request';
export const ME_ORG_LIST_RECEIVE = '@me/org/list-receive';
export const ME_ORG_MODE = '@me/org/entity-mode';
export const ME_ORG_REQUEST = '@me/org/entity-request';
export const ME_ORG_RECEIVE = '@me/org/entity-receive';
export const ME_ORG_UPDATE = '@me/org/entity-update';
export const ME_ORG_FINISHED = '@me/org/finished';
export const ME_DELEGATE_NAVIGATION_SET = '@me/delegate/navigation-set';
export const ME_DELEGATE_LIST_REQUEST = '@me/delegate/list-request';
export const ME_DELEGATE_LIST_RECEIVE = '@me/delegate/list-receive';
export const ME_DELEGATE_MODE = '@me/delegate/entity-mode';
export const ME_DELEGATE_REQUEST = '@me/delegate/entity-request';
export const ME_DELEGATE_RECEIVE = '@me/delegate/entity-receive';
export const ME_DELEGATE_UPDATE = '@me/delegate/entity-update';
export const ME_DELEGATE_FINISHED = '@me/delegate/finished';
export const ME_CONTACT_NAVIGATION_SET = '@me/contact/navigation-set';
export const ME_CONTACT_MODE = '@me/contact/entity-mode';
export const ME_CONTACT_REQUEST = '@me/contact/entity-request';
export const ME_CONTACT_RECEIVE = '@me/contact/entity-receive';
export const ME_CONTACT_UPDATE = '@me/contact/entity-update';
export const ME_CONTACT_FINISHED = '@me/contact/finished';
export const ME_SITE_NAVIGATION_SET = '@me/contact/navigation-set';
export const ME_SITE_MODE = '@me/site/entity-mode';
export const ME_SITE_REQUEST = '@me/site/entity-request';
export const ME_SITE_RECEIVE = '@me/site/entity-receive';
export const ME_SITE_UPDATE = '@me/site/entity-update';
export const ME_SITE_FINISHED = '@me/site/finished';
export const ME_DELEGATESITE_NAVIGATION_SET = '@me/delegatesite/navigation-set';
export const ME_DELEGATESITE_MODE = '@me/delegatesite/entity-mode';
export const ME_DELEGATESITE_REQUEST = '@me/delegatesite/entity-request';
export const ME_DELEGATESITE_RECEIVE = '@me/delegatesite/entity-receive';
export const ME_DELEGATESITE_UPDATE = '@me/delegatesite/entity-update';
export const ME_DELEGATESITE_FINISHED = '@me/delegatesite/finished';

/**
 * SYSTEM functions
 */

// Applications
export const APPLICATION_LABELS_GET = '@application/labels-get';
export const APPLICATION_LABEL_SELECT = '@application/label-select';
export const APPLICATION_NAVIGATION_SET = '@application/navigation-set';
export const APPLICATION_LIST_REQUEST = '@application/list-request';
export const APPLICATION_LIST_REQUEST_MORE = '@application/list-request-more';
export const APPLICATION_LIST_RECEIVE = '@application/list-receive';
export const APPLICATION_LIST_APPEND = '@application/list-append';
export const APPLICATION_SELECT = '@application/entity-select';
export const APPLICATION_MODE = '@application/entity-mode';
export const APPLICATION_REQUEST = '@application/entity-request';
export const APPLICATION_RECEIVE = '@application/entity-receive';
export const APPLICATION_ADD = '@application/entity-add';
export const APPLICATION_UPDATE = '@application/entity-update';
export const APPLICATION_DELETE = '@application/entity-delete';
export const APPLICATION_FINISHED = '@application/finished';

// AppServices
export const APPSERVICE_LABELS_GET = '@appservice/labels-get';
export const APPSERVICE_LABEL_SELECT = '@appservice/label-select';
export const APPSERVICE_NAVIGATION_SET = '@appservice/navigation-set';
export const APPSERVICE_LIST_REQUEST = '@appservice/list-request';
export const APPSERVICE_LIST_REQUEST_MORE = '@appservice/list-request-more';
export const APPSERVICE_LIST_RECEIVE = '@appservice/list-receive';
export const APPSERVICE_LIST_APPEND = '@appservice/list-append';
export const APPSERVICE_SELECT = '@appservice/entity-select';
export const APPSERVICE_MODE = '@appservice/entity-mode';
export const APPSERVICE_REQUEST = '@appservice/entity-request';
export const APPSERVICE_RECEIVE = '@appservice/entity-receive';
export const APPSERVICE_ADD = '@appservice/entity-add';
export const APPSERVICE_UPDATE = '@appservice/entity-update';
export const APPSERVICE_DELETE = '@appservice/entity-delete';
export const APPSERVICE_FINISHED = '@appservice/finished';

// Countries
export const COUNTRY_LABELS_GET = '@country/labels-get';
export const COUNTRY_LABEL_SELECT = '@country/label-select';
export const COUNTRY_NAVIGATION_SET = '@country/navigation-set';
export const COUNTRY_LIST_REQUEST = '@country/list-request';
export const COUNTRY_LIST_REQUEST_MORE = '@country/list-request-more';
export const COUNTRY_LIST_RECEIVE = '@country/list-receive';
export const COUNTRY_LIST_APPEND = '@country/list-append';
export const COUNTRY_SELECT = '@country/entity-select';
export const COUNTRY_MODE = '@country/entity-mode';
export const COUNTRY_REQUEST = '@country/entity-request';
export const COUNTRY_RECEIVE = '@country/entity-receive';
export const COUNTRY_ADD = '@country/entity-add';
export const COUNTRY_UPDATE = '@country/entity-update';
export const COUNTRY_DELETE = '@country/entity-delete';
export const COUNTRY_FINISHED = '@country/finished';

// Currencies
export const CURRENCY_LABELS_GET = '@currency/labels-get';
export const CURRENCY_LABEL_SELECT = '@currency/label-select';
export const CURRENCY_NAVIGATION_SET = '@currency/navigation-set';
export const CURRENCY_LIST_REQUEST = '@currency/list-request';
export const CURRENCY_LIST_REQUEST_MORE = '@currency/list-request-more';
export const CURRENCY_LIST_RECEIVE = '@currency/list-receive';
export const CURRENCY_LIST_APPEND = '@currency/list-append';
export const CURRENCY_SELECT = '@currency/entity-select';
export const CURRENCY_MODE = '@currency/entity-mode';
export const CURRENCY_REQUEST = '@currency/entity-request';
export const CURRENCY_RECEIVE = '@currency/entity-receive';
export const CURRENCY_ADD = '@currency/entity-add';
export const CURRENCY_UPDATE = '@currency/entity-update';
export const CURRENCY_DELETE = '@currency/entity-delete';
export const CURRENCY_FINISHED = '@currency/finished';

// Deployments
export const DEPLOYMENT_LABELS_GET = '@deployment/labels-get';
export const DEPLOYMENT_LABEL_SELECT = '@deployment/label-select';
export const DEPLOYMENT_NAVIGATION_SET = '@deployment/navigation-set';
export const DEPLOYMENT_LIST_REQUEST = '@deployment/list-request';
export const DEPLOYMENT_LIST_REQUEST_MORE = '@deployment/list-request-more';
export const DEPLOYMENT_LIST_RECEIVE = '@deployment/list-receive';
export const DEPLOYMENT_LIST_APPEND = '@deployment/list-append';
export const DEPLOYMENT_SELECT = '@deployment/entity-select';
export const DEPLOYMENT_MODE = '@deployment/entity-mode';
export const DEPLOYMENT_REQUEST = '@deployment/entity-request';
export const DEPLOYMENT_RECEIVE = '@deployment/entity-receive';
export const DEPLOYMENT_ADD = '@deployment/entity-add';
export const DEPLOYMENT_UPDATE = '@deployment/entity-update';
export const DEPLOYMENT_DELETE = '@deployment/entity-delete';
export const DEPLOYMENT_FINISHED = '@deployment/finished';

// DeploymentItems
export const DEPLOYMENTITEM_LABELS_GET = '@deploymentitem/labels-get';
export const DEPLOYMENTITEM_LABEL_SELECT = '@deploymentitem/label-select';
export const DEPLOYMENTITEM_NAVIGATION_SET = '@deploymentitem/navigation-set';
export const DEPLOYMENTITEM_LIST_REQUEST = '@deploymentitem/list-request';
export const DEPLOYMENTITEM_LIST_REQUEST_MORE =
  '@deploymentitem/list-request-more';
export const DEPLOYMENTITEM_LIST_RECEIVE = '@deploymentitem/list-receive';
export const DEPLOYMENTITEM_LIST_APPEND = '@deploymentitem/list-append';
export const DEPLOYMENTITEM_SELECT = '@deploymentitem/entity-select';
export const DEPLOYMENTITEM_MODE = '@deploymentitem/entity-mode';
export const DEPLOYMENTITEM_REQUEST = '@deploymentitem/entity-request';
export const DEPLOYMENTITEM_RECEIVE = '@deploymentitem/entity-receive';
export const DEPLOYMENTITEM_ADD = '@deploymentitem/entity-add';
export const DEPLOYMENTITEM_UPDATE = '@deploymentitem/entity-update';
export const DEPLOYMENTITEM_DELETE = '@deploymentitem/entity-delete';
export const DEPLOYMENTITEM_FINISHED = '@deploymentitem/finished';

// Functions
export const FUNCTION_LABELS_GET = '@function/labels-get';
export const FUNCTION_LABEL_SELECT = '@function/label-select';
export const FUNCTION_NAVIGATION_SET = '@function/navigation-set';
export const FUNCTION_LIST_REQUEST = '@function/list-request';
export const FUNCTION_LIST_REQUEST_MORE = '@function/list-request-more';
export const FUNCTION_LIST_RECEIVE = '@function/list-receive';
export const FUNCTION_LIST_APPEND = '@function/list-append';
export const FUNCTION_SELECT = '@function/entity-select';
export const FUNCTION_MODE = '@function/entity-mode';
export const FUNCTION_REQUEST = '@function/entity-request';
export const FUNCTION_RECEIVE = '@function/entity-receive';
export const FUNCTION_ADD = '@function/entity-add';
export const FUNCTION_UPDATE = '@function/entity-update';
export const FUNCTION_DELETE = '@function/entity-delete';
export const FUNCTION_FINISHED = '@function/finished';

// Languages
export const LANGUAGE_LABELS_GET = '@language/labels-get';
export const LANGUAGE_LABEL_SELECT = '@language/label-select';
export const LANGUAGE_NAVIGATION_SET = '@language/navigation-set';
export const LANGUAGE_LIST_REQUEST = '@language/list-request';
export const LANGUAGE_LIST_REQUEST_MORE = '@language/list-request-more';
export const LANGUAGE_LIST_RECEIVE = '@language/list-receive';
export const LANGUAGE_LIST_APPEND = '@language/list-append';
export const LANGUAGE_SELECT = '@language/entity-select';
export const LANGUAGE_MODE = '@language/entity-mode';
export const LANGUAGE_REQUEST = '@language/entity-request';
export const LANGUAGE_RECEIVE = '@language/entity-receive';
export const LANGUAGE_ADD = '@language/entity-add';
export const LANGUAGE_UPDATE = '@language/entity-update';
export const LANGUAGE_DELETE = '@language/entity-delete';
export const LANGUAGE_FINISHED = '@language/finished';

// Revisions
export const REVISION_LABELS_GET = '@revision/labels-get';
export const REVISION_LABEL_SELECT = '@revision/label-select';
export const REVISION_NAVIGATION_SET = '@revision/navigation-set';
export const REVISION_LIST_REQUEST = '@revision/list-request';
export const REVISION_LIST_REQUEST_MORE = '@revision/list-request-more';
export const REVISION_LIST_RECEIVE = '@revision/list-receive';
export const REVISION_LIST_APPEND = '@revision/list-append';
export const REVISION_SELECT = '@revision/entity-select';
export const REVISION_MODE = '@revision/entity-mode';
export const REVISION_REQUEST = '@revision/entity-request';
export const REVISION_RECEIVE = '@revision/entity-receive';
export const REVISION_ADD = '@revision/entity-add';
export const REVISION_UPDATE = '@revision/entity-update';
export const REVISION_DELETE = '@revision/entity-delete';
export const REVISION_FINISHED = '@revision/finished';

// ServiceFunctions
export const SERVICEFUNCTION_LABELS_GET = '@servicefunction/labels-get';
export const SERVICEFUNCTION_LABEL_SELECT = '@servicefunction/label-select';
export const SERVICEFUNCTION_NAVIGATION_SET = '@servicefunction/navigation-set';
export const SERVICEFUNCTION_LIST_REQUEST = '@servicefunction/list-request';
export const SERVICEFUNCTION_LIST_REQUEST_MORE =
  '@servicefunction/list-request-more';
export const SERVICEFUNCTION_LIST_RECEIVE = '@servicefunction/list-receive';
export const SERVICEFUNCTION_LIST_APPEND = '@servicefunction/list-append';
export const SERVICEFUNCTION_SELECT = '@servicefunction/entity-select';
export const SERVICEFUNCTION_MODE = '@servicefunction/entity-mode';
export const SERVICEFUNCTION_REQUEST = '@servicefunction/entity-request';
export const SERVICEFUNCTION_RECEIVE = '@servicefunction/entity-receive';
export const SERVICEFUNCTION_ADD = '@servicefunction/entity-add';
export const SERVICEFUNCTION_UPDATE = '@servicefunction/entity-update';
export const SERVICEFUNCTION_DELETE = '@servicefunction/entity-delete';
export const SERVICEFUNCTION_FINISHED = '@servicefunction/finished';

// ServiceModules
export const SERVICEMODULE_LABELS_GET = '@servicemodule/labels-get';
export const SERVICEMODULE_LABEL_SELECT = '@servicemodule/label-select';
export const SERVICEMODULE_NAVIGATION_SET = '@servicemodule/navigation-set';
export const SERVICEMODULE_LIST_REQUEST = '@servicemodule/list-request';
export const SERVICEMODULE_LIST_REQUEST_MORE =
  '@servicemodule/list-request-more';
export const SERVICEMODULE_LIST_RECEIVE = '@servicemodule/list-receive';
export const SERVICEMODULE_LIST_APPEND = '@servicemodule/list-append';
export const SERVICEMODULE_SELECT = '@servicemodule/entity-select';
export const SERVICEMODULE_MODE = '@servicemodule/entity-mode';
export const SERVICEMODULE_REQUEST = '@servicemodule/entity-request';
export const SERVICEMODULE_RECEIVE = '@servicemodule/entity-receive';
export const SERVICEMODULE_ADD = '@servicemodule/entity-add';
export const SERVICEMODULE_UPDATE = '@servicemodule/entity-update';
export const SERVICEMODULE_DELETE = '@servicemodule/entity-delete';
export const SERVICEMODULE_FINISHED = '@servicemodule/finished';

// Systems
export const SYSTEM_LABELS_GET = '@system/labels-get';
export const SYSTEM_LABEL_SELECT = '@system/label-select';
export const SYSTEM_NAVIGATION_SET = '@system/navigation-set';
export const SYSTEM_LIST_REQUEST = '@system/list-request';
export const SYSTEM_LIST_REQUEST_MORE = '@system/list-request-more';
export const SYSTEM_LIST_RECEIVE = '@system/list-receive';
export const SYSTEM_LIST_APPEND = '@system/list-append';
export const SYSTEM_SELECT = '@system/entity-select';
export const SYSTEM_MODE = '@system/entity-mode';
export const SYSTEM_REQUEST = '@system/entity-request';
export const SYSTEM_RECEIVE = '@system/entity-receive';
export const SYSTEM_ADD = '@system/entity-add';
export const SYSTEM_UPDATE = '@system/entity-update';
export const SYSTEM_DELETE = '@system/entity-delete';
export const SYSTEM_FINISHED = '@system/finished';

// Webhooks
export const WEBHOOK_LABELS_GET = '@webhook/labels-get';
export const WEBHOOK_LABEL_SELECT = '@webhook/label-select';
export const WEBHOOK_NAVIGATION_SET = '@webhook/navigation-set';
export const WEBHOOK_LIST_REQUEST = '@webhook/list-request';
export const WEBHOOK_LIST_REQUEST_MORE = '@webhook/list-request-more';
export const WEBHOOK_LIST_RECEIVE = '@webhook/list-receive';
export const WEBHOOK_LIST_APPEND = '@webhook/list-append';
export const WEBHOOK_SELECT = '@webhook/entity-select';
export const WEBHOOK_MODE = '@webhook/entity-mode';
export const WEBHOOK_REQUEST = '@webhook/entity-request';
export const WEBHOOK_RECEIVE = '@webhook/entity-receive';
export const WEBHOOK_ADD = '@webhook/entity-add';
export const WEBHOOK_UPDATE = '@webhook/entity-update';
export const WEBHOOK_DELETE = '@webhook/entity-delete';
export const WEBHOOK_FINISHED = '@webhook/finished';

/**
 * TENANT functions
 */

// Appointments
export const APPOINTMENT_LABELS_GET = '@appointment/labels-get';
export const APPOINTMENT_LABEL_SELECT = '@appointment/label-select';
export const APPOINTMENT_NAVIGATION_SET = '@appointment/navigation-set';
export const APPOINTMENT_LIST_REQUEST = '@appointment/list-request';
export const APPOINTMENT_LIST_REQUEST_MORE = '@appointment/list-request-more';
export const APPOINTMENT_LIST_RECEIVE = '@appointment/list-receive';
export const APPOINTMENT_LIST_APPEND = '@appointment/list-append';
export const APPOINTMENT_SELECT = '@appointment/entity-select';
export const APPOINTMENT_MODE = '@appointment/entity-mode';
export const APPOINTMENT_REQUEST = '@appointment/entity-request';
export const APPOINTMENT_RECEIVE = '@appointment/entity-receive';
export const APPOINTMENT_ADD = '@appointment/entity-add';
export const APPOINTMENT_UPDATE = '@appointment/entity-update';
export const APPOINTMENT_DELETE = '@appointment/entity-delete';
export const APPOINTMENT_FINISHED = '@appointment/finished';

// Contracts
export const CONTRACT_LABELS_GET = '@contract/labels-get';
export const CONTRACT_LABEL_SELECT = '@contract/label-select';
export const CONTRACT_NAVIGATION_SET = '@contract/navigation-set';
export const CONTRACT_LIST_REQUEST = '@contract/list-request';
export const CONTRACT_LIST_REQUEST_MORE = '@contract/list-request-more';
export const CONTRACT_LIST_RECEIVE = '@contract/list-receive';
export const CONTRACT_LIST_APPEND = '@contract/list-append';
export const CONTRACT_SELECT = '@contract/entity-select';
export const CONTRACT_MODE = '@contract/entity-mode';
export const CONTRACT_REQUEST = '@contract/entity-request';
export const CONTRACT_RECEIVE = '@contract/entity-receive';
export const CONTRACT_ADD = '@contract/entity-add';
export const CONTRACT_UPDATE = '@contract/entity-update';
export const CONTRACT_DELETE = '@contract/entity-delete';
export const CONTRACT_FINISHED = '@contract/finished';

// Licenses
export const LICENSE_LABELS_GET = '@license/labels-get';
export const LICENSE_LABEL_SELECT = '@license/label-select';
export const LICENSE_NAVIGATION_SET = '@license/navigation-set';
export const LICENSE_LIST_REQUEST = '@license/list-request';
export const LICENSE_LIST_REQUEST_MORE = '@license/list-request-more';
export const LICENSE_LIST_RECEIVE = '@license/list-receive';
export const LICENSE_LIST_APPEND = '@license/list-append';
export const LICENSE_SELECT = '@license/entity-select';
export const LICENSE_MODE = '@license/entity-mode';
export const LICENSE_REQUEST = '@license/entity-request';
export const LICENSE_RECEIVE = '@license/entity-receive';
export const LICENSE_ADD = '@license/entity-add';
export const LICENSE_UPDATE = '@license/entity-update';
export const LICENSE_DELETE = '@license/entity-delete';
export const LICENSE_FINISHED = '@license/finished';

// Logins
export const LOGIN_LABELS_GET = '@login/labels-get';
export const LOGIN_LABEL_SELECT = '@login/label-select';
export const LOGIN_NAVIGATION_SET = '@login/navigation-set';
export const LOGIN_LIST_REQUEST = '@login/list-request';
export const LOGIN_LIST_REQUEST_MORE = '@login/list-request-more';
export const LOGIN_LIST_RECEIVE = '@login/list-receive';
export const LOGIN_LIST_APPEND = '@login/list-append';
export const LOGIN_SELECT = '@login/entity-select';
export const LOGIN_MODE = '@login/entity-mode';
export const LOGIN_REQUEST = '@login/entity-request';
export const LOGIN_RECEIVE = '@login/entity-receive';
export const LOGIN_ADD = '@login/entity-add';
export const LOGIN_UPDATE = '@login/entity-update';
export const LOGIN_DELETE = '@login/entity-delete';
export const LOGIN_FINISHED = '@login/finished';

// Members
export const MEMBER_LABELS_GET = '@member/labels-get';
export const MEMBER_LABEL_SELECT = '@member/label-select';
export const MEMBER_NAVIGATION_SET = '@member/navigation-set';
export const MEMBER_LIST_REQUEST = '@member/list-request';
export const MEMBER_LIST_REQUEST_MORE = '@member/list-request-more';
export const MEMBER_LIST_RECEIVE = '@member/list-receive';
export const MEMBER_LIST_APPEND = '@member/list-append';
export const MEMBER_SELECT = '@member/entity-select';
export const MEMBER_MODE = '@member/entity-mode';
export const MEMBER_REQUEST = '@member/entity-request';
export const MEMBER_RECEIVE = '@member/entity-receive';
export const MEMBER_ADD = '@member/entity-add';
export const MEMBER_UPDATE = '@member/entity-update';
export const MEMBER_DELETE = '@member/entity-delete';
export const MEMBER_FINISHED = '@member/finished';

// Organizations
export const ORGANIZATION_LABELS_GET = '@organization/labels-get';
export const ORGANIZATION_LABEL_SELECT = '@organization/label-select';
export const ORGANIZATION_NAVIGATION_SET = '@organization/navigation-set';
export const ORGANIZATION_LIST_REQUEST = '@organization/list-request';
export const ORGANIZATION_LIST_REQUEST_MORE = '@organization/list-request-more';
export const ORGANIZATION_LIST_RECEIVE = '@organization/list-receive';
export const ORGANIZATION_LIST_APPEND = '@organization/list-append';
export const ORGANIZATION_SELECT = '@organization/entity-select';
export const ORGANIZATION_MODE = '@organization/entity-mode';
export const ORGANIZATION_REQUEST = '@organization/entity-request';
export const ORGANIZATION_RECEIVE = '@organization/entity-receive';
export const ORGANIZATION_ADD = '@organization/entity-add';
export const ORGANIZATION_UPDATE = '@organization/entity-update';
export const ORGANIZATION_DELETE = '@organization/entity-delete';
export const ORGANIZATION_FINISHED = '@organization/finished';

// Tenants
export const TENANT_LABELS_GET = '@tenant/labels-get';
export const TENANT_LABEL_SELECT = '@tenant/label-select';
export const TENANT_NAVIGATION_SET = '@tenant/navigation-set';
export const TENANT_LIST_REQUEST = '@tenant/list-request';
export const TENANT_LIST_REQUEST_MORE = '@tenant/list-request-more';
export const TENANT_LIST_RECEIVE = '@tenant/list-receive';
export const TENANT_LIST_APPEND = '@tenant/list-append';
export const TENANT_SELECT = '@tenant/entity-select';
export const TENANT_MODE = '@tenant/entity-mode';
export const TENANT_REQUEST = '@tenant/entity-request';
export const TENANT_RECEIVE = '@tenant/entity-receive';
export const TENANT_ADD = '@tenant/entity-add';
export const TENANT_UPDATE = '@tenant/entity-update';
export const TENANT_DELETE = '@tenant/entity-delete';
export const TENANT_FINISHED = '@tenant/finished';

// Users
export const USER_LABELS_GET = '@user/labels-get';
export const USER_LABEL_SELECT = '@user/label-select';
export const USER_NAVIGATION_SET = '@user/navigation-set';
export const USER_LIST_REQUEST = '@user/list-request';
export const USER_LIST_REQUEST_MORE = '@user/list-request-more';
export const USER_LIST_RECEIVE = '@user/list-receive';
export const USER_LIST_APPEND = '@user/list-append';
export const USER_SELECT = '@user/entity-select';
export const USER_MODE = '@user/entity-mode';
export const USER_REQUEST = '@user/entity-request';
export const USER_RECEIVE = '@user/entity-receive';
export const USER_ADD = '@user/entity-add';
export const USER_UPDATE = '@user/entity-update';
export const USER_DELETE = '@user/entity-delete';
export const USER_FINISHED = '@user/finished';

/**
 * ORGANIZATION Inheritance functions
 */

// RESOURCE | Resource
export const RESOURCE_LABELS_GET = '@resource/labels-get';
export const RESOURCE_LABEL_SELECT = '@resource/label-select';
export const RESOURCE_NAVIGATION_SET = '@resource/navigation-set';
export const RESOURCE_LIST_REQUEST = '@resource/list-request';
export const RESOURCE_LIST_REQUEST_MORE = '@resource/list-request-more';
export const RESOURCE_LIST_RECEIVE = '@resource/list-receive';
export const RESOURCE_LIST_APPEND = '@resource/list-append';
export const RESOURCE_SELECT = '@resource/entity-select';
export const RESOURCE_MODE = '@resource/entity-mode';
export const RESOURCE_REQUEST = '@resource/entity-request';
export const RESOURCE_RECEIVE = '@resource/entity-receive';
export const RESOURCE_ADD = '@resource/entity-add';
export const RESOURCE_UPDATE = '@resource/entity-update';
export const RESOURCE_DELETE = '@resource/entity-delete';
export const RESOURCE_FINISHED = '@resource/finished';

// RESOURCE | Device
export const DEVICE_LABELS_GET = '@device/labels-get';
export const DEVICE_LABEL_SELECT = '@device/label-select';
export const DEVICE_NAVIGATION_SET = '@device/navigation-set';
export const DEVICE_LIST_REQUEST = '@device/list-request';
export const DEVICE_LIST_REQUEST_MORE = '@device/list-request-more';
export const DEVICE_LIST_RECEIVE = '@device/list-receive';
export const DEVICE_LIST_APPEND = '@device/list-append';
export const DEVICE_SELECT = '@device/entity-select';
export const DEVICE_MODE = '@device/entity-mode';
export const DEVICE_REQUEST = '@device/entity-request';
export const DEVICE_RECEIVE = '@device/entity-receive';
export const DEVICE_ADD = '@device/entity-add';
export const DEVICE_UPDATE = '@device/entity-update';
export const DEVICE_DELETE = '@device/entity-delete';
export const DEVICE_FINISHED = '@device/finished';

// RESOURCE | Location
export const LOCATION_LABELS_GET = '@location/labels-get';
export const LOCATION_LABEL_SELECT = '@location/label-select';
export const LOCATION_NAVIGATION_SET = '@location/navigation-set';
export const LOCATION_LIST_REQUEST = '@location/list-request';
export const LOCATION_LIST_REQUEST_MORE = '@location/list-request-more';
export const LOCATION_LIST_RECEIVE = '@location/list-receive';
export const LOCATION_LIST_APPEND = '@location/list-append';
export const LOCATION_SELECT = '@location/entity-select';
export const LOCATION_MODE = '@location/entity-mode';
export const LOCATION_REQUEST = '@location/entity-request';
export const LOCATION_RECEIVE = '@location/entity-receive';
export const LOCATION_ADD = '@location/entity-add';
export const LOCATION_UPDATE = '@location/entity-update';
export const LOCATION_DELETE = '@location/entity-delete';
export const LOCATION_FINISHED = '@location/finished';

// RESOURCE | Person
export const PERSON_LABELS_GET = '@person/labels-get';
export const PERSON_LABEL_SELECT = '@person/label-select';
export const PERSON_NAVIGATION_SET = '@person/navigation-set';
export const PERSON_LIST_REQUEST = '@person/list-request';
export const PERSON_LIST_REQUEST_MORE = '@person/list-request-more';
export const PERSON_LIST_RECEIVE = '@person/list-receive';
export const PERSON_LIST_APPEND = '@person/list-append';
export const PERSON_SELECT = '@person/entity-select';
export const PERSON_MODE = '@person/entity-mode';
export const PERSON_REQUEST = '@person/entity-request';
export const PERSON_RECEIVE = '@person/entity-receive';
export const PERSON_ADD = '@person/entity-add';
export const PERSON_UPDATE = '@person/entity-update';
export const PERSON_DELETE = '@person/entity-delete';
export const PERSON_FINISHED = '@person/finished';

// PROJECT | Project
export const PROJECT_LABELS_GET = '@project/labels-get';
export const PROJECT_LABEL_SELECT = '@project/label-select';
export const PROJECT_NAVIGATION_SET = '@project/navigation-set';
export const PROJECT_LIST_REQUEST = '@project/list-request';
export const PROJECT_LIST_REQUEST_MORE = '@project/list-request-more';
export const PROJECT_LIST_RECEIVE = '@project/list-receive';
export const PROJECT_LIST_APPEND = '@project/list-append';
export const PROJECT_SELECT = '@project/entity-select';
export const PROJECT_MODE = '@project/entity-mode';
export const PROJECT_REQUEST = '@project/entity-request';
export const PROJECT_RECEIVE = '@project/entity-receive';
export const PROJECT_ADD = '@project/entity-add';
export const PROJECT_UPDATE = '@project/entity-update';
export const PROJECT_DELETE = '@project/entity-delete';
export const PROJECT_FINISHED = '@project/finished';

// PROJECT | Abonnement
export const ABONNEMENT_LABELS_GET = '@abonnement/labels-get';
export const ABONNEMENT_LABEL_SELECT = '@abonnement/label-select';
export const ABONNEMENT_NAVIGATION_SET = '@abonnement/navigation-set';
export const ABONNEMENT_LIST_REQUEST = '@abonnement/list-request';
export const ABONNEMENT_LIST_REQUEST_MORE = '@abonnement/list-request-more';
export const ABONNEMENT_LIST_RECEIVE = '@abonnement/list-receive';
export const ABONNEMENT_LIST_APPEND = '@abonnement/list-append';
export const ABONNEMENT_SELECT = '@abonnement/entity-select';
export const ABONNEMENT_MODE = '@abonnement/entity-mode';
export const ABONNEMENT_REQUEST = '@abonnement/entity-request';
export const ABONNEMENT_RECEIVE = '@abonnement/entity-receive';
export const ABONNEMENT_ADD = '@abonnement/entity-add';
export const ABONNEMENT_UPDATE = '@abonnement/entity-update';
export const ABONNEMENT_DELETE = '@abonnement/entity-delete';
export const ABONNEMENT_FINISHED = '@abonnement/finished';

// PROJECT | Business
export const BUSINESS_LABELS_GET = '@business/labels-get';
export const BUSINESS_LABEL_SELECT = '@business/label-select';
export const BUSINESS_NAVIGATION_SET = '@business/navigation-set';
export const BUSINESS_LIST_REQUEST = '@business/list-request';
export const BUSINESS_LIST_REQUEST_MORE = '@business/list-request-more';
export const BUSINESS_LIST_RECEIVE = '@business/list-receive';
export const BUSINESS_LIST_APPEND = '@business/list-append';
export const BUSINESS_SELECT = '@business/entity-select';
export const BUSINESS_MODE = '@business/entity-mode';
export const BUSINESS_REQUEST = '@business/entity-request';
export const BUSINESS_RECEIVE = '@business/entity-receive';
export const BUSINESS_ADD = '@business/entity-add';
export const BUSINESS_UPDATE = '@business/entity-update';
export const BUSINESS_DELETE = '@business/entity-delete';
export const BUSINESS_FINISHED = '@business/finished';

// PROJECT | Event
export const EVENT_LABELS_GET = '@event/labels-get';
export const EVENT_LABEL_SELECT = '@event/label-select';
export const EVENT_NAVIGATION_SET = '@event/navigation-set';
export const EVENT_LIST_REQUEST = '@event/list-request';
export const EVENT_LIST_REQUEST_MORE = '@event/list-request-more';
export const EVENT_LIST_RECEIVE = '@event/list-receive';
export const EVENT_LIST_APPEND = '@event/list-append';
export const EVENT_SELECT = '@event/entity-select';
export const EVENT_MODE = '@event/entity-mode';
export const EVENT_REQUEST = '@event/entity-request';
export const EVENT_RECEIVE = '@event/entity-receive';
export const EVENT_ADD = '@event/entity-add';
export const EVENT_UPDATE = '@event/entity-update';
export const EVENT_DELETE = '@event/entity-delete';
export const EVENT_FINISHED = '@event/finished';

// PROJECT | Semester
export const SEMESTER_LABELS_GET = '@semester/labels-get';
export const SEMESTER_LABEL_SELECT = '@semester/label-select';
export const SEMESTER_NAVIGATION_SET = '@semester/navigation-set';
export const SEMESTER_LIST_REQUEST = '@semester/list-request';
export const SEMESTER_LIST_REQUEST_MORE = '@semester/list-request-more';
export const SEMESTER_LIST_RECEIVE = '@semester/list-receive';
export const SEMESTER_LIST_APPEND = '@semester/list-append';
export const SEMESTER_SELECT = '@semester/entity-select';
export const SEMESTER_MODE = '@semester/entity-mode';
export const SEMESTER_REQUEST = '@semester/entity-request';
export const SEMESTER_RECEIVE = '@semester/entity-receive';
export const SEMESTER_ADD = '@semester/entity-add';
export const SEMESTER_UPDATE = '@semester/entity-update';
export const SEMESTER_DELETE = '@semester/entity-delete';
export const SEMESTER_FINISHED = '@semester/finished';

// PROJECT | Solution
export const SOLUTION_LABELS_GET = '@solution/labels-get';
export const SOLUTION_LABEL_SELECT = '@solution/label-select';
export const SOLUTION_NAVIGATION_SET = '@solution/navigation-set';
export const SOLUTION_LIST_REQUEST = '@solution/list-request';
export const SOLUTION_LIST_REQUEST_MORE = '@solution/list-request-more';
export const SOLUTION_LIST_RECEIVE = '@solution/list-receive';
export const SOLUTION_LIST_APPEND = '@solution/list-append';
export const SOLUTION_SELECT = '@solution/entity-select';
export const SOLUTION_MODE = '@solution/entity-mode';
export const SOLUTION_REQUEST = '@solution/entity-request';
export const SOLUTION_RECEIVE = '@solution/entity-receive';
export const SOLUTION_ADD = '@solution/entity-add';
export const SOLUTION_UPDATE = '@solution/entity-update';
export const SOLUTION_DELETE = '@solution/entity-delete';
export const SOLUTION_FINISHED = '@solution/finished';

// PROJECT | Travel
export const TRAVEL_LABELS_GET = '@travel/labels-get';
export const TRAVEL_LABEL_SELECT = '@travel/label-select';
export const TRAVEL_NAVIGATION_SET = '@travel/navigation-set';
export const TRAVEL_LIST_REQUEST = '@travel/list-request';
export const TRAVEL_LIST_REQUEST_MORE = '@travel/list-request-more';
export const TRAVEL_LIST_RECEIVE = '@travel/list-receive';
export const TRAVEL_LIST_APPEND = '@travel/list-append';
export const TRAVEL_SELECT = '@travel/entity-select';
export const TRAVEL_MODE = '@travel/entity-mode';
export const TRAVEL_REQUEST = '@travel/entity-request';
export const TRAVEL_RECEIVE = '@travel/entity-receive';
export const TRAVEL_ADD = '@travel/entity-add';
export const TRAVEL_UPDATE = '@travel/entity-update';
export const TRAVEL_DELETE = '@travel/entity-delete';
export const TRAVEL_FINISHED = '@travel/finished';

// JOB | Job
export const JOB_LABELS_GET = '@job/labels-get';
export const JOB_LABEL_SELECT = '@job/label-select';
export const JOB_NAVIGATION_SET = '@job/navigation-set';
export const JOB_LIST_REQUEST = '@job/list-request';
export const JOB_LIST_REQUEST_MORE = '@job/list-request-more';
export const JOB_LIST_RECEIVE = '@job/list-receive';
export const JOB_LIST_APPEND = '@job/list-append';
export const JOB_SELECT = '@job/entity-select';
export const JOB_MODE = '@job/entity-mode';
export const JOB_REQUEST = '@job/entity-request';
export const JOB_RECEIVE = '@job/entity-receive';
export const JOB_ADD = '@job/entity-add';
export const JOB_UPDATE = '@job/entity-update';
export const JOB_DELETE = '@job/entity-delete';
export const JOB_FINISHED = '@job/finished';

// JOB | Bug
export const BUG_LABELS_GET = '@bug/labels-get';
export const BUG_LABEL_SELECT = '@bug/label-select';
export const BUG_NAVIGATION_SET = '@bug/navigation-set';
export const BUG_LIST_REQUEST = '@bug/list-request';
export const BUG_LIST_REQUEST_MORE = '@bug/list-request-more';
export const BUG_LIST_RECEIVE = '@bug/list-receive';
export const BUG_LIST_APPEND = '@bug/list-append';
export const BUG_SELECT = '@bug/entity-select';
export const BUG_MODE = '@bug/entity-mode';
export const BUG_REQUEST = '@bug/entity-request';
export const BUG_RECEIVE = '@bug/entity-receive';
export const BUG_ADD = '@bug/entity-add';
export const BUG_UPDATE = '@bug/entity-update';
export const BUG_DELETE = '@bug/entity-delete';
export const BUG_FINISHED = '@bug/finished';

// JOB | Case
export const CASE_LABELS_GET = '@case/labels-get';
export const CASE_LABEL_SELECT = '@case/label-select';
export const CASE_NAVIGATION_SET = '@case/navigation-set';
export const CASE_LIST_REQUEST = '@case/list-request';
export const CASE_LIST_REQUEST_MORE = '@case/list-request-more';
export const CASE_LIST_RECEIVE = '@case/list-receive';
export const CASE_LIST_APPEND = '@case/list-append';
export const CASE_SELECT = '@case/entity-select';
export const CASE_MODE = '@case/entity-mode';
export const CASE_REQUEST = '@case/entity-request';
export const CASE_RECEIVE = '@case/entity-receive';
export const CASE_ADD = '@case/entity-add';
export const CASE_UPDATE = '@case/entity-update';
export const CASE_DELETE = '@case/entity-delete';
export const CASE_FINISHED = '@case/finished';

// JOB | Complaint
export const COMPLAINT_LABELS_GET = '@complaint/labels-get';
export const COMPLAINT_LABEL_SELECT = '@complaint/label-select';
export const COMPLAINT_NAVIGATION_SET = '@complaint/navigation-set';
export const COMPLAINT_LIST_REQUEST = '@complaint/list-request';
export const COMPLAINT_LIST_REQUEST_MORE = '@complaint/list-request-more';
export const COMPLAINT_LIST_RECEIVE = '@complaint/list-receive';
export const COMPLAINT_LIST_APPEND = '@complaint/list-append';
export const COMPLAINT_SELECT = '@complaint/entity-select';
export const COMPLAINT_MODE = '@complaint/entity-mode';
export const COMPLAINT_REQUEST = '@complaint/entity-request';
export const COMPLAINT_RECEIVE = '@complaint/entity-receive';
export const COMPLAINT_ADD = '@complaint/entity-add';
export const COMPLAINT_UPDATE = '@complaint/entity-update';
export const COMPLAINT_DELETE = '@complaint/entity-delete';
export const COMPLAINT_FINISHED = '@complaint/finished';

// JOB | Course
export const COURSE_LABELS_GET = '@course/labels-get';
export const COURSE_LABEL_SELECT = '@course/label-select';
export const COURSE_NAVIGATION_SET = '@course/navigation-set';
export const COURSE_LIST_REQUEST = '@course/list-request';
export const COURSE_LIST_REQUEST_MORE = '@course/list-request-more';
export const COURSE_LIST_RECEIVE = '@course/list-receive';
export const COURSE_LIST_APPEND = '@course/list-append';
export const COURSE_SELECT = '@course/entity-select';
export const COURSE_MODE = '@course/entity-mode';
export const COURSE_REQUEST = '@course/entity-request';
export const COURSE_RECEIVE = '@course/entity-receive';
export const COURSE_ADD = '@course/entity-add';
export const COURSE_UPDATE = '@course/entity-update';
export const COURSE_DELETE = '@course/entity-delete';
export const COURSE_FINISHED = '@course/finished';

// JOB | Feature
export const FEATURE_LABELS_GET = '@feature/labels-get';
export const FEATURE_LABEL_SELECT = '@feature/label-select';
export const FEATURE_NAVIGATION_SET = '@feature/navigation-set';
export const FEATURE_LIST_REQUEST = '@feature/list-request';
export const FEATURE_LIST_REQUEST_MORE = '@feature/list-request-more';
export const FEATURE_LIST_RECEIVE = '@feature/list-receive';
export const FEATURE_LIST_APPEND = '@feature/list-append';
export const FEATURE_SELECT = '@feature/entity-select';
export const FEATURE_MODE = '@feature/entity-mode';
export const FEATURE_REQUEST = '@feature/entity-request';
export const FEATURE_RECEIVE = '@feature/entity-receive';
export const FEATURE_ADD = '@feature/entity-add';
export const FEATURE_UPDATE = '@feature/entity-update';
export const FEATURE_DELETE = '@feature/entity-delete';
export const FEATURE_FINISHED = '@feature/finished';

// JOB | Idea
export const IDEA_LABELS_GET = '@idea/labels-get';
export const IDEA_LABEL_SELECT = '@idea/label-select';
export const IDEA_NAVIGATION_SET = '@idea/navigation-set';
export const IDEA_LIST_REQUEST = '@idea/list-request';
export const IDEA_LIST_REQUEST_MORE = '@idea/list-request-more';
export const IDEA_LIST_RECEIVE = '@idea/list-receive';
export const IDEA_LIST_APPEND = '@idea/list-append';
export const IDEA_SELECT = '@idea/entity-select';
export const IDEA_MODE = '@idea/entity-mode';
export const IDEA_REQUEST = '@idea/entity-request';
export const IDEA_RECEIVE = '@idea/entity-receive';
export const IDEA_ADD = '@idea/entity-add';
export const IDEA_UPDATE = '@idea/entity-update';
export const IDEA_DELETE = '@idea/entity-delete';
export const IDEA_FINISHED = '@idea/finished';

// JOB | Requirement
export const REQUIREMENT_LABELS_GET = '@requirement/labels-get';
export const REQUIREMENT_LABEL_SELECT = '@requirement/label-select';
export const REQUIREMENT_NAVIGATION_SET = '@requirement/navigation-set';
export const REQUIREMENT_LIST_REQUEST = '@requirement/list-request';
export const REQUIREMENT_LIST_REQUEST_MORE = '@requirement/list-request-more';
export const REQUIREMENT_LIST_RECEIVE = '@requirement/list-receive';
export const REQUIREMENT_LIST_APPEND = '@requirement/list-append';
export const REQUIREMENT_SELECT = '@requirement/entity-select';
export const REQUIREMENT_MODE = '@requirement/entity-mode';
export const REQUIREMENT_REQUEST = '@requirement/entity-request';
export const REQUIREMENT_RECEIVE = '@requirement/entity-receive';
export const REQUIREMENT_ADD = '@requirement/entity-add';
export const REQUIREMENT_UPDATE = '@requirement/entity-update';
export const REQUIREMENT_DELETE = '@requirement/entity-delete';
export const REQUIREMENT_FINISHED = '@requirement/finished';

// JOB | Service
export const SERVICE_LABELS_GET = '@service/labels-get';
export const SERVICE_LABEL_SELECT = '@service/label-select';
export const SERVICE_NAVIGATION_SET = '@service/navigation-set';
export const SERVICE_LIST_REQUEST = '@service/list-request';
export const SERVICE_LIST_REQUEST_MORE = '@service/list-request-more';
export const SERVICE_LIST_RECEIVE = '@service/list-receive';
export const SERVICE_LIST_APPEND = '@service/list-append';
export const SERVICE_SELECT = '@service/entity-select';
export const SERVICE_MODE = '@service/entity-mode';
export const SERVICE_REQUEST = '@service/entity-request';
export const SERVICE_RECEIVE = '@service/entity-receive';
export const SERVICE_ADD = '@service/entity-add';
export const SERVICE_UPDATE = '@service/entity-update';
export const SERVICE_DELETE = '@service/entity-delete';
export const SERVICE_FINISHED = '@service/finished';

// JOB | Show
export const SHOW_LABELS_GET = '@show/labels-get';
export const SHOW_LABEL_SELECT = '@show/label-select';
export const SHOW_NAVIGATION_SET = '@show/navigation-set';
export const SHOW_LIST_REQUEST = '@show/list-request';
export const SHOW_LIST_REQUEST_MORE = '@show/list-request-more';
export const SHOW_LIST_RECEIVE = '@show/list-receive';
export const SHOW_LIST_APPEND = '@show/list-append';
export const SHOW_SELECT = '@show/entity-select';
export const SHOW_MODE = '@show/entity-mode';
export const SHOW_REQUEST = '@show/entity-request';
export const SHOW_RECEIVE = '@show/entity-receive';
export const SHOW_ADD = '@show/entity-add';
export const SHOW_UPDATE = '@show/entity-update';
export const SHOW_DELETE = '@show/entity-delete';
export const SHOW_FINISHED = '@show/finished';

// JOB | Tour
export const TOUR_LABELS_GET = '@tour/labels-get';
export const TOUR_LABEL_SELECT = '@tour/label-select';
export const TOUR_NAVIGATION_SET = '@tour/navigation-set';
export const TOUR_LIST_REQUEST = '@tour/list-request';
export const TOUR_LIST_REQUEST_MORE = '@tour/list-request-more';
export const TOUR_LIST_RECEIVE = '@tour/list-receive';
export const TOUR_LIST_APPEND = '@tour/list-append';
export const TOUR_SELECT = '@tour/entity-select';
export const TOUR_MODE = '@tour/entity-mode';
export const TOUR_REQUEST = '@tour/entity-request';
export const TOUR_RECEIVE = '@tour/entity-receive';
export const TOUR_ADD = '@tour/entity-add';
export const TOUR_UPDATE = '@tour/entity-update';
export const TOUR_DELETE = '@tour/entity-delete';
export const TOUR_FINISHED = '@tour/finished';

// TASK | Task
export const TASK_LABELS_GET = '@task/labels-get';
export const TASK_LABEL_SELECT = '@task/label-select';
export const TASK_NAVIGATION_SET = '@task/navigation-set';
export const TASK_LIST_REQUEST = '@task/list-request';
export const TASK_LIST_REQUEST_MORE = '@task/list-request-more';
export const TASK_LIST_RECEIVE = '@task/list-receive';
export const TASK_LIST_APPEND = '@task/list-append';
export const TASK_SELECT = '@task/entity-select';
export const TASK_MODE = '@task/entity-mode';
export const TASK_REQUEST = '@task/entity-request';
export const TASK_RECEIVE = '@task/entity-receive';
export const TASK_ADD = '@task/entity-add';
export const TASK_UPDATE = '@task/entity-update';
export const TASK_DELETE = '@task/entity-delete';
export const TASK_FINISHED = '@task/finished';

// TASK | Lesson
export const LESSON_LABELS_GET = '@lesson/labels-get';
export const LESSON_LABEL_SELECT = '@lesson/label-select';
export const LESSON_NAVIGATION_SET = '@lesson/navigation-set';
export const LESSON_LIST_REQUEST = '@lesson/list-request';
export const LESSON_LIST_REQUEST_MORE = '@lesson/list-request-more';
export const LESSON_LIST_RECEIVE = '@lesson/list-receive';
export const LESSON_LIST_APPEND = '@lesson/list-append';
export const LESSON_SELECT = '@lesson/entity-select';
export const LESSON_MODE = '@lesson/entity-mode';
export const LESSON_REQUEST = '@lesson/entity-request';
export const LESSON_RECEIVE = '@lesson/entity-receive';
export const LESSON_ADD = '@lesson/entity-add';
export const LESSON_UPDATE = '@lesson/entity-update';
export const LESSON_DELETE = '@lesson/entity-delete';
export const LESSON_FINISHED = '@lesson/finished';

// TASK | Seat
export const SEAT_LABELS_GET = '@seat/labels-get';
export const SEAT_LABEL_SELECT = '@seat/label-select';
export const SEAT_NAVIGATION_SET = '@seat/navigation-set';
export const SEAT_LIST_REQUEST = '@seat/list-request';
export const SEAT_LIST_REQUEST_MORE = '@seat/list-request-more';
export const SEAT_LIST_RECEIVE = '@seat/list-receive';
export const SEAT_LIST_APPEND = '@seat/list-append';
export const SEAT_SELECT = '@seat/entity-select';
export const SEAT_MODE = '@seat/entity-mode';
export const SEAT_REQUEST = '@seat/entity-request';
export const SEAT_RECEIVE = '@seat/entity-receive';
export const SEAT_ADD = '@seat/entity-add';
export const SEAT_UPDATE = '@seat/entity-update';
export const SEAT_DELETE = '@seat/entity-delete';
export const SEAT_FINISHED = '@seat/finished';

// TASK | Session
export const SESSION_LABELS_GET = '@session/labels-get';
export const SESSION_LABEL_SELECT = '@session/label-select';
export const SESSION_NAVIGATION_SET = '@session/navigation-set';
export const SESSION_LIST_REQUEST = '@session/list-request';
export const SESSION_LIST_REQUEST_MORE = '@session/list-request-more';
export const SESSION_LIST_RECEIVE = '@session/list-receive';
export const SESSION_LIST_APPEND = '@session/list-append';
export const SESSION_SELECT = '@session/entity-select';
export const SESSION_MODE = '@session/entity-mode';
export const SESSION_REQUEST = '@session/entity-request';
export const SESSION_RECEIVE = '@session/entity-receive';
export const SESSION_ADD = '@session/entity-add';
export const SESSION_UPDATE = '@session/entity-update';
export const SESSION_DELETE = '@session/entity-delete';
export const SESSION_FINISHED = '@session/finished';

// SALESDOC | SalesDoc
export const SALESDOC_LABELS_GET = '@salesdoc/labels-get';
export const SALESDOC_LABEL_SELECT = '@salesdoc/label-select';
export const SALESDOC_NAVIGATION_SET = '@salesdoc/navigation-set';
export const SALESDOC_LIST_REQUEST = '@salesdoc/list-request';
export const SALESDOC_LIST_REQUEST_MORE = '@salesdoc/list-request-more';
export const SALESDOC_LIST_RECEIVE = '@salesdoc/list-receive';
export const SALESDOC_LIST_APPEND = '@salesdoc/list-append';
export const SALESDOC_SELECT = '@salesdoc/entity-select';
export const SALESDOC_MODE = '@salesdoc/entity-mode';
export const SALESDOC_REQUEST = '@salesdoc/entity-request';
export const SALESDOC_RECEIVE = '@salesdoc/entity-receive';
export const SALESDOC_ADD = '@salesdoc/entity-add';
export const SALESDOC_UPDATE = '@salesdoc/entity-update';
export const SALESDOC_DELETE = '@salesdoc/entity-delete';
export const SALESDOC_FINISHED = '@salesdoc/finished';

// SALESDOC | SalesQuote
export const SALESQUOTE_LABELS_GET = '@salesquote/labels-get';
export const SALESQUOTE_LABEL_SELECT = '@salesquote/label-select';
export const SALESQUOTE_NAVIGATION_SET = '@salesquote/navigation-set';
export const SALESQUOTE_LIST_REQUEST = '@salesquote/list-request';
export const SALESQUOTE_LIST_REQUEST_MORE = '@salesquote/list-request-more';
export const SALESQUOTE_LIST_RECEIVE = '@salesquote/list-receive';
export const SALESQUOTE_LIST_APPEND = '@salesquote/list-append';
export const SALESQUOTE_SELECT = '@salesquote/entity-select';
export const SALESQUOTE_MODE = '@salesquote/entity-mode';
export const SALESQUOTE_REQUEST = '@salesquote/entity-request';
export const SALESQUOTE_RECEIVE = '@salesquote/entity-receive';
export const SALESQUOTE_ADD = '@salesquote/entity-add';
export const SALESQUOTE_UPDATE = '@salesquote/entity-update';
export const SALESQUOTE_DELETE = '@salesquote/entity-delete';
export const SALESQUOTE_FINISHED = '@salesquote/finished';

// SALESDOC | SalesOrder
export const SALESORDER_LABELS_GET = '@salesorder/labels-get';
export const SALESORDER_LABEL_SELECT = '@salesorder/label-select';
export const SALESORDER_NAVIGATION_SET = '@salesorder/navigation-set';
export const SALESORDER_LIST_REQUEST = '@salesorder/list-request';
export const SALESORDER_LIST_REQUEST_MORE = '@salesorder/list-request-more';
export const SALESORDER_LIST_RECEIVE = '@salesorder/list-receive';
export const SALESORDER_LIST_APPEND = '@salesorder/list-append';
export const SALESORDER_SELECT = '@salesorder/entity-select';
export const SALESORDER_MODE = '@salesorder/entity-mode';
export const SALESORDER_REQUEST = '@salesorder/entity-request';
export const SALESORDER_RECEIVE = '@salesorder/entity-receive';
export const SALESORDER_ADD = '@salesorder/entity-add';
export const SALESORDER_UPDATE = '@salesorder/entity-update';
export const SALESORDER_DELETE = '@salesorder/entity-delete';
export const SALESORDER_FINISHED = '@salesorder/finished';

// SALESDOC | SalesInvoice
export const SALESINVOICE_LABELS_GET = '@salesinvoice/labels-get';
export const SALESINVOICE_LABEL_SELECT = '@salesinvoice/label-select';
export const SALESINVOICE_NAVIGATION_SET = '@salesinvoice/navigation-set';
export const SALESINVOICE_LIST_REQUEST = '@salesinvoice/list-request';
export const SALESINVOICE_LIST_REQUEST_MORE = '@salesinvoice/list-request-more';
export const SALESINVOICE_LIST_RECEIVE = '@salesinvoice/list-receive';
export const SALESINVOICE_LIST_APPEND = '@salesinvoice/list-append';
export const SALESINVOICE_SELECT = '@salesinvoice/entity-select';
export const SALESINVOICE_MODE = '@salesinvoice/entity-mode';
export const SALESINVOICE_REQUEST = '@salesinvoice/entity-request';
export const SALESINVOICE_RECEIVE = '@salesinvoice/entity-receive';
export const SALESINVOICE_ADD = '@salesinvoice/entity-add';
export const SALESINVOICE_UPDATE = '@salesinvoice/entity-update';
export const SALESINVOICE_DELETE = '@salesinvoice/entity-delete';
export const SALESINVOICE_FINISHED = '@salesinvoice/finished';

// PURCHASEDOC | PurchaseDoc
export const PURCHASEDOC_LABELS_GET = '@purchasedoc/labels-get';
export const PURCHASEDOC_LABEL_SELECT = '@purchasedoc/label-select';
export const PURCHASEDOC_NAVIGATION_SET = '@purchasedoc/navigation-set';
export const PURCHASEDOC_LIST_REQUEST = '@purchasedoc/list-request';
export const PURCHASEDOC_LIST_REQUEST_MORE = '@purchasedoc/list-request-more';
export const PURCHASEDOC_LIST_RECEIVE = '@purchasedoc/list-receive';
export const PURCHASEDOC_LIST_APPEND = '@purchasedoc/list-append';
export const PURCHASEDOC_SELECT = '@purchasedoc/entity-select';
export const PURCHASEDOC_MODE = '@purchasedoc/entity-mode';
export const PURCHASEDOC_REQUEST = '@purchasedoc/entity-request';
export const PURCHASEDOC_RECEIVE = '@purchasedoc/entity-receive';
export const PURCHASEDOC_ADD = '@purchasedoc/entity-add';
export const PURCHASEDOC_UPDATE = '@purchasedoc/entity-update';
export const PURCHASEDOC_DELETE = '@purchasedoc/entity-delete';
export const PURCHASEDOC_FINISHED = '@purchasedoc/finished';

// PURCHASEDOC | PurchaseQuote
export const PURCHASEQUOTE_LABELS_GET = '@purchasequote/labels-get';
export const PURCHASEQUOTE_LABEL_SELECT = '@purchasequote/label-select';
export const PURCHASEQUOTE_NAVIGATION_SET = '@purchasequote/navigation-set';
export const PURCHASEQUOTE_LIST_REQUEST = '@purchasequote/list-request';
export const PURCHASEQUOTE_LIST_REQUEST_MORE =
  '@purchasequote/list-request-more';
export const PURCHASEQUOTE_LIST_RECEIVE = '@purchasequote/list-receive';
export const PURCHASEQUOTE_LIST_APPEND = '@purchasequote/list-append';
export const PURCHASEQUOTE_SELECT = '@purchasequote/entity-select';
export const PURCHASEQUOTE_MODE = '@purchasequote/entity-mode';
export const PURCHASEQUOTE_REQUEST = '@purchasequote/entity-request';
export const PURCHASEQUOTE_RECEIVE = '@purchasequote/entity-receive';
export const PURCHASEQUOTE_ADD = '@purchasequote/entity-add';
export const PURCHASEQUOTE_UPDATE = '@purchasequote/entity-update';
export const PURCHASEQUOTE_DELETE = '@purchasequote/entity-delete';
export const PURCHASEQUOTE_FINISHED = '@purchasequote/finished';

// PURCHASEDOC | PurchaseOrder
export const PURCHASEORDER_LABELS_GET = '@purchaseorder/labels-get';
export const PURCHASEORDER_LABEL_SELECT = '@purchaseorder/label-select';
export const PURCHASEORDER_NAVIGATION_SET = '@purchaseorder/navigation-set';
export const PURCHASEORDER_LIST_REQUEST = '@purchaseorder/list-request';
export const PURCHASEORDER_LIST_REQUEST_MORE =
  '@purchaseorder/list-request-more';
export const PURCHASEORDER_LIST_RECEIVE = '@purchaseorder/list-receive';
export const PURCHASEORDER_LIST_APPEND = '@purchaseorder/list-append';
export const PURCHASEORDER_SELECT = '@purchaseorder/entity-select';
export const PURCHASEORDER_MODE = '@purchaseorder/entity-mode';
export const PURCHASEORDER_REQUEST = '@purchaseorder/entity-request';
export const PURCHASEORDER_RECEIVE = '@purchaseorder/entity-receive';
export const PURCHASEORDER_ADD = '@purchaseorder/entity-add';
export const PURCHASEORDER_UPDATE = '@purchaseorder/entity-update';
export const PURCHASEORDER_DELETE = '@purchaseorder/entity-delete';
export const PURCHASEORDER_FINISHED = '@purchaseorder/finished';

// PURCHASEDOC | PurchaseInvoice
export const PURCHASEINVOICE_LABELS_GET = '@purchaseinvoice/labels-get';
export const PURCHASEINVOICE_LABEL_SELECT = '@purchaseinvoice/label-select';
export const PURCHASEINVOICE_NAVIGATION_SET = '@purchaseinvoice/navigation-set';
export const PURCHASEINVOICE_LIST_REQUEST = '@purchaseinvoice/list-request';
export const PURCHASEINVOICE_LIST_REQUEST_MORE =
  '@purchaseinvoice/list-request-more';
export const PURCHASEINVOICE_LIST_RECEIVE = '@purchaseinvoice/list-receive';
export const PURCHASEINVOICE_LIST_APPEND = '@purchaseinvoice/list-append';
export const PURCHASEINVOICE_SELECT = '@purchaseinvoice/entity-select';
export const PURCHASEINVOICE_MODE = '@purchaseinvoice/entity-mode';
export const PURCHASEINVOICE_REQUEST = '@purchaseinvoice/entity-request';
export const PURCHASEINVOICE_RECEIVE = '@purchaseinvoice/entity-receive';
export const PURCHASEINVOICE_ADD = '@purchaseinvoice/entity-add';
export const PURCHASEINVOICE_UPDATE = '@purchaseinvoice/entity-update';
export const PURCHASEINVOICE_DELETE = '@purchaseinvoice/entity-delete';
export const PURCHASEINVOICE_FINISHED = '@purchaseinvoice/finished';

/**
 * ORGANIZATION functions
 */

// Absence
export const ABSENCE_LABELS_GET = '@absence/labels-get';
export const ABSENCE_LABEL_SELECT = '@absence/label-select';
export const ABSENCE_NAVIGATION_SET = '@absence/navigation-set';
export const ABSENCE_LIST_REQUEST = '@absence/list-request';
export const ABSENCE_LIST_REQUEST_MORE = '@absence/list-request-more';
export const ABSENCE_LIST_RECEIVE = '@absence/list-receive';
export const ABSENCE_LIST_APPEND = '@absence/list-append';
export const ABSENCE_SELECT = '@absence/entity-select';
export const ABSENCE_MODE = '@absence/entity-mode';
export const ABSENCE_REQUEST = '@absence/entity-request';
export const ABSENCE_RECEIVE = '@absence/entity-receive';
export const ABSENCE_ADD = '@absence/entity-add';
export const ABSENCE_UPDATE = '@absence/entity-update';
export const ABSENCE_DELETE = '@absence/entity-delete';
export const ABSENCE_FINISHED = '@absence/finished';

// AbsenceType
export const ABSENCETYPE_LABELS_GET = '@absencetype/labels-get';
export const ABSENCETYPE_LABEL_SELECT = '@absencetype/label-select';
export const ABSENCETYPE_NAVIGATION_SET = '@absencetype/navigation-set';
export const ABSENCETYPE_LIST_REQUEST = '@absencetype/list-request';
export const ABSENCETYPE_LIST_REQUEST_MORE = '@absencetype/list-request-more';
export const ABSENCETYPE_LIST_RECEIVE = '@absencetype/list-receive';
export const ABSENCETYPE_LIST_APPEND = '@absencetype/list-append';
export const ABSENCETYPE_SELECT = '@absencetype/entity-select';
export const ABSENCETYPE_MODE = '@absencetype/entity-mode';
export const ABSENCETYPE_REQUEST = '@absencetype/entity-request';
export const ABSENCETYPE_RECEIVE = '@absencetype/entity-receive';
export const ABSENCETYPE_ADD = '@absencetype/entity-add';
export const ABSENCETYPE_UPDATE = '@absencetype/entity-update';
export const ABSENCETYPE_DELETE = '@absencetype/entity-delete';
export const ABSENCETYPE_FINISHED = '@absencetype/finished';

// ActionContact
export const ACTIONCONTACT_LABELS_GET = '@actioncontact/labels-get';
export const ACTIONCONTACT_LABEL_SELECT = '@actioncontact/label-select';
export const ACTIONCONTACT_NAVIGATION_SET = '@actioncontact/navigation-set';
export const ACTIONCONTACT_LIST_REQUEST = '@actioncontact/list-request';
export const ACTIONCONTACT_LIST_REQUEST_MORE =
  '@actioncontact/list-request-more';
export const ACTIONCONTACT_LIST_RECEIVE = '@actioncontact/list-receive';
export const ACTIONCONTACT_LIST_APPEND = '@actioncontact/list-append';
export const ACTIONCONTACT_SELECT = '@actioncontact/entity-select';
export const ACTIONCONTACT_MODE = '@actioncontact/entity-mode';
export const ACTIONCONTACT_REQUEST = '@actioncontact/entity-request';
export const ACTIONCONTACT_RECEIVE = '@actioncontact/entity-receive';
export const ACTIONCONTACT_ADD = '@actioncontact/entity-add';
export const ACTIONCONTACT_UPDATE = '@actioncontact/entity-update';
export const ACTIONCONTACT_DELETE = '@actioncontact/entity-delete';
export const ACTIONCONTACT_FINISHED = '@actioncontact/finished';

// Activity
export const ACTIVITY_LABELS_GET = '@activity/labels-get';
export const ACTIVITY_LABEL_SELECT = '@activity/label-select';
export const ACTIVITY_NAVIGATION_SET = '@activity/navigation-set';
export const ACTIVITY_LIST_REQUEST = '@activity/list-request';
export const ACTIVITY_LIST_REQUEST_MORE = '@activity/list-request-more';
export const ACTIVITY_LIST_RECEIVE = '@activity/list-receive';
export const ACTIVITY_LIST_APPEND = '@activity/list-append';
export const ACTIVITY_SELECT = '@activity/entity-select';
export const ACTIVITY_MODE = '@activity/entity-mode';
export const ACTIVITY_REQUEST = '@activity/entity-request';
export const ACTIVITY_RECEIVE = '@activity/entity-receive';
export const ACTIVITY_ADD = '@activity/entity-add';
export const ACTIVITY_UPDATE = '@activity/entity-update';
export const ACTIVITY_DELETE = '@activity/entity-delete';
export const ACTIVITY_FINISHED = '@activity/finished';

// ActivityAttachment
export const ACTIVITYATTACHMENT_LABELS_GET = '@activityattachment/labels-get';
export const ACTIVITYATTACHMENT_LABEL_SELECT =
  '@activityattachment/label-select';
export const ACTIVITYATTACHMENT_NAVIGATION_SET =
  '@activityattachment/navigation-set';
export const ACTIVITYATTACHMENT_LIST_REQUEST =
  '@activityattachment/list-request';
export const ACTIVITYATTACHMENT_LIST_REQUEST_MORE =
  '@activityattachment/list-request-more';
export const ACTIVITYATTACHMENT_LIST_RECEIVE =
  '@activityattachment/list-receive';
export const ACTIVITYATTACHMENT_LIST_APPEND = '@activityattachment/list-append';
export const ACTIVITYATTACHMENT_SELECT = '@activityattachment/entity-select';
export const ACTIVITYATTACHMENT_MODE = '@activityattachment/entity-mode';
export const ACTIVITYATTACHMENT_REQUEST = '@activityattachment/entity-request';
export const ACTIVITYATTACHMENT_RECEIVE = '@activityattachment/entity-receive';
export const ACTIVITYATTACHMENT_ADD = '@activityattachment/entity-add';
export const ACTIVITYATTACHMENT_UPDATE = '@activityattachment/entity-update';
export const ACTIVITYATTACHMENT_DELETE = '@activityattachment/entity-delete';
export const ACTIVITYATTACHMENT_FINISHED = '@activityattachment/finished';

// Advice
export const ADVICE_LABELS_GET = '@advice/labels-get';
export const ADVICE_LABEL_SELECT = '@advice/label-select';
export const ADVICE_NAVIGATION_SET = '@advice/navigation-set';
export const ADVICE_LIST_REQUEST = '@advice/list-request';
export const ADVICE_LIST_REQUEST_MORE = '@advice/list-request-more';
export const ADVICE_LIST_RECEIVE = '@advice/list-receive';
export const ADVICE_LIST_APPEND = '@advice/list-append';
export const ADVICE_SELECT = '@advice/entity-select';
export const ADVICE_MODE = '@advice/entity-mode';
export const ADVICE_REQUEST = '@advice/entity-request';
export const ADVICE_RECEIVE = '@advice/entity-receive';
export const ADVICE_ADD = '@advice/entity-add';
export const ADVICE_UPDATE = '@advice/entity-update';
export const ADVICE_DELETE = '@advice/entity-delete';
export const ADVICE_FINISHED = '@advice/finished';

// adviceattachment
export const ADVICEATTACHMENT_LABELS_GET = '@adviceattachment/labels-get';
export const ADVICEATTACHMENT_LABEL_SELECT = '@adviceattachment/label-select';
export const ADVICEATTACHMENT_NAVIGATION_SET =
  '@adviceattachment/navigation-set';
export const ADVICEATTACHMENT_LIST_REQUEST = '@adviceattachment/list-request';
export const ADVICEATTACHMENT_LIST_REQUEST_MORE =
  '@adviceattachment/list-request-more';
export const ADVICEATTACHMENT_LIST_RECEIVE = '@adviceattachment/list-receive';
export const ADVICEATTACHMENT_LIST_APPEND = '@adviceattachment/list-append';
export const ADVICEATTACHMENT_SELECT = '@adviceattachment/entity-select';
export const ADVICEATTACHMENT_MODE = '@adviceattachment/entity-mode';
export const ADVICEATTACHMENT_REQUEST = '@adviceattachment/entity-request';
export const ADVICEATTACHMENT_RECEIVE = '@adviceattachment/entity-receive';
export const ADVICEATTACHMENT_ADD = '@adviceattachment/entity-add';
export const ADVICEATTACHMENT_UPDATE = '@adviceattachment/entity-update';
export const ADVICEATTACHMENT_DELETE = '@adviceattachment/entity-delete';
export const ADVICEATTACHMENT_FINISHED = '@adviceattachment/finished';

// Area
export const AREA_LABELS_GET = '@area/labels-get';
export const AREA_LABEL_SELECT = '@area/label-select';
export const AREA_NAVIGATION_SET = '@area/navigation-set';
export const AREA_LIST_REQUEST = '@area/list-request';
export const AREA_LIST_REQUEST_MORE = '@area/list-request-more';
export const AREA_LIST_RECEIVE = '@area/list-receive';
export const AREA_LIST_APPEND = '@area/list-append';
export const AREA_SELECT = '@area/entity-select';
export const AREA_MODE = '@area/entity-mode';
export const AREA_REQUEST = '@area/entity-request';
export const AREA_RECEIVE = '@area/entity-receive';
export const AREA_ADD = '@area/entity-add';
export const AREA_UPDATE = '@area/entity-update';
export const AREA_DELETE = '@area/entity-delete';
export const AREA_FINISHED = '@area/finished';

// Assignment
export const ASSIGNMENT_LABELS_GET = '@assignment/labels-get';
export const ASSIGNMENT_LABEL_SELECT = '@assignment/label-select';
export const ASSIGNMENT_NAVIGATION_SET = '@assignment/navigation-set';
export const ASSIGNMENT_LIST_REQUEST = '@assignment/list-request';
export const ASSIGNMENT_LIST_REQUEST_MORE = '@assignment/list-request-more';
export const ASSIGNMENT_LIST_RECEIVE = '@assignment/list-receive';
export const ASSIGNMENT_LIST_APPEND = '@assignment/list-append';
export const ASSIGNMENT_SELECT = '@assignment/entity-select';
export const ASSIGNMENT_MODE = '@assignment/entity-mode';
export const ASSIGNMENT_REQUEST = '@assignment/entity-request';
export const ASSIGNMENT_RECEIVE = '@assignment/entity-receive';
export const ASSIGNMENT_ADD = '@assignment/entity-add';
export const ASSIGNMENT_UPDATE = '@assignment/entity-update';
export const ASSIGNMENT_DELETE = '@assignment/entity-delete';
export const ASSIGNMENT_FINISHED = '@assignment/finished';

// AssignmentAttachment
export const ASSIGNMENTATTACHMENT_LABELS_GET =
  '@assignmentattachment/labels-get';
export const ASSIGNMENTATTACHMENT_LABEL_SELECT =
  '@assignmentattachment/label-select';
export const ASSIGNMENTATTACHMENT_NAVIGATION_SET =
  '@assignmentattachment/navigation-set';
export const ASSIGNMENTATTACHMENT_LIST_REQUEST =
  '@assignmentattachment/list-request';
export const ASSIGNMENTATTACHMENT_LIST_REQUEST_MORE =
  '@assignmentattachment/list-request-more';
export const ASSIGNMENTATTACHMENT_LIST_RECEIVE =
  '@assignmentattachment/list-receive';
export const ASSIGNMENTATTACHMENT_LIST_APPEND =
  '@assignmentattachment/list-append';
export const ASSIGNMENTATTACHMENT_SELECT =
  '@assignmentattachment/entity-select';
export const ASSIGNMENTATTACHMENT_MODE = '@assignmentattachment/entity-mode';
export const ASSIGNMENTATTACHMENT_REQUEST =
  '@assignmentattachment/entity-request';
export const ASSIGNMENTATTACHMENT_RECEIVE =
  '@assignmentattachment/entity-receive';
export const ASSIGNMENTATTACHMENT_ADD = '@assignmentattachment/entity-add';
export const ASSIGNMENTATTACHMENT_UPDATE =
  '@assignmentattachment/entity-update';
export const ASSIGNMENTATTACHMENT_DELETE =
  '@assignmentattachment/entity-delete';
export const ASSIGNMENTATTACHMENT_FINISHED = '@assignmentattachment/finished';

// Assortment
export const ASSORTMENT_LABELS_GET = '@assortment/labels-get';
export const ASSORTMENT_LABEL_SELECT = '@assortment/label-select';
export const ASSORTMENT_NAVIGATION_SET = '@assortment/navigation-set';
export const ASSORTMENT_LIST_REQUEST = '@assortment/list-request';
export const ASSORTMENT_LIST_REQUEST_MORE = '@assortment/list-request-more';
export const ASSORTMENT_LIST_RECEIVE = '@assortment/list-receive';
export const ASSORTMENT_LIST_APPEND = '@assortment/list-append';
export const ASSORTMENT_SELECT = '@assortment/entity-select';
export const ASSORTMENT_MODE = '@assortment/entity-mode';
export const ASSORTMENT_REQUEST = '@assortment/entity-request';
export const ASSORTMENT_RECEIVE = '@assortment/entity-receive';
export const ASSORTMENT_ADD = '@assortment/entity-add';
export const ASSORTMENT_UPDATE = '@assortment/entity-update';
export const ASSORTMENT_DELETE = '@assortment/entity-delete';
export const ASSORTMENT_FINISHED = '@assortment/finished';

// AssortmentProduct
export const ASSORTMENTPRODUCT_LABELS_GET = '@assortmentproduct/labels-get';
export const ASSORTMENTPRODUCT_LABEL_SELECT = '@assortmentproduct/label-select';
export const ASSORTMENTPRODUCT_NAVIGATION_SET =
  '@assortmentproduct/navigation-set';
export const ASSORTMENTPRODUCT_LIST_REQUEST = '@assortmentproduct/list-request';
export const ASSORTMENTPRODUCT_LIST_REQUEST_MORE =
  '@assortmentproduct/list-request-more';
export const ASSORTMENTPRODUCT_LIST_RECEIVE = '@assortmentproduct/list-receive';
export const ASSORTMENTPRODUCT_LIST_APPEND = '@assortmentproduct/list-append';
export const ASSORTMENTPRODUCT_SELECT = '@assortmentproduct/entity-select';
export const ASSORTMENTPRODUCT_MODE = '@assortmentproduct/entity-mode';
export const ASSORTMENTPRODUCT_REQUEST = '@assortmentproduct/entity-request';
export const ASSORTMENTPRODUCT_RECEIVE = '@assortmentproduct/entity-receive';
export const ASSORTMENTPRODUCT_ADD = '@assortmentproduct/entity-add';
export const ASSORTMENTPRODUCT_UPDATE = '@assortmentproduct/entity-update';
export const ASSORTMENTPRODUCT_DELETE = '@assortmentproduct/entity-delete';
export const ASSORTMENTPRODUCT_FINISHED = '@assortmentproduct/finished';

// Attachment
export const ATTACHMENT_LABELS_GET = '@attachment/labels-get';
export const ATTACHMENT_LABEL_SELECT = '@attachment/label-select';
export const ATTACHMENT_NAVIGATION_SET = '@attachment/navigation-set';
export const ATTACHMENT_LIST_REQUEST = '@attachment/list-request';
export const ATTACHMENT_LIST_REQUEST_MORE = '@attachment/list-request-more';
export const ATTACHMENT_LIST_RECEIVE = '@attachment/list-receive';
export const ATTACHMENT_LIST_APPEND = '@attachment/list-append';
export const ATTACHMENT_SELECT = '@attachment/entity-select';
export const ATTACHMENT_MODE = '@attachment/entity-mode';
export const ATTACHMENT_REQUEST = '@attachment/entity-request';
export const ATTACHMENT_RECEIVE = '@attachment/entity-receive';
export const ATTACHMENT_ADD = '@attachment/entity-add';
export const ATTACHMENT_UPDATE = '@attachment/entity-update';
export const ATTACHMENT_DELETE = '@attachment/entity-delete';
export const ATTACHMENT_FINISHED = '@attachment/finished';

// Attend
export const ATTEND_LABELS_GET = '@attend/labels-get';
export const ATTEND_LABEL_SELECT = '@attend/label-select';
export const ATTEND_NAVIGATION_SET = '@attend/navigation-set';
export const ATTEND_LIST_REQUEST = '@attend/list-request';
export const ATTEND_LIST_REQUEST_MORE = '@attend/list-request-more';
export const ATTEND_LIST_RECEIVE = '@attend/list-receive';
export const ATTEND_LIST_APPEND = '@attend/list-append';
export const ATTEND_SELECT = '@attend/entity-select';
export const ATTEND_MODE = '@attend/entity-mode';
export const ATTEND_REQUEST = '@attend/entity-request';
export const ATTEND_RECEIVE = '@attend/entity-receive';
export const ATTEND_ADD = '@attend/entity-add';
export const ATTEND_UPDATE = '@attend/entity-update';
export const ATTEND_DELETE = '@attend/entity-delete';
export const ATTEND_FINISHED = '@attend/finished';

// AttendAttachment
export const ATTENDATTACHMENT_LABELS_GET = '@attendattachment/labels-get';
export const ATTENDATTACHMENT_LABEL_SELECT = '@attendattachment/label-select';
export const ATTENDATTACHMENT_NAVIGATION_SET =
  '@attendattachment/navigation-set';
export const ATTENDATTACHMENT_LIST_REQUEST = '@attendattachment/list-request';
export const ATTENDATTACHMENT_LIST_REQUEST_MORE =
  '@attendattachment/list-request-more';
export const ATTENDATTACHMENT_LIST_RECEIVE = '@attendattachment/list-receive';
export const ATTENDATTACHMENT_LIST_APPEND = '@attendattachment/list-append';
export const ATTENDATTACHMENT_SELECT = '@attendattachment/entity-select';
export const ATTENDATTACHMENT_MODE = '@attendattachment/entity-mode';
export const ATTENDATTACHMENT_REQUEST = '@attendattachment/entity-request';
export const ATTENDATTACHMENT_RECEIVE = '@attendattachment/entity-receive';
export const ATTENDATTACHMENT_ADD = '@attendattachment/entity-add';
export const ATTENDATTACHMENT_UPDATE = '@attendattachment/entity-update';
export const ATTENDATTACHMENT_DELETE = '@attendattachment/entity-delete';
export const ATTENDATTACHMENT_FINISHED = '@attendattachment/finished';

// Attendee
export const ATTENDEE_LABELS_GET = '@attendee/labels-get';
export const ATTENDEE_LABEL_SELECT = '@attendee/label-select';
export const ATTENDEE_NAVIGATION_SET = '@attendee/navigation-set';
export const ATTENDEE_LIST_REQUEST = '@attendee/list-request';
export const ATTENDEE_LIST_REQUEST_MORE = '@attendee/list-request-more';
export const ATTENDEE_LIST_RECEIVE = '@attendee/list-receive';
export const ATTENDEE_LIST_APPEND = '@attendee/list-append';
export const ATTENDEE_SELECT = '@attendee/entity-select';
export const ATTENDEE_MODE = '@attendee/entity-mode';
export const ATTENDEE_REQUEST = '@attendee/entity-request';
export const ATTENDEE_RECEIVE = '@attendee/entity-receive';
export const ATTENDEE_ADD = '@attendee/entity-add';
export const ATTENDEE_UPDATE = '@attendee/entity-update';
export const ATTENDEE_DELETE = '@attendee/entity-delete';
export const ATTENDEE_FINISHED = '@attendee/finished';

// AttendeeAttachment
export const ATTENDEEATTACHMENT_LABELS_GET = '@attendeeattachment/labels-get';
export const ATTENDEEATTACHMENT_LABEL_SELECT =
  '@attendeeattachment/label-select';
export const ATTENDEEATTACHMENT_NAVIGATION_SET =
  '@attendeeattachment/navigation-set';
export const ATTENDEEATTACHMENT_LIST_REQUEST =
  '@attendeeattachment/list-request';
export const ATTENDEEATTACHMENT_LIST_REQUEST_MORE =
  '@attendeeattachment/list-request-more';
export const ATTENDEEATTACHMENT_LIST_RECEIVE =
  '@attendeeattachment/list-receive';
export const ATTENDEEATTACHMENT_LIST_APPEND = '@attendeeattachment/list-append';
export const ATTENDEEATTACHMENT_SELECT = '@attendeeattachment/entity-select';
export const ATTENDEEATTACHMENT_MODE = '@attendeeattachment/entity-mode';
export const ATTENDEEATTACHMENT_REQUEST = '@attendeeattachment/entity-request';
export const ATTENDEEATTACHMENT_RECEIVE = '@attendeeattachment/entity-receive';
export const ATTENDEEATTACHMENT_ADD = '@attendeeattachment/entity-add';
export const ATTENDEEATTACHMENT_UPDATE = '@attendeeattachment/entity-update';
export const ATTENDEEATTACHMENT_DELETE = '@attendeeattachment/entity-delete';
export const ATTENDEEATTACHMENT_FINISHED = '@attendeeattachment/finished';

// AttendeeCat
export const ATTENDEECAT_LABELS_GET = '@attendeecat/labels-get';
export const ATTENDEECAT_LABEL_SELECT = '@attendeecat/label-select';
export const ATTENDEECAT_NAVIGATION_SET = '@attendeecat/navigation-set';
export const ATTENDEECAT_LIST_REQUEST = '@attendeecat/list-request';
export const ATTENDEECAT_LIST_REQUEST_MORE = '@attendeecat/list-request-more';
export const ATTENDEECAT_LIST_RECEIVE = '@attendeecat/list-receive';
export const ATTENDEECAT_LIST_APPEND = '@attendeecat/list-append';
export const ATTENDEECAT_SELECT = '@attendeecat/entity-select';
export const ATTENDEECAT_MODE = '@attendeecat/entity-mode';
export const ATTENDEECAT_REQUEST = '@attendeecat/entity-request';
export const ATTENDEECAT_RECEIVE = '@attendeecat/entity-receive';
export const ATTENDEECAT_ADD = '@attendeecat/entity-add';
export const ATTENDEECAT_UPDATE = '@attendeecat/entity-update';
export const ATTENDEECAT_DELETE = '@attendeecat/entity-delete';
export const ATTENDEECAT_FINISHED = '@attendeecat/finished';

// Author
export const AUTHOR_LABELS_GET = '@author/labels-get';
export const AUTHOR_LABEL_SELECT = '@author/label-select';
export const AUTHOR_NAVIGATION_SET = '@author/navigation-set';
export const AUTHOR_LIST_REQUEST = '@author/list-request';
export const AUTHOR_LIST_REQUEST_MORE = '@author/list-request-more';
export const AUTHOR_LIST_RECEIVE = '@author/list-receive';
export const AUTHOR_LIST_APPEND = '@author/list-append';
export const AUTHOR_SELECT = '@author/entity-select';
export const AUTHOR_MODE = '@author/entity-mode';
export const AUTHOR_REQUEST = '@author/entity-request';
export const AUTHOR_RECEIVE = '@author/entity-receive';
export const AUTHOR_ADD = '@author/entity-add';
export const AUTHOR_UPDATE = '@author/entity-update';
export const AUTHOR_DELETE = '@author/entity-delete';
export const AUTHOR_FINISHED = '@author/finished';

// Billing
export const BILLING_LABELS_GET = '@billing/labels-get';
export const BILLING_LABEL_SELECT = '@billing/label-select';
export const BILLING_NAVIGATION_SET = '@billing/navigation-set';
export const BILLING_LIST_REQUEST = '@billing/list-request';
export const BILLING_LIST_REQUEST_MORE = '@billing/list-request-more';
export const BILLING_LIST_RECEIVE = '@billing/list-receive';
export const BILLING_LIST_APPEND = '@billing/list-append';
export const BILLING_SELECT = '@billing/entity-select';
export const BILLING_MODE = '@billing/entity-mode';
export const BILLING_REQUEST = '@billing/entity-request';
export const BILLING_RECEIVE = '@billing/entity-receive';
export const BILLING_ADD = '@billing/entity-add';
export const BILLING_UPDATE = '@billing/entity-update';
export const BILLING_DELETE = '@billing/entity-delete';
export const BILLING_FINISHED = '@billing/finished';

// BillingRun
export const BILLINGRUN_LABELS_GET = '@billingrun/labels-get';
export const BILLINGRUN_LABEL_SELECT = '@billingrun/label-select';
export const BILLINGRUN_NAVIGATION_SET = '@billingrun/navigation-set';
export const BILLINGRUN_LIST_REQUEST = '@billingrun/list-request';
export const BILLINGRUN_LIST_REQUEST_MORE = '@billingrun/list-request-more';
export const BILLINGRUN_LIST_RECEIVE = '@billingrun/list-receive';
export const BILLINGRUN_LIST_APPEND = '@billingrun/list-append';
export const BILLINGRUN_SELECT = '@billingrun/entity-select';
export const BILLINGRUN_MODE = '@billingrun/entity-mode';
export const BILLINGRUN_REQUEST = '@billingrun/entity-request';
export const BILLINGRUN_RECEIVE = '@billingrun/entity-receive';
export const BILLINGRUN_ADD = '@billingrun/entity-add';
export const BILLINGRUN_UPDATE = '@billingrun/entity-update';
export const BILLINGRUN_DELETE = '@billingrun/entity-delete';
export const BILLINGRUN_FINISHED = '@billingrun/finished';

// Blog
export const BLOG_LABELS_GET = '@blog/labels-get';
export const BLOG_LABEL_SELECT = '@blog/label-select';
export const BLOG_NAVIGATION_SET = '@blog/navigation-set';
export const BLOG_LIST_REQUEST = '@blog/list-request';
export const BLOG_LIST_REQUEST_MORE = '@blog/list-request-more';
export const BLOG_LIST_RECEIVE = '@blog/list-receive';
export const BLOG_LIST_APPEND = '@blog/list-append';
export const BLOG_SELECT = '@blog/entity-select';
export const BLOG_MODE = '@blog/entity-mode';
export const BLOG_REQUEST = '@blog/entity-request';
export const BLOG_RECEIVE = '@blog/entity-receive';
export const BLOG_ADD = '@blog/entity-add';
export const BLOG_UPDATE = '@blog/entity-update';
export const BLOG_DELETE = '@blog/entity-delete';
export const BLOG_FINISHED = '@blog/finished';

// Booking
export const BOOKING_LABELS_GET = '@booking/labels-get';
export const BOOKING_LABEL_SELECT = '@booking/label-select';
export const BOOKING_NAVIGATION_SET = '@booking/navigation-set';
export const BOOKING_LIST_REQUEST = '@booking/list-request';
export const BOOKING_LIST_REQUEST_MORE = '@booking/list-request-more';
export const BOOKING_LIST_RECEIVE = '@booking/list-receive';
export const BOOKING_LIST_APPEND = '@booking/list-append';
export const BOOKING_SELECT = '@booking/entity-select';
export const BOOKING_MODE = '@booking/entity-mode';
export const BOOKING_REQUEST = '@booking/entity-request';
export const BOOKING_RECEIVE = '@booking/entity-receive';
export const BOOKING_ADD = '@booking/entity-add';
export const BOOKING_UPDATE = '@booking/entity-update';
export const BOOKING_DELETE = '@booking/entity-delete';
export const BOOKING_FINISHED = '@booking/finished';

// BookingAttachment
export const BOOKINGATTACHMENT_LABELS_GET = '@bookingattachment/labels-get';
export const BOOKINGATTACHMENT_LABEL_SELECT = '@bookingattachment/label-select';
export const BOOKINGATTACHMENT_NAVIGATION_SET =
  '@bookingattachment/navigation-set';
export const BOOKINGATTACHMENT_LIST_REQUEST = '@bookingattachment/list-request';
export const BOOKINGATTACHMENT_LIST_REQUEST_MORE =
  '@bookingattachment/list-request-more';
export const BOOKINGATTACHMENT_LIST_RECEIVE = '@bookingattachment/list-receive';
export const BOOKINGATTACHMENT_LIST_APPEND = '@bookingattachment/list-append';
export const BOOKINGATTACHMENT_SELECT = '@bookingattachment/entity-select';
export const BOOKINGATTACHMENT_MODE = '@bookingattachment/entity-mode';
export const BOOKINGATTACHMENT_REQUEST = '@bookingattachment/entity-request';
export const BOOKINGATTACHMENT_RECEIVE = '@bookingattachment/entity-receive';
export const BOOKINGATTACHMENT_ADD = '@bookingattachment/entity-add';
export const BOOKINGATTACHMENT_UPDATE = '@bookingattachment/entity-update';
export const BOOKINGATTACHMENT_DELETE = '@bookingattachment/entity-delete';
export const BOOKINGATTACHMENT_FINISHED = '@bookingattachment/finished';

// Bundle
export const BUNDLE_LABELS_GET = '@bundle/labels-get';
export const BUNDLE_LABEL_SELECT = '@bundle/label-select';
export const BUNDLE_NAVIGATION_SET = '@bundle/navigation-set';
export const BUNDLE_LIST_REQUEST = '@bundle/list-request';
export const BUNDLE_LIST_REQUEST_MORE = '@bundle/list-request-more';
export const BUNDLE_LIST_RECEIVE = '@bundle/list-receive';
export const BUNDLE_LIST_APPEND = '@bundle/list-append';
export const BUNDLE_SELECT = '@bundle/entity-select';
export const BUNDLE_MODE = '@bundle/entity-mode';
export const BUNDLE_REQUEST = '@bundle/entity-request';
export const BUNDLE_RECEIVE = '@bundle/entity-receive';
export const BUNDLE_ADD = '@bundle/entity-add';
export const BUNDLE_UPDATE = '@bundle/entity-update';
export const BUNDLE_DELETE = '@bundle/entity-delete';
export const BUNDLE_FINISHED = '@bundle/finished';

// BusinessPartner
export const BUSINESSPARTNER_LABELS_GET = '@businesspartner/labels-get';
export const BUSINESSPARTNER_LABEL_SELECT = '@businesspartner/label-select';
export const BUSINESSPARTNER_NAVIGATION_SET = '@businesspartner/navigation-set';
export const BUSINESSPARTNER_LIST_REQUEST = '@businesspartner/list-request';
export const BUSINESSPARTNER_LIST_REQUEST_MORE =
  '@businesspartner/list-request-more';
export const BUSINESSPARTNER_LIST_RECEIVE = '@businesspartner/list-receive';
export const BUSINESSPARTNER_LIST_APPEND = '@businesspartner/list-append';
export const BUSINESSPARTNER_SELECT = '@businesspartner/entity-select';
export const BUSINESSPARTNER_MODE = '@businesspartner/entity-mode';
export const BUSINESSPARTNER_REQUEST = '@businesspartner/entity-request';
export const BUSINESSPARTNER_RECEIVE = '@businesspartner/entity-receive';
export const BUSINESSPARTNER_ADD = '@businesspartner/entity-add';
export const BUSINESSPARTNER_UPDATE = '@businesspartner/entity-update';
export const BUSINESSPARTNER_DELETE = '@businesspartner/entity-delete';
export const BUSINESSPARTNER_FINISHED = '@businesspartner/finished';

// BusinessPartnerAttachment
export const BUSINESSPARTNERATTACHMENT_LABELS_GET =
  '@businesspartnerattachment/labels-get';
export const BUSINESSPARTNERATTACHMENT_LABEL_SELECT =
  '@businesspartnerattachment/label-select';
export const BUSINESSPARTNERATTACHMENT_NAVIGATION_SET =
  '@businesspartnerattachment/navigation-set';
export const BUSINESSPARTNERATTACHMENT_LIST_REQUEST =
  '@businesspartnerattachment/list-request';
export const BUSINESSPARTNERATTACHMENT_LIST_REQUEST_MORE =
  '@businesspartnerattachment/list-request-more';
export const BUSINESSPARTNERATTACHMENT_LIST_RECEIVE =
  '@businesspartnerattachment/list-receive';
export const BUSINESSPARTNERATTACHMENT_LIST_APPEND =
  '@businesspartnerattachment/list-append';
export const BUSINESSPARTNERATTACHMENT_SELECT =
  '@businesspartnerattachment/entity-select';
export const BUSINESSPARTNERATTACHMENT_MODE =
  '@businesspartnerattachment/entity-mode';
export const BUSINESSPARTNERATTACHMENT_REQUEST =
  '@businesspartnerattachment/entity-request';
export const BUSINESSPARTNERATTACHMENT_RECEIVE =
  '@businesspartnerattachment/entity-receive';
export const BUSINESSPARTNERATTACHMENT_ADD =
  '@businesspartnerattachment/entity-add';
export const BUSINESSPARTNERATTACHMENT_UPDATE =
  '@businesspartnerattachment/entity-update';
export const BUSINESSPARTNERATTACHMENT_DELETE =
  '@businesspartnerattachment/entity-delete';
export const BUSINESSPARTNERATTACHMENT_FINISHED =
  '@businesspartnerattachment/finished';

// BusinessPartnerCat
export const BUSINESSPARTNERCAT_LABELS_GET = '@businesspartnercat/labels-get';
export const BUSINESSPARTNERCAT_LABEL_SELECT =
  '@businesspartnercat/label-select';
export const BUSINESSPARTNERCAT_NAVIGATION_SET =
  '@businesspartnercat/navigation-set';
export const BUSINESSPARTNERCAT_LIST_REQUEST =
  '@businesspartnercat/list-request';
export const BUSINESSPARTNERCAT_LIST_REQUEST_MORE =
  '@businesspartnercat/list-request-more';
export const BUSINESSPARTNERCAT_LIST_RECEIVE =
  '@businesspartnercat/list-receive';
export const BUSINESSPARTNERCAT_LIST_APPEND = '@businesspartnercat/list-append';
export const BUSINESSPARTNERCAT_SELECT = '@businesspartnercat/entity-select';
export const BUSINESSPARTNERCAT_MODE = '@businesspartnercat/entity-mode';
export const BUSINESSPARTNERCAT_REQUEST = '@businesspartnercat/entity-request';
export const BUSINESSPARTNERCAT_RECEIVE = '@businesspartnercat/entity-receive';
export const BUSINESSPARTNERCAT_ADD = '@businesspartnercat/entity-add';
export const BUSINESSPARTNERCAT_UPDATE = '@businesspartnercat/entity-update';
export const BUSINESSPARTNERCAT_DELETE = '@businesspartnercat/entity-delete';
export const BUSINESSPARTNERCAT_FINISHED = '@businesspartnercat/finished';

// BusinessPartnerGroup
export const BUSINESSPARTNERGROUP_LABELS_GET =
  '@businesspartnergroup/labels-get';
export const BUSINESSPARTNERGROUP_LABEL_SELECT =
  '@businesspartnergroup/label-select';
export const BUSINESSPARTNERGROUP_NAVIGATION_SET =
  '@businesspartnergroup/navigation-set';
export const BUSINESSPARTNERGROUP_LIST_REQUEST =
  '@businesspartnergroup/list-request';
export const BUSINESSPARTNERGROUP_LIST_REQUEST_MORE =
  '@businesspartnergroup/list-request-more';
export const BUSINESSPARTNERGROUP_LIST_RECEIVE =
  '@businesspartnergroup/list-receive';
export const BUSINESSPARTNERGROUP_LIST_APPEND =
  '@businesspartnergroup/list-append';
export const BUSINESSPARTNERGROUP_SELECT =
  '@businesspartnergroup/entity-select';
export const BUSINESSPARTNERGROUP_MODE = '@businesspartnergroup/entity-mode';
export const BUSINESSPARTNERGROUP_REQUEST =
  '@businesspartnergroup/entity-request';
export const BUSINESSPARTNERGROUP_RECEIVE =
  '@businesspartnergroup/entity-receive';
export const BUSINESSPARTNERGROUP_ADD = '@businesspartnergroup/entity-add';
export const BUSINESSPARTNERGROUP_UPDATE =
  '@businesspartnergroup/entity-update';
export const BUSINESSPARTNERGROUP_DELETE =
  '@businesspartnergroup/entity-delete';
export const BUSINESSPARTNERGROUP_FINISHED = '@businesspartnergroup/finished';

// Calendar
export const CALENDAR_LABELS_GET = '@calendar/labels-get';
export const CALENDAR_LABEL_SELECT = '@calendar/label-select';
export const CALENDAR_NAVIGATION_SET = '@calendar/navigation-set';
export const CALENDAR_LIST_REQUEST = '@calendar/list-request';
export const CALENDAR_LIST_REQUEST_MORE = '@calendar/list-request-more';
export const CALENDAR_LIST_RECEIVE = '@calendar/list-receive';
export const CALENDAR_LIST_APPEND = '@calendar/list-append';
export const CALENDAR_SELECT = '@calendar/entity-select';
export const CALENDAR_MODE = '@calendar/entity-mode';
export const CALENDAR_REQUEST = '@calendar/entity-request';
export const CALENDAR_RECEIVE = '@calendar/entity-receive';
export const CALENDAR_ADD = '@calendar/entity-add';
export const CALENDAR_UPDATE = '@calendar/entity-update';
export const CALENDAR_DELETE = '@calendar/entity-delete';
export const CALENDAR_FINISHED = '@calendar/finished';

// Capacity
export const CAPACITY_LABELS_GET = '@capacity/labels-get';
export const CAPACITY_LABEL_SELECT = '@capacity/label-select';
export const CAPACITY_NAVIGATION_SET = '@capacity/navigation-set';
export const CAPACITY_LIST_REQUEST = '@capacity/list-request';
export const CAPACITY_LIST_REQUEST_MORE = '@capacity/list-request-more';
export const CAPACITY_LIST_RECEIVE = '@capacity/list-receive';
export const CAPACITY_LIST_APPEND = '@capacity/list-append';
export const CAPACITY_SELECT = '@capacity/entity-select';
export const CAPACITY_MODE = '@capacity/entity-mode';
export const CAPACITY_REQUEST = '@capacity/entity-request';
export const CAPACITY_RECEIVE = '@capacity/entity-receive';
export const CAPACITY_ADD = '@capacity/entity-add';
export const CAPACITY_UPDATE = '@capacity/entity-update';
export const CAPACITY_DELETE = '@capacity/entity-delete';
export const CAPACITY_FINISHED = '@capacity/finished';

// Catalog
export const CATALOG_LABELS_GET = '@catalog/labels-get';
export const CATALOG_LABEL_SELECT = '@catalog/label-select';
export const CATALOG_NAVIGATION_SET = '@catalog/navigation-set';
export const CATALOG_LIST_REQUEST = '@catalog/list-request';
export const CATALOG_LIST_REQUEST_MORE = '@catalog/list-request-more';
export const CATALOG_LIST_RECEIVE = '@catalog/list-receive';
export const CATALOG_LIST_APPEND = '@catalog/list-append';
export const CATALOG_SELECT = '@catalog/entity-select';
export const CATALOG_MODE = '@catalog/entity-mode';
export const CATALOG_REQUEST = '@catalog/entity-request';
export const CATALOG_RECEIVE = '@catalog/entity-receive';
export const CATALOG_ADD = '@catalog/entity-add';
export const CATALOG_UPDATE = '@catalog/entity-update';
export const CATALOG_DELETE = '@catalog/entity-delete';
export const CATALOG_FINISHED = '@catalog/finished';

// Channel
export const CHANNEL_LABELS_GET = '@channel/labels-get';
export const CHANNEL_LABEL_SELECT = '@channel/label-select';
export const CHANNEL_NAVIGATION_SET = '@channel/navigation-set';
export const CHANNEL_LIST_REQUEST = '@channel/list-request';
export const CHANNEL_LIST_REQUEST_MORE = '@channel/list-request-more';
export const CHANNEL_LIST_RECEIVE = '@channel/list-receive';
export const CHANNEL_LIST_APPEND = '@channel/list-append';
export const CHANNEL_SELECT = '@channel/entity-select';
export const CHANNEL_MODE = '@channel/entity-mode';
export const CHANNEL_REQUEST = '@channel/entity-request';
export const CHANNEL_RECEIVE = '@channel/entity-receive';
export const CHANNEL_ADD = '@channel/entity-add';
export const CHANNEL_UPDATE = '@channel/entity-update';
export const CHANNEL_DELETE = '@channel/entity-delete';
export const CHANNEL_FINISHED = '@channel/finished';

// ChannelSync
export const CHANNELSYNC_LABELS_GET = '@channelsync/labels-get';
export const CHANNELSYNC_LABEL_SELECT = '@channelsync/label-select';
export const CHANNELSYNC_NAVIGATION_SET = '@channelsync/navigation-set';
export const CHANNELSYNC_LIST_REQUEST = '@channelsync/list-request';
export const CHANNELSYNC_LIST_REQUEST_MORE = '@channelsync/list-request-more';
export const CHANNELSYNC_LIST_RECEIVE = '@channelsync/list-receive';
export const CHANNELSYNC_LIST_APPEND = '@channelsync/list-append';
export const CHANNELSYNC_SELECT = '@channelsync/entity-select';
export const CHANNELSYNC_MODE = '@channelsync/entity-mode';
export const CHANNELSYNC_REQUEST = '@channelsync/entity-request';
export const CHANNELSYNC_RECEIVE = '@channelsync/entity-receive';
export const CHANNELSYNC_ADD = '@channelsync/entity-add';
export const CHANNELSYNC_UPDATE = '@channelsync/entity-update';
export const CHANNELSYNC_DELETE = '@channelsync/entity-delete';
export const CHANNELSYNC_FINISHED = '@channelsync/finished';

// ChannelUser
export const CHANNELUSER_LABELS_GET = '@channeluser/labels-get';
export const CHANNELUSER_LABEL_SELECT = '@channeluser/label-select';
export const CHANNELUSER_NAVIGATION_SET = '@channeluser/navigation-set';
export const CHANNELUSER_LIST_REQUEST = '@channeluser/list-request';
export const CHANNELUSER_LIST_REQUEST_MORE = '@channeluser/list-request-more';
export const CHANNELUSER_LIST_RECEIVE = '@channeluser/list-receive';
export const CHANNELUSER_LIST_APPEND = '@channeluser/list-append';
export const CHANNELUSER_SELECT = '@channeluser/entity-select';
export const CHANNELUSER_MODE = '@channeluser/entity-mode';
export const CHANNELUSER_REQUEST = '@channeluser/entity-request';
export const CHANNELUSER_RECEIVE = '@channeluser/entity-receive';
export const CHANNELUSER_ADD = '@channeluser/entity-add';
export const CHANNELUSER_UPDATE = '@channeluser/entity-update';
export const CHANNELUSER_DELETE = '@channeluser/entity-delete';
export const CHANNELUSER_FINISHED = '@channeluser/finished';

// Chapter
export const CHAPTER_LABELS_GET = '@chapter/labels-get';
export const CHAPTER_LABEL_SELECT = '@chapter/label-select';
export const CHAPTER_NAVIGATION_SET = '@chapter/navigation-set';
export const CHAPTER_LIST_REQUEST = '@chapter/list-request';
export const CHAPTER_LIST_REQUEST_MORE = '@chapter/list-request-more';
export const CHAPTER_LIST_RECEIVE = '@chapter/list-receive';
export const CHAPTER_LIST_APPEND = '@chapter/list-append';
export const CHAPTER_SELECT = '@chapter/entity-select';
export const CHAPTER_MODE = '@chapter/entity-mode';
export const CHAPTER_REQUEST = '@chapter/entity-request';
export const CHAPTER_RECEIVE = '@chapter/entity-receive';
export const CHAPTER_ADD = '@chapter/entity-add';
export const CHAPTER_UPDATE = '@chapter/entity-update';
export const CHAPTER_DELETE = '@chapter/entity-delete';
export const CHAPTER_FINISHED = '@chapter/finished';

// Comment
export const COMMENT_LABELS_GET = '@comment/labels-get';
export const COMMENT_LABEL_SELECT = '@comment/label-select';
export const COMMENT_NAVIGATION_SET = '@comment/navigation-set';
export const COMMENT_LIST_REQUEST = '@comment/list-request';
export const COMMENT_LIST_REQUEST_MORE = '@comment/list-request-more';
export const COMMENT_LIST_RECEIVE = '@comment/list-receive';
export const COMMENT_LIST_APPEND = '@comment/list-append';
export const COMMENT_SELECT = '@comment/entity-select';
export const COMMENT_MODE = '@comment/entity-mode';
export const COMMENT_REQUEST = '@comment/entity-request';
export const COMMENT_RECEIVE = '@comment/entity-receive';
export const COMMENT_ADD = '@comment/entity-add';
export const COMMENT_UPDATE = '@comment/entity-update';
export const COMMENT_DELETE = '@comment/entity-delete';
export const COMMENT_FINISHED = '@comment/finished';

// CommentAttachment
export const COMMENTATTACHMENT_LABELS_GET = '@commentattachment/labels-get';
export const COMMENTATTACHMENT_LABEL_SELECT = '@commentattachment/label-select';
export const COMMENTATTACHMENT_NAVIGATION_SET =
  '@commentattachment/navigation-set';
export const COMMENTATTACHMENT_LIST_REQUEST = '@commentattachment/list-request';
export const COMMENTATTACHMENT_LIST_REQUEST_MORE =
  '@commentattachment/list-request-more';
export const COMMENTATTACHMENT_LIST_RECEIVE = '@commentattachment/list-receive';
export const COMMENTATTACHMENT_LIST_APPEND = '@commentattachment/list-append';
export const COMMENTATTACHMENT_SELECT = '@commentattachment/entity-select';
export const COMMENTATTACHMENT_MODE = '@commentattachment/entity-mode';
export const COMMENTATTACHMENT_REQUEST = '@commentattachment/entity-request';
export const COMMENTATTACHMENT_RECEIVE = '@commentattachment/entity-receive';
export const COMMENTATTACHMENT_ADD = '@commentattachment/entity-add';
export const COMMENTATTACHMENT_UPDATE = '@commentattachment/entity-update';
export const COMMENTATTACHMENT_DELETE = '@commentattachment/entity-delete';
export const COMMENTATTACHMENT_FINISHED = '@commentattachment/finished';

// Competence
export const COMPETENCE_LABELS_GET = '@competence/labels-get';
export const COMPETENCE_LABEL_SELECT = '@competence/label-select';
export const COMPETENCE_NAVIGATION_SET = '@competence/navigation-set';
export const COMPETENCE_LIST_REQUEST = '@competence/list-request';
export const COMPETENCE_LIST_REQUEST_MORE = '@competence/list-request-more';
export const COMPETENCE_LIST_RECEIVE = '@competence/list-receive';
export const COMPETENCE_LIST_APPEND = '@competence/list-append';
export const COMPETENCE_SELECT = '@competence/entity-select';
export const COMPETENCE_MODE = '@competence/entity-mode';
export const COMPETENCE_REQUEST = '@competence/entity-request';
export const COMPETENCE_RECEIVE = '@competence/entity-receive';
export const COMPETENCE_ADD = '@competence/entity-add';
export const COMPETENCE_UPDATE = '@comcompetenceent/entity-update';
export const COMPETENCE_DELETE = '@competence/entity-delete';
export const COMPETENCE_FINISHED = '@competence/finished';

// Consumption
export const CONSUMPTION_LABELS_GET = '@consumption/labels-get';
export const CONSUMPTION_LABEL_SELECT = '@consumption/label-select';
export const CONSUMPTION_NAVIGATION_SET = '@consumption/navigation-set';
export const CONSUMPTION_LIST_REQUEST = '@consumption/list-request';
export const CONSUMPTION_LIST_REQUEST_MORE = '@consumption/list-request-more';
export const CONSUMPTION_LIST_RECEIVE = '@consumption/list-receive';
export const CONSUMPTION_LIST_APPEND = '@consumption/list-append';
export const CONSUMPTION_SELECT = '@consumption/entity-select';
export const CONSUMPTION_MODE = '@consumption/entity-mode';
export const CONSUMPTION_REQUEST = '@consumption/entity-request';
export const CONSUMPTION_RECEIVE = '@consumption/entity-receive';
export const CONSUMPTION_ADD = '@consumption/entity-add';
export const CONSUMPTION_UPDATE = '@consumption/entity-update';
export const CONSUMPTION_DELETE = '@consumption/entity-delete';
export const CONSUMPTION_FINISHED = '@consumption/finished';

// Contact
export const CONTACT_LABELS_GET = '@contact/labels-get';
export const CONTACT_LABEL_SELECT = '@contact/label-select';
export const CONTACT_NAVIGATION_SET = '@contact/navigation-set';
export const CONTACT_LIST_REQUEST = '@contact/list-request';
export const CONTACT_LIST_REQUEST_MORE = '@contact/list-request-more';
export const CONTACT_LIST_RECEIVE = '@contact/list-receive';
export const CONTACT_LIST_APPEND = '@contact/list-append';
export const CONTACT_SELECT = '@contact/entity-select';
export const CONTACT_MODE = '@contact/entity-mode';
export const CONTACT_REQUEST = '@contact/entity-request';
export const CONTACT_RECEIVE = '@contact/entity-receive';
export const CONTACT_ADD = '@contact/entity-add';
export const CONTACT_UPDATE = '@contact/entity-update';
export const CONTACT_DELETE = '@contact/entity-delete';
export const CONTACT_FINISHED = '@contact/finished';

// ContactAttachment
export const CONTACTATTACHMENT_LABELS_GET = '@contactattachment/labels-get';
export const CONTACTATTACHMENT_LABEL_SELECT = '@contactattachment/label-select';
export const CONTACTATTACHMENT_NAVIGATION_SET =
  '@contactattachment/navigation-set';
export const CONTACTATTACHMENT_LIST_REQUEST = '@contactattachment/list-request';
export const CONTACTATTACHMENT_LIST_REQUEST_MORE =
  '@contactattachment/list-request-more';
export const CONTACTATTACHMENT_LIST_RECEIVE = '@contactattachment/list-receive';
export const CONTACTATTACHMENT_LIST_APPEND = '@contactattachment/list-append';
export const CONTACTATTACHMENT_SELECT = '@contactattachment/entity-select';
export const CONTACTATTACHMENT_MODE = '@contactattachment/entity-mode';
export const CONTACTATTACHMENT_REQUEST = '@contactattachment/entity-request';
export const CONTACTATTACHMENT_RECEIVE = '@contactattachment/entity-receive';
export const CONTACTATTACHMENT_ADD = '@contactattachment/entity-add';
export const CONTACTATTACHMENT_UPDATE = '@contactattachment/entity-update';
export const CONTACTATTACHMENT_DELETE = '@contactattachment/entity-delete';
export const CONTACTATTACHMENT_FINISHED = '@contactattachment/finished';

// Data
export const DATA_LABELS_GET = '@data/labels-get';
export const DATA_LABEL_SELECT = '@data/label-select';
export const DATA_NAVIGATION_SET = '@data/navigation-set';
export const DATA_LIST_REQUEST = '@data/list-request';
export const DATA_LIST_REQUEST_MORE = '@data/list-request-more';
export const DATA_LIST_RECEIVE = '@data/list-receive';
export const DATA_LIST_APPEND = '@data/list-append';
export const DATA_SELECT = '@data/entity-select';
export const DATA_MODE = '@data/entity-mode';
export const DATA_REQUEST = '@data/entity-request';
export const DATA_RECEIVE = '@data/entity-receive';
export const DATA_ADD = '@data/entity-add';
export const DATA_UPDATE = '@data/entity-update';
export const DATA_DELETE = '@data/entity-delete';
export const DATA_FINISHED = '@data/finished';

// Delegate
export const DELEGATE_LABELS_GET = '@delegate/labels-get';
export const DELEGATE_LABEL_SELECT = '@delegate/label-select';
export const DELEGATE_NAVIGATION_SET = '@delegate/navigation-set';
export const DELEGATE_LIST_REQUEST = '@delegate/list-request';
export const DELEGATE_LIST_REQUEST_MORE = '@delegate/list-request-more';
export const DELEGATE_LIST_RECEIVE = '@delegate/list-receive';
export const DELEGATE_LIST_APPEND = '@delegate/list-append';
export const DELEGATE_SELECT = '@delegate/entity-select';
export const DELEGATE_MODE = '@delegate/entity-mode';
export const DELEGATE_REQUEST = '@delegate/entity-request';
export const DELEGATE_RECEIVE = '@delegate/entity-receive';
export const DELEGATE_ADD = '@delegate/entity-add';
export const DELEGATE_UPDATE = '@delegate/entity-update';
export const DELEGATE_DELETE = '@delegate/entity-delete';
export const DELEGATE_FINISHED = '@delegate/finished';

// DelegateSite
export const DELEGATESITE_LABELS_GET = '@delegatesite/labels-get';
export const DELEGATESITE_LABEL_SELECT = '@delegatesite/label-select';
export const DELEGATESITE_NAVIGATION_SET = '@delegatesite/navigation-set';
export const DELEGATESITE_LIST_REQUEST = '@delegatesite/list-request';
export const DELEGATESITE_LIST_REQUEST_MORE = '@delegatesite/list-request-more';
export const DELEGATESITE_LIST_RECEIVE = '@delegatesite/list-receive';
export const DELEGATESITE_LIST_APPEND = '@delegatesite/list-append';
export const DELEGATESITE_SELECT = '@delegatesite/entity-select';
export const DELEGATESITE_MODE = '@delegatesite/entity-mode';
export const DELEGATESITE_REQUEST = '@delegatesite/entity-request';
export const DELEGATESITE_RECEIVE = '@delegatesite/entity-receive';
export const DELEGATESITE_ADD = '@delegatesite/entity-add';
export const DELEGATESITE_UPDATE = '@delegatesite/entity-update';
export const DELEGATESITE_DELETE = '@delegatesite/entity-delete';
export const DELEGATESITE_FINISHED = '@delegatesite/finished';

// Department
export const DEPARTMENT_LABELS_GET = '@department/labels-get';
export const DEPARTMENT_LABEL_SELECT = '@department/label-select';
export const DEPARTMENT_NAVIGATION_SET = '@department/navigation-set';
export const DEPARTMENT_LIST_REQUEST = '@department/list-request';
export const DEPARTMENT_LIST_REQUEST_MORE = '@department/list-request-more';
export const DEPARTMENT_LIST_RECEIVE = '@department/list-receive';
export const DEPARTMENT_LIST_APPEND = '@department/list-append';
export const DEPARTMENT_SELECT = '@department/entity-select';
export const DEPARTMENT_MODE = '@department/entity-mode';
export const DEPARTMENT_REQUEST = '@department/entity-request';
export const DEPARTMENT_RECEIVE = '@department/entity-receive';
export const DEPARTMENT_ADD = '@department/entity-add';
export const DEPARTMENT_UPDATE = '@department/entity-update';
export const DEPARTMENT_DELETE = '@department/entity-delete';
export const DEPARTMENT_FINISHED = '@department/finished';

// Doc
export const DOC_LABELS_GET = '@doc/labels-get';
export const DOC_LABEL_SELECT = '@doc/label-select';
export const DOC_NAVIGATION_SET = '@doc/navigation-set';
export const DOC_LIST_REQUEST = '@doc/list-request';
export const DOC_LIST_REQUEST_MORE = '@doc/list-request-more';
export const DOC_LIST_RECEIVE = '@doc/list-receive';
export const DOC_LIST_APPEND = '@doc/list-append';
export const DOC_SELECT = '@doc/entity-select';
export const DOC_MODE = '@doc/entity-mode';
export const DOC_REQUEST = '@doc/entity-request';
export const DOC_RECEIVE = '@doc/entity-receive';
export const DOC_ADD = '@doc/entity-add';
export const DOC_UPDATE = '@doc/entity-update';
export const DOC_DELETE = '@doc/entity-delete';
export const DOC_FINISHED = '@doc/finished';

// Education
export const EDUCATION_LABELS_GET = '@education/labels-get';
export const EDUCATION_LABEL_SELECT = '@education/label-select';
export const EDUCATION_NAVIGATION_SET = '@education/navigation-set';
export const EDUCATION_LIST_REQUEST = '@education/list-request';
export const EDUCATION_LIST_REQUEST_MORE = '@education/list-request-more';
export const EDUCATION_LIST_RECEIVE = '@education/list-receive';
export const EDUCATION_LIST_APPEND = '@education/list-append';
export const EDUCATION_SELECT = '@education/entity-select';
export const EDUCATION_MODE = '@education/entity-mode';
export const EDUCATION_REQUEST = '@education/entity-request';
export const EDUCATION_RECEIVE = '@education/entity-receive';
export const EDUCATION_ADD = '@education/entity-add';
export const EDUCATION_UPDATE = '@education/entity-update';
export const EDUCATION_DELETE = '@education/entity-delete';
export const EDUCATION_FINISHED = '@education/finished';

// Email
export const EMAIL_LABELS_GET = '@email/labels-get';
export const EMAIL_LABEL_SELECT = '@email/label-select';
export const EMAIL_NAVIGATION_SET = '@email/navigation-set';
export const EMAIL_LIST_REQUEST = '@email/list-request';
export const EMAIL_LIST_REQUEST_MORE = '@email/list-request-more';
export const EMAIL_LIST_RECEIVE = '@email/list-receive';
export const EMAIL_LIST_APPEND = '@email/list-append';
export const EMAIL_SELECT = '@email/entity-select';
export const EMAIL_MODE = '@email/entity-mode';
export const EMAIL_REQUEST = '@email/entity-request';
export const EMAIL_RECEIVE = '@email/entity-receive';
export const EMAIL_ADD = '@email/entity-add';
export const EMAIL_UPDATE = '@email/entity-update';
export const EMAIL_DELETE = '@email/entity-delete';
export const EMAIL_FINISHED = '@email/finished';

// EmailAttachment
export const EMAILATTACHMENT_LABELS_GET = '@emailattachment/labels-get';
export const EMAILATTACHMENT_LABEL_SELECT = '@emailattachment/label-select';
export const EMAILATTACHMENT_NAVIGATION_SET = '@emailattachment/navigation-set';
export const EMAILATTACHMENT_LIST_REQUEST = '@emailattachment/list-request';
export const EMAILATTACHMENT_LIST_REQUEST_MORE =
  '@emailattachment/list-request-more';
export const EMAILATTACHMENT_LIST_RECEIVE = '@emailattachment/list-receive';
export const EMAILATTACHMENT_LIST_APPEND = '@emailattachment/list-append';
export const EMAILATTACHMENT_SELECT = '@emailattachment/entity-select';
export const EMAILATTACHMENT_MODE = '@emailattachment/entity-mode';
export const EMAILATTACHMENT_REQUEST = '@emailattachment/entity-request';
export const EMAILATTACHMENT_RECEIVE = '@emailattachment/entity-receive';
export const EMAILATTACHMENT_ADD = '@emailattachment/entity-add';
export const EMAILATTACHMENT_UPDATE = '@emailattachment/entity-update';
export const EMAILATTACHMENT_DELETE = '@emailattachment/entity-delete';
export const EMAILATTACHMENT_FINISHED = '@emailattachment/finished';

// EmailState
export const EMAILSTATE_LABELS_GET = '@emailstate/labels-get';
export const EMAILSTATE_LABEL_SELECT = '@emailstate/label-select';
export const EMAILSTATE_NAVIGATION_SET = '@emailstate/navigation-set';
export const EMAILSTATE_LIST_REQUEST = '@emailstate/list-request';
export const EMAILSTATE_LIST_REQUEST_MORE = '@emailstate/list-request-more';
export const EMAILSTATE_LIST_RECEIVE = '@emailstate/list-receive';
export const EMAILSTATE_LIST_APPEND = '@emailstate/list-append';
export const EMAILSTATE_SELECT = '@emailstate/entity-select';
export const EMAILSTATE_MODE = '@emailstate/entity-mode';
export const EMAILSTATE_REQUEST = '@emailstate/entity-request';
export const EMAILSTATE_RECEIVE = '@emailstate/entity-receive';
export const EMAILSTATE_ADD = '@emailstate/entity-add';
export const EMAILSTATE_UPDATE = '@emailstate/entity-update';
export const EMAILSTATE_DELETE = '@emailstate/entity-delete';
export const EMAILSTATE_FINISHED = '@emailstate/finished';

// Employee
export const EMPLOYEE_LABELS_GET = '@employee/labels-get';
export const EMPLOYEE_LABEL_SELECT = '@employee/label-select';
export const EMPLOYEE_NAVIGATION_SET = '@employee/navigation-set';
export const EMPLOYEE_LIST_REQUEST = '@employee/list-request';
export const EMPLOYEE_LIST_REQUEST_MORE = '@employee/list-request-more';
export const EMPLOYEE_LIST_RECEIVE = '@employee/list-receive';
export const EMPLOYEE_LIST_APPEND = '@employee/list-append';
export const EMPLOYEE_SELECT = '@employee/entity-select';
export const EMPLOYEE_MODE = '@employee/entity-mode';
export const EMPLOYEE_REQUEST = '@employee/entity-request';
export const EMPLOYEE_RECEIVE = '@employee/entity-receive';
export const EMPLOYEE_ADD = '@employee/entity-add';
export const EMPLOYEE_UPDATE = '@employee/entity-update';
export const EMPLOYEE_DELETE = '@employee/entity-delete';
export const EMPLOYEE_FINISHED = '@employee/finished';

// EmployeeAttachment
export const EMPLOYEEATTACHMENT_LABELS_GET = '@employeeattachment/labels-get';
export const EMPLOYEEATTACHMENT_LABEL_SELECT =
  '@employeeattachment/label-select';
export const EMPLOYEEATTACHMENT_NAVIGATION_SET =
  '@employeeattachment/navigation-set';
export const EMPLOYEEATTACHMENT_LIST_REQUEST =
  '@employeeattachment/list-request';
export const EMPLOYEEATTACHMENT_LIST_REQUEST_MORE =
  '@employeeattachment/list-request-more';
export const EMPLOYEEATTACHMENT_LIST_RECEIVE =
  '@employeeattachment/list-receive';
export const EMPLOYEEATTACHMENT_LIST_APPEND = '@employeeattachment/list-append';
export const EMPLOYEEATTACHMENT_SELECT = '@employeeattachment/entity-select';
export const EMPLOYEEATTACHMENT_MODE = '@employeeattachment/entity-mode';
export const EMPLOYEEATTACHMENT_REQUEST = '@employeeattachment/entity-request';
export const EMPLOYEEATTACHMENT_RECEIVE = '@employeeattachment/entity-receive';
export const EMPLOYEEATTACHMENT_ADD = '@employeeattachment/entity-add';
export const EMPLOYEEATTACHMENT_UPDATE = '@employeeattachment/entity-update';
export const EMPLOYEEATTACHMENT_DELETE = '@employeeattachment/entity-delete';
export const EMPLOYEEATTACHMENT_FINISHED = '@employeeattachment/finished';

// EventCat
export const EVENTCAT_LABELS_GET = '@eventcat/labels-get';
export const EVENTCAT_LABEL_SELECT = '@eventcat/label-select';
export const EVENTCAT_NAVIGATION_SET = '@eventcat/navigation-set';
export const EVENTCAT_LIST_REQUEST = '@eventcat/list-request';
export const EVENTCAT_LIST_REQUEST_MORE = '@eventcat/list-request-more';
export const EVENTCAT_LIST_RECEIVE = '@eventcat/list-receive';
export const EVENTCAT_LIST_APPEND = '@eventcat/list-append';
export const EVENTCAT_SELECT = '@eventcat/entity-select';
export const EVENTCAT_MODE = '@eventcat/entity-mode';
export const EVENTCAT_REQUEST = '@eventcat/entity-request';
export const EVENTCAT_RECEIVE = '@eventcat/entity-receive';
export const EVENTCAT_ADD = '@eventcat/entity-add';
export const EVENTCAT_UPDATE = '@eventcat/entity-update';
export const EVENTCAT_DELETE = '@eventcat/entity-delete';
export const EVENTCAT_FINISHED = '@eventcat/finished';

// Follow
export const FOLLOW_LABELS_GET = '@follow/labels-get';
export const FOLLOW_LABEL_SELECT = '@follow/label-select';
export const FOLLOW_NAVIGATION_SET = '@follow/navigation-set';
export const FOLLOW_LIST_REQUEST = '@follow/list-request';
export const FOLLOW_LIST_REQUEST_MORE = '@follow/list-request-more';
export const FOLLOW_LIST_RECEIVE = '@follow/list-receive';
export const FOLLOW_LIST_APPEND = '@follow/list-append';
export const FOLLOW_SELECT = '@follow/entity-select';
export const FOLLOW_MODE = '@follow/entity-mode';
export const FOLLOW_REQUEST = '@follow/entity-request';
export const FOLLOW_RECEIVE = '@follow/entity-receive';
export const FOLLOW_ADD = '@follow/entity-add';
export const FOLLOW_UPDATE = '@follow/entity-update';
export const FOLLOW_DELETE = '@follow/entity-delete';
export const FOLLOW_FINISHED = '@follow/finished';

// Forum
export const FORUM_LABELS_GET = '@forum/labels-get';
export const FORUM_LABEL_SELECT = '@forum/label-select';
export const FORUM_NAVIGATION_SET = '@forum/navigation-set';
export const FORUM_LIST_REQUEST = '@forum/list-request';
export const FORUM_LIST_REQUEST_MORE = '@forum/list-request-more';
export const FORUM_LIST_RECEIVE = '@forum/list-receive';
export const FORUM_LIST_APPEND = '@forum/list-append';
export const FORUM_SELECT = '@forum/entity-select';
export const FORUM_MODE = '@forum/entity-mode';
export const FORUM_REQUEST = '@forum/entity-request';
export const FORUM_RECEIVE = '@forum/entity-receive';
export const FORUM_ADD = '@forum/entity-add';
export const FORUM_UPDATE = '@forum/entity-update';
export const FORUM_DELETE = '@forum/entity-delete';
export const FORUM_FINISHED = '@forum/finished';

// Grade
export const GRADE_LABELS_GET = '@grade/labels-get';
export const GRADE_LABEL_SELECT = '@grade/label-select';
export const GRADE_NAVIGATION_SET = '@grade/navigation-set';
export const GRADE_LIST_REQUEST = '@grade/list-request';
export const GRADE_LIST_REQUEST_MORE = '@grade/list-request-more';
export const GRADE_LIST_RECEIVE = '@grade/list-receive';
export const GRADE_LIST_APPEND = '@grade/list-append';
export const GRADE_SELECT = '@grade/entity-select';
export const GRADE_MODE = '@grade/entity-mode';
export const GRADE_REQUEST = '@grade/entity-request';
export const GRADE_RECEIVE = '@grade/entity-receive';
export const GRADE_ADD = '@grade/entity-add';
export const GRADE_UPDATE = '@grade/entity-update';
export const GRADE_DELETE = '@grade/entity-delete';
export const GRADE_FINISHED = '@grade/finished';

// Guide
export const GUIDE_LABELS_GET = '@guide/labels-get';
export const GUIDE_LABEL_SELECT = '@guide/label-select';
export const GUIDE_NAVIGATION_SET = '@guide/navigation-set';
export const GUIDE_LIST_REQUEST = '@guide/list-request';
export const GUIDE_LIST_REQUEST_MORE = '@guide/list-request-more';
export const GUIDE_LIST_RECEIVE = '@guide/list-receive';
export const GUIDE_LIST_APPEND = '@guide/list-append';
export const GUIDE_SELECT = '@guide/entity-select';
export const GUIDE_MODE = '@guide/entity-mode';
export const GUIDE_REQUEST = '@guide/entity-request';
export const GUIDE_RECEIVE = '@guide/entity-receive';
export const GUIDE_ADD = '@guide/entity-add';
export const GUIDE_UPDATE = '@guide/entity-update';
export const GUIDE_DELETE = '@guide/entity-delete';
export const GUIDE_FINISHED = '@guide/finished';

// Guideline
export const GUIDELINE_LABELS_GET = '@guideline/labels-get';
export const GUIDELINE_LABEL_SELECT = '@guideline/label-select';
export const GUIDELINE_NAVIGATION_SET = '@guideline/navigation-set';
export const GUIDELINE_LIST_REQUEST = '@guideline/list-request';
export const GUIDELINE_LIST_REQUEST_MORE = '@guideline/list-request-more';
export const GUIDELINE_LIST_RECEIVE = '@guideline/list-receive';
export const GUIDELINE_LIST_APPEND = '@guideline/list-append';
export const GUIDELINE_SELECT = '@guideline/entity-select';
export const GUIDELINE_MODE = '@guideline/entity-mode';
export const GUIDELINE_REQUEST = '@guideline/entity-request';
export const GUIDELINE_RECEIVE = '@guideline/entity-receive';
export const GUIDELINE_ADD = '@guideline/entity-add';
export const GUIDELINE_UPDATE = '@guideline/entity-update';
export const GUIDELINE_DELETE = '@guideline/entity-delete';
export const GUIDELINE_FINISHED = '@guideline/finished';

// Installation
export const INSTALLATION_LABELS_GET = '@installation/labels-get';
export const INSTALLATION_LABEL_SELECT = '@installation/label-select';
export const INSTALLATION_NAVIGATION_SET = '@installation/navigation-set';
export const INSTALLATION_LIST_REQUEST = '@installation/list-request';
export const INSTALLATION_LIST_REQUEST_MORE = '@installation/list-request-more';
export const INSTALLATION_LIST_RECEIVE = '@installation/list-receive';
export const INSTALLATION_LIST_APPEND = '@installation/list-append';
export const INSTALLATION_SELECT = '@installation/entity-select';
export const INSTALLATION_MODE = '@installation/entity-mode';
export const INSTALLATION_REQUEST = '@installation/entity-request';
export const INSTALLATION_RECEIVE = '@installation/entity-receive';
export const INSTALLATION_ADD = '@installation/entity-add';
export const INSTALLATION_UPDATE = '@installation/entity-update';
export const INSTALLATION_DELETE = '@installation/entity-delete';
export const INSTALLATION_FINISHED = '@installation/finished';

// Institute
export const INSTITUTE_LABELS_GET = '@institute/labels-get';
export const INSTITUTE_LABEL_SELECT = '@institute/label-select';
export const INSTITUTE_NAVIGATION_SET = '@institute/navigation-set';
export const INSTITUTE_LIST_REQUEST = '@institute/list-request';
export const INSTITUTE_LIST_REQUEST_MORE = '@institute/list-request-more';
export const INSTITUTE_LIST_RECEIVE = '@institute/list-receive';
export const INSTITUTE_LIST_APPEND = '@institute/list-append';
export const INSTITUTE_SELECT = '@institute/entity-select';
export const INSTITUTE_MODE = '@institute/entity-mode';
export const INSTITUTE_REQUEST = '@institute/entity-request';
export const INSTITUTE_RECEIVE = '@institute/entity-receive';
export const INSTITUTE_ADD = '@institute/entity-add';
export const INSTITUTE_UPDATE = '@institute/entity-update';
export const INSTITUTE_DELETE = '@institute/entity-delete';
export const INSTITUTE_FINISHED = '@institute/finished';

// Iteration
export const ITERATION_LABELS_GET = '@iteration/labels-get';
export const ITERATION_LABEL_SELECT = '@iteration/label-select';
export const ITERATION_NAVIGATION_SET = '@iteration/navigation-set';
export const ITERATION_LIST_REQUEST = '@iteration/list-request';
export const ITERATION_LIST_REQUEST_MORE = '@iteration/list-request-more';
export const ITERATION_LIST_RECEIVE = '@iteration/list-receive';
export const ITERATION_LIST_APPEND = '@iteration/list-append';
export const ITERATION_SELECT = '@iteration/entity-select';
export const ITERATION_MODE = '@iteration/entity-mode';
export const ITERATION_REQUEST = '@iteration/entity-request';
export const ITERATION_RECEIVE = '@iteration/entity-receive';
export const ITERATION_ADD = '@iteration/entity-add';
export const ITERATION_UPDATE = '@iteration/entity-update';
export const ITERATION_DELETE = '@iteration/entity-delete';
export const ITERATION_FINISHED = '@iteration/finished';

// JobAttachment
export const JOBATTACHMENT_LABELS_GET = '@jobattachment/labels-get';
export const JOBATTACHMENT_LABEL_SELECT = '@jobattachment/label-select';
export const JOBATTACHMENT_NAVIGATION_SET = '@jobattachment/navigation-set';
export const JOBATTACHMENT_LIST_REQUEST = '@jobattachment/list-request';
export const JOBATTACHMENT_LIST_REQUEST_MORE =
  '@jobattachment/list-request-more';
export const JOBATTACHMENT_LIST_RECEIVE = '@jobattachment/list-receive';
export const JOBATTACHMENT_LIST_APPEND = '@jobattachment/list-append';
export const JOBATTACHMENT_SELECT = '@jobattachment/entity-select';
export const JOBATTACHMENT_MODE = '@jobattachment/entity-mode';
export const JOBATTACHMENT_REQUEST = '@jobattachment/entity-request';
export const JOBATTACHMENT_RECEIVE = '@jobattachment/entity-receive';
export const JOBATTACHMENT_ADD = '@jobattachment/entity-add';
export const JOBATTACHMENT_UPDATE = '@jobattachment/entity-update';
export const JOBATTACHMENT_DELETE = '@jobattachment/entity-delete';
export const JOBATTACHMENT_FINISHED = '@jobattachment/finished';

// JobCat
export const JOBCAT_LABELS_GET = '@jobcat/labels-get';
export const JOBCAT_LABEL_SELECT = '@jobcat/label-select';
export const JOBCAT_NAVIGATION_SET = '@jobcat/navigation-set';
export const JOBCAT_LIST_REQUEST = '@jobcat/list-request';
export const JOBCAT_LIST_REQUEST_MORE = '@jobcat/list-request-more';
export const JOBCAT_LIST_RECEIVE = '@jobcat/list-receive';
export const JOBCAT_LIST_APPEND = '@jobcat/list-append';
export const JOBCAT_SELECT = '@jobcat/entity-select';
export const JOBCAT_MODE = '@jobcat/entity-mode';
export const JOBCAT_REQUEST = '@jobcat/entity-request';
export const JOBCAT_RECEIVE = '@jobcat/entity-receive';
export const JOBCAT_ADD = '@jobcat/entity-add';
export const JOBCAT_UPDATE = '@jobcat/entity-update';
export const JOBCAT_DELETE = '@jobcat/entity-delete';
export const JOBCAT_FINISHED = '@jobcat/finished';

// JobGroup
export const JOBGROUP_LABELS_GET = '@jobgroup/labels-get';
export const JOBGROUP_LABEL_SELECT = '@jobgroup/label-select';
export const JOBGROUP_NAVIGATION_SET = '@jobgroup/navigation-set';
export const JOBGROUP_LIST_REQUEST = '@jobgroup/list-request';
export const JOBGROUP_LIST_REQUEST_MORE = '@jobgroup/list-request-more';
export const JOBGROUP_LIST_RECEIVE = '@jobgroup/list-receive';
export const JOBGROUP_LIST_APPEND = '@jobgroup/list-append';
export const JOBGROUP_SELECT = '@jobgroup/entity-select';
export const JOBGROUP_MODE = '@jobgroup/entity-mode';
export const JOBGROUP_REQUEST = '@jobgroup/entity-request';
export const JOBGROUP_RECEIVE = '@jobgroup/entity-receive';
export const JOBGROUP_ADD = '@jobgroup/entity-add';
export const JOBGROUP_UPDATE = '@jobgroup/entity-update';
export const JOBGROUP_DELETE = '@jobgroup/entity-delete';
export const JOBGROUP_FINISHED = '@jobgroup/finished';

// JobRelation
export const JOBRELATION_LABELS_GET = '@jobrelation/labels-get';
export const JOBRELATION_LABEL_SELECT = '@jobrelation/label-select';
export const JOBRELATION_NAVIGATION_SET = '@jobrelation/navigation-set';
export const JOBRELATION_LIST_REQUEST = '@jobrelation/list-request';
export const JOBRELATION_LIST_REQUEST_MORE = '@jobrelation/list-request-more';
export const JOBRELATION_LIST_RECEIVE = '@jobrelation/list-receive';
export const JOBRELATION_LIST_APPEND = '@jobrelation/list-append';
export const JOBRELATION_SELECT = '@jobrelation/entity-select';
export const JOBRELATION_MODE = '@jobrelation/entity-mode';
export const JOBRELATION_REQUEST = '@jobrelation/entity-request';
export const JOBRELATION_RECEIVE = '@jobrelation/entity-receive';
export const JOBRELATION_ADD = '@jobrelation/entity-add';
export const JOBRELATION_UPDATE = '@jobrelation/entity-update';
export const JOBRELATION_DELETE = '@jobrelation/entity-delete';
export const JOBRELATION_FINISHED = '@jobrelation/finished';

// JobRelationType
export const JOBRELATIONTYPE_LABELS_GET = '@jobrelationtype/labels-get';
export const JOBRELATIONTYPE_LABEL_SELECT = '@jobrelationtype/label-select';
export const JOBRELATIONTYPE_NAVIGATION_SET = '@jobrelationtype/navigation-set';
export const JOBRELATIONTYPE_LIST_REQUEST = '@jobrelationtype/list-request';
export const JOBRELATIONTYPE_LIST_REQUEST_MORE =
  '@jobrelationtype/list-request-more';
export const JOBRELATIONTYPE_LIST_RECEIVE = '@jobrelationtype/list-receive';
export const JOBRELATIONTYPE_LIST_APPEND = '@jobrelationtype/list-append';
export const JOBRELATIONTYPE_SELECT = '@jobrelationtype/entity-select';
export const JOBRELATIONTYPE_MODE = '@jobrelationtype/entity-mode';
export const JOBRELATIONTYPE_REQUEST = '@jobrelationtype/entity-request';
export const JOBRELATIONTYPE_RECEIVE = '@jobrelationtype/entity-receive';
export const JOBRELATIONTYPE_ADD = '@jobrelationtype/entity-add';
export const JOBRELATIONTYPE_UPDATE = '@jobrelationtype/entity-update';
export const JOBRELATIONTYPE_DELETE = '@jobrelationtype/entity-delete';
export const JOBRELATIONTYPE_FINISHED = '@jobrelationtype/finished';

// JobTag
export const JOBTAG_LABELS_GET = '@jobtag/labels-get';
export const JOBTAG_LABEL_SELECT = '@jobtag/label-select';
export const JOBTAG_NAVIGATION_SET = '@jobtag/navigation-set';
export const JOBTAG_LIST_REQUEST = '@jobtag/list-request';
export const JOBTAG_LIST_REQUEST_MORE = '@jobtag/list-request-more';
export const JOBTAG_LIST_RECEIVE = '@jobtag/list-receive';
export const JOBTAG_LIST_APPEND = '@jobtag/list-append';
export const JOBTAG_SELECT = '@jobtag/entity-select';
export const JOBTAG_MODE = '@jobtag/entity-mode';
export const JOBTAG_REQUEST = '@jobtag/entity-request';
export const JOBTAG_RECEIVE = '@jobtag/entity-receive';
export const JOBTAG_ADD = '@jobtag/entity-add';
export const JOBTAG_UPDATE = '@jobtag/entity-update';
export const JOBTAG_DELETE = '@jobtag/entity-delete';
export const JOBTAG_FINISHED = '@jobtag/finished';

// MailingList
export const MAILINGLIST_LABELS_GET = '@mailinglist/labels-get';
export const MAILINGLIST_LABEL_SELECT = '@mailinglist/label-select';
export const MAILINGLIST_NAVIGATION_SET = '@mailinglist/navigation-set';
export const MAILINGLIST_LIST_REQUEST = '@mailinglist/list-request';
export const MAILINGLIST_LIST_REQUEST_MORE = '@mailinglist/list-request-more';
export const MAILINGLIST_LIST_RECEIVE = '@mailinglist/list-receive';
export const MAILINGLIST_LIST_APPEND = '@mailinglist/list-append';
export const MAILINGLIST_SELECT = '@mailinglist/entity-select';
export const MAILINGLIST_MODE = '@mailinglist/entity-mode';
export const MAILINGLIST_REQUEST = '@mailinglist/entity-request';
export const MAILINGLIST_RECEIVE = '@mailinglist/entity-receive';
export const MAILINGLIST_ADD = '@mailinglist/entity-add';
export const MAILINGLIST_UPDATE = '@mailinglist/entity-update';
export const MAILINGLIST_DELETE = '@mailinglist/entity-delete';
export const MAILINGLIST_FINISHED = '@mailinglist/finished';

// MailingListContact
export const MAILINGLISTCONTACT_LABELS_GET = '@mailinglistcontact/labels-get';
export const MAILINGLISTCONTACT_LABEL_SELECT =
  '@mailinglistcontact/label-select';
export const MAILINGLISTCONTACT_NAVIGATION_SET =
  '@mailinglistcontact/navigation-set';
export const MAILINGLISTCONTACT_LIST_REQUEST =
  '@mailinglistcontact/list-request';
export const MAILINGLISTCONTACT_LIST_REQUEST_MORE =
  '@mailinglistcontact/list-request-more';
export const MAILINGLISTCONTACT_LIST_RECEIVE =
  '@mailinglistcontact/list-receive';
export const MAILINGLISTCONTACT_LIST_APPEND = '@mailinglistcontact/list-append';
export const MAILINGLISTCONTACT_SELECT = '@mailinglistcontact/entity-select';
export const MAILINGLISTCONTACT_MODE = '@mailinglistcontact/entity-mode';
export const MAILINGLISTCONTACT_REQUEST = '@mailinglistcontact/entity-request';
export const MAILINGLISTCONTACT_RECEIVE = '@mailinglistcontact/entity-receive';
export const MAILINGLISTCONTACT_ADD = '@mailinglistcontact/entity-add';
export const MAILINGLISTCONTACT_UPDATE = '@mailinglistcontact/entity-update';
export const MAILINGLISTCONTACT_DELETE = '@mailinglistcontact/entity-delete';
export const MAILINGLISTCONTACT_FINISHED = '@mailinglistcontact/finished';

// Menu
export const MENU_LABELS_GET = '@menu/labels-get';
export const MENU_LABEL_SELECT = '@menu/label-select';
export const MENU_NAVIGATION_SET = '@menu/navigation-set';
export const MENU_LIST_REQUEST = '@menu/list-request';
export const MENU_LIST_REQUEST_MORE = '@menu/list-request-more';
export const MENU_LIST_RECEIVE = '@menu/list-receive';
export const MENU_LIST_APPEND = '@menu/list-append';
export const MENU_SELECT = '@menu/entity-select';
export const MENU_MODE = '@menu/entity-mode';
export const MENU_REQUEST = '@menu/entity-request';
export const MENU_RECEIVE = '@menu/entity-receive';
export const MENU_ADD = '@menu/entity-add';
export const MENU_UPDATE = '@menu/entity-update';
export const MENU_DELETE = '@menu/entity-delete';
export const MENU_FINISHED = '@menu/finished';

// MenuItem
export const MENUITEM_LABELS_GET = '@menuitem/labels-get';
export const MENUITEM_LABEL_SELECT = '@menuitem/label-select';
export const MENUITEM_NAVIGATION_SET = '@menuitem/navigation-set';
export const MENUITEM_LIST_REQUEST = '@menuitem/list-request';
export const MENUITEM_LIST_REQUEST_MORE = '@menuitem/list-request-more';
export const MENUITEM_LIST_RECEIVE = '@menuitem/list-receive';
export const MENUITEM_LIST_APPEND = '@menuitem/list-append';
export const MENUITEM_SELECT = '@menuitem/entity-select';
export const MENUITEM_MODE = '@menuitem/entity-mode';
export const MENUITEM_REQUEST = '@menuitem/entity-request';
export const MENUITEM_RECEIVE = '@menuitem/entity-receive';
export const MENUITEM_ADD = '@menuitem/entity-add';
export const MENUITEM_UPDATE = '@menuitem/entity-update';
export const MENUITEM_DELETE = '@menuitem/entity-delete';
export const MENUITEM_FINISHED = '@menuitem/finished';

// Message
export const MESSAGE_LABELS_GET = '@message/labels-get';
export const MESSAGE_LABEL_SELECT = '@message/label-select';
export const MESSAGE_NAVIGATION_SET = '@message/navigation-set';
export const MESSAGE_LIST_REQUEST = '@message/list-request';
export const MESSAGE_LIST_REQUEST_MORE = '@message/list-request-more';
export const MESSAGE_LIST_RECEIVE = '@message/list-receive';
export const MESSAGE_LIST_APPEND = '@message/list-append';
export const MESSAGE_SELECT = '@message/entity-select';
export const MESSAGE_MODE = '@message/entity-mode';
export const MESSAGE_REQUEST = '@message/entity-request';
export const MESSAGE_RECEIVE = '@message/entity-receive';
export const MESSAGE_ADD = '@message/entity-add';
export const MESSAGE_UPDATE = '@message/entity-update';
export const MESSAGE_DELETE = '@message/entity-delete';
export const MESSAGE_FINISHED = '@message/finished';

// MessageAttachment
export const MESSAGEATTACHMENT_LABELS_GET = '@messageattachment/labels-get';
export const MESSAGEATTACHMENT_LABEL_SELECT = '@messageattachment/label-select';
export const MESSAGEATTACHMENT_NAVIGATION_SET =
  '@messageattachment/navigation-set';
export const MESSAGEATTACHMENT_LIST_REQUEST = '@messageattachment/list-request';
export const MESSAGEATTACHMENT_LIST_REQUEST_MORE =
  '@messageattachment/list-request-more';
export const MESSAGEATTACHMENT_LIST_RECEIVE = '@messageattachment/list-receive';
export const MESSAGEATTACHMENT_LIST_APPEND = '@messageattachment/list-append';
export const MESSAGEATTACHMENT_SELECT = '@messageattachment/entity-select';
export const MESSAGEATTACHMENT_MODE = '@messageattachment/entity-mode';
export const MESSAGEATTACHMENT_REQUEST = '@messageattachment/entity-request';
export const MESSAGEATTACHMENT_RECEIVE = '@messageattachment/entity-receive';
export const MESSAGEATTACHMENT_ADD = '@messageattachment/entity-add';
export const MESSAGEATTACHMENT_UPDATE = '@messageattachment/entity-update';
export const MESSAGEATTACHMENT_DELETE = '@messageattachment/entity-delete';
export const MESSAGEATTACHMENT_FINISHED = '@messageattachment/finished';

// MessageTag
export const MESSAGETAG_LABELS_GET = '@messagetag/labels-get';
export const MESSAGETAG_LABEL_SELECT = '@messagetag/label-select';
export const MESSAGETAG_NAVIGATION_SET = '@messagetag/navigation-set';
export const MESSAGETAG_LIST_REQUEST = '@messagetag/list-request';
export const MESSAGETAG_LIST_REQUEST_MORE = '@messagetag/list-request-more';
export const MESSAGETAG_LIST_RECEIVE = '@messagetag/list-receive';
export const MESSAGETAG_LIST_APPEND = '@messagetag/list-append';
export const MESSAGETAG_SELECT = '@messagetag/entity-select';
export const MESSAGETAG_MODE = '@messagetag/entity-mode';
export const MESSAGETAG_REQUEST = '@messagetag/entity-request';
export const MESSAGETAG_RECEIVE = '@messagetag/entity-receive';
export const MESSAGETAG_ADD = '@messagetag/entity-add';
export const MESSAGETAG_UPDATE = '@messagetag/entity-update';
export const MESSAGETAG_DELETE = '@messagetag/entity-delete';
export const MESSAGETAG_FINISHED = '@messagetag/finished';

// Newsletter
export const NEWSLETTER_LABELS_GET = '@newsletter/labels-get';
export const NEWSLETTER_LABEL_SELECT = '@newsletter/label-select';
export const NEWSLETTER_NAVIGATION_SET = '@newsletter/navigation-set';
export const NEWSLETTER_LIST_REQUEST = '@newsletter/list-request';
export const NEWSLETTER_LIST_REQUEST_MORE = '@newsletter/list-request-more';
export const NEWSLETTER_LIST_RECEIVE = '@newsletter/list-receive';
export const NEWSLETTER_LIST_APPEND = '@newsletter/list-append';
export const NEWSLETTER_SELECT = '@newsletter/entity-select';
export const NEWSLETTER_MODE = '@newsletter/entity-mode';
export const NEWSLETTER_REQUEST = '@newsletter/entity-request';
export const NEWSLETTER_RECEIVE = '@newsletter/entity-receive';
export const NEWSLETTER_ADD = '@newsletter/entity-add';
export const NEWSLETTER_UPDATE = '@newsletter/entity-update';
export const NEWSLETTER_DELETE = '@newsletter/entity-delete';
export const NEWSLETTER_FINISHED = '@newsletter/finished';

// NewsletterAttachment
export const NEWSLETTERATTACHMENT_LABELS_GET =
  '@newsletterattachment/labels-get';
export const NEWSLETTERATTACHMENT_LABEL_SELECT =
  '@newsletterattachment/label-select';
export const NEWSLETTERATTACHMENT_NAVIGATION_SET =
  '@newsletterattachment/navigation-set';
export const NEWSLETTERATTACHMENT_LIST_REQUEST =
  '@newsletterattachment/list-request';
export const NEWSLETTERATTACHMENT_LIST_REQUEST_MORE =
  '@newsletterattachment/list-request-more';
export const NEWSLETTERATTACHMENT_LIST_RECEIVE =
  '@newsletterattachment/list-receive';
export const NEWSLETTERATTACHMENT_LIST_APPEND =
  '@newsletterattachment/list-append';
export const NEWSLETTERATTACHMENT_SELECT =
  '@newsletterattachment/entity-select';
export const NEWSLETTERATTACHMENT_MODE = '@newsletterattachment/entity-mode';
export const NEWSLETTERATTACHMENT_REQUEST =
  '@newsletterattachment/entity-request';
export const NEWSLETTERATTACHMENT_RECEIVE =
  '@newsletterattachment/entity-receive';
export const NEWSLETTERATTACHMENT_ADD = '@newsletterattachment/entity-add';
export const NEWSLETTERATTACHMENT_UPDATE =
  '@newsletterattachment/entity-update';
export const NEWSLETTERATTACHMENT_DELETE =
  '@newsletterattachment/entity-delete';
export const NEWSLETTERATTACHMENT_FINISHED = '@newsletterattachment/finished';

// NewsletterCat
export const NEWSLETTERCAT_LABELS_GET = '@newslettercat/labels-get';
export const NEWSLETTERCAT_LABEL_SELECT = '@newslettercat/label-select';
export const NEWSLETTERCAT_NAVIGATION_SET = '@newslettercat/navigation-set';
export const NEWSLETTERCAT_LIST_REQUEST = '@newslettercat/list-request';
export const NEWSLETTERCAT_LIST_REQUEST_MORE =
  '@newslettercat/list-request-more';
export const NEWSLETTERCAT_LIST_RECEIVE = '@newslettercat/list-receive';
export const NEWSLETTERCAT_LIST_APPEND = '@newslettercat/list-append';
export const NEWSLETTERCAT_SELECT = '@newslettercat/entity-select';
export const NEWSLETTERCAT_MODE = '@newslettercat/entity-mode';
export const NEWSLETTERCAT_REQUEST = '@newslettercat/entity-request';
export const NEWSLETTERCAT_RECEIVE = '@newslettercat/entity-receive';
export const NEWSLETTERCAT_ADD = '@newslettercat/entity-add';
export const NEWSLETTERCAT_UPDATE = '@newslettercat/entity-update';
export const NEWSLETTERCAT_DELETE = '@newslettercat/entity-delete';
export const NEWSLETTERCAT_FINISHED = '@newslettercat/finished';

// NewsletterContact
export const NEWSLETTERCONTACT_LABELS_GET = '@newslettercontact/labels-get';
export const NEWSLETTERCONTACT_LABEL_SELECT = '@newslettercontact/label-select';
export const NEWSLETTERCONTACT_NAVIGATION_SET =
  '@newslettercontact/navigation-set';
export const NEWSLETTERCONTACT_LIST_REQUEST = '@newslettercontact/list-request';
export const NEWSLETTERCONTACT_LIST_REQUEST_MORE =
  '@newslettercontact/list-request-more';
export const NEWSLETTERCONTACT_LIST_RECEIVE = '@newslettercontact/list-receive';
export const NEWSLETTERCONTACT_LIST_APPEND = '@newslettercontact/list-append';
export const NEWSLETTERCONTACT_SELECT = '@newslettercontact/entity-select';
export const NEWSLETTERCONTACT_MODE = '@newslettercontact/entity-mode';
export const NEWSLETTERCONTACT_REQUEST = '@newslettercontact/entity-request';
export const NEWSLETTERCONTACT_RECEIVE = '@newslettercontact/entity-receive';
export const NEWSLETTERCONTACT_ADD = '@newslettercontact/entity-add';
export const NEWSLETTERCONTACT_UPDATE = '@newslettercontact/entity-update';
export const NEWSLETTERCONTACT_DELETE = '@newslettercontact/entity-delete';
export const NEWSLETTERCONTACT_FINISHED = '@newslettercontact/finished';

// NewsletterGroup
export const NEWSLETTERGROUP_LABELS_GET = '@newslettergroup/labels-get';
export const NEWSLETTERGROUP_LABEL_SELECT = '@newslettergroup/label-select';
export const NEWSLETTERGROUP_NAVIGATION_SET = '@newslettergroup/navigation-set';
export const NEWSLETTERGROUP_LIST_REQUEST = '@newslettergroup/list-request';
export const NEWSLETTERGROUP_LIST_REQUEST_MORE =
  '@newslettergroup/list-request-more';
export const NEWSLETTERGROUP_LIST_RECEIVE = '@newslettergroup/list-receive';
export const NEWSLETTERGROUP_LIST_APPEND = '@newslettergroup/list-append';
export const NEWSLETTERGROUP_SELECT = '@newslettergroup/entity-select';
export const NEWSLETTERGROUP_MODE = '@newslettergroup/entity-mode';
export const NEWSLETTERGROUP_REQUEST = '@newslettergroup/entity-request';
export const NEWSLETTERGROUP_RECEIVE = '@newslettergroup/entity-receive';
export const NEWSLETTERGROUP_ADD = '@newslettergroup/entity-add';
export const NEWSLETTERGROUP_UPDATE = '@newslettergroup/entity-update';
export const NEWSLETTERGROUP_DELETE = '@newslettergroup/entity-delete';
export const NEWSLETTERGROUP_FINISHED = '@newslettergroup/finished';

// NewsletterTag
export const NEWSLETTERTAG_LABELS_GET = '@newslettertag/labels-get';
export const NEWSLETTERTAG_LABEL_SELECT = '@newslettertag/label-select';
export const NEWSLETTERTAG_NAVIGATION_SET = '@newslettertag/navigation-set';
export const NEWSLETTERTAG_LIST_REQUEST = '@newslettertag/list-request';
export const NEWSLETTERTAG_LIST_REQUEST_MORE =
  '@newslettertag/list-request-more';
export const NEWSLETTERTAG_LIST_RECEIVE = '@newslettertag/list-receive';
export const NEWSLETTERTAG_LIST_APPEND = '@newslettertag/list-append';
export const NEWSLETTERTAG_SELECT = '@newslettertag/entity-select';
export const NEWSLETTERTAG_MODE = '@newslettertag/entity-mode';
export const NEWSLETTERTAG_REQUEST = '@newslettertag/entity-request';
export const NEWSLETTERTAG_RECEIVE = '@newslettertag/entity-receive';
export const NEWSLETTERTAG_ADD = '@newslettertag/entity-add';
export const NEWSLETTERTAG_UPDATE = '@newslettertag/entity-update';
export const NEWSLETTERTAG_DELETE = '@newslettertag/entity-delete';
export const NEWSLETTERTAG_FINISHED = '@newslettertag/finished';

// Notification
export const NOTIFICATION_LABELS_GET = '@notification/labels-get';
export const NOTIFICATION_LABEL_SELECT = '@notification/label-select';
export const NOTIFICATION_NAVIGATION_SET = '@notification/navigation-set';
export const NOTIFICATION_LIST_REQUEST = '@notification/list-request';
export const NOTIFICATION_LIST_REQUEST_MORE = '@notification/list-request-more';
export const NOTIFICATION_LIST_RECEIVE = '@notification/list-receive';
export const NOTIFICATION_LIST_APPEND = '@notification/list-append';
export const NOTIFICATION_SELECT = '@notification/entity-select';
export const NOTIFICATION_MODE = '@notification/entity-mode';
export const NOTIFICATION_REQUEST = '@notification/entity-request';
export const NOTIFICATION_RECEIVE = '@notification/entity-receive';
export const NOTIFICATION_ADD = '@notification/entity-add';
export const NOTIFICATION_UPDATE = '@notification/entity-update';
export const NOTIFICATION_DELETE = '@notification/entity-delete';
export const NOTIFICATION_FINISHED = '@notification/finished';

// NotificationRun
export const NOTIFICATIONRUN_LABELS_GET = '@notificationrun/labels-get';
export const NOTIFICATIONRUN_LABEL_SELECT = '@notificationrun/label-select';
export const NOTIFICATIONRUN_NAVIGATION_SET = '@notificationrun/navigation-set';
export const NOTIFICATIONRUN_LIST_REQUEST = '@notificationrun/list-request';
export const NOTIFICATIONRUN_LIST_REQUEST_MORE =
  '@notificationrun/list-request-more';
export const NOTIFICATIONRUN_LIST_RECEIVE = '@notificationrun/list-receive';
export const NOTIFICATIONRUN_LIST_APPEND = '@notificationrun/list-append';
export const NOTIFICATIONRUN_SELECT = '@notificationrun/entity-select';
export const NOTIFICATIONRUN_MODE = '@notificationrun/entity-mode';
export const NOTIFICATIONRUN_REQUEST = '@notificationrun/entity-request';
export const NOTIFICATIONRUN_RECEIVE = '@notificationrun/entity-receive';
export const NOTIFICATIONRUN_ADD = '@notificationrun/entity-add';
export const NOTIFICATIONRUN_UPDATE = '@notificationrun/entity-update';
export const NOTIFICATIONRUN_DELETE = '@notificationrun/entity-delete';
export const NOTIFICATIONRUN_FINISHED = '@notificationrun/finished';

// OptIn
export const OPTIN_LABELS_GET = '@optin/labels-get';
export const OPTIN_LABEL_SELECT = '@optin/label-select';
export const OPTIN_NAVIGATION_SET = '@optin/navigation-set';
export const OPTIN_LIST_REQUEST = '@optin/list-request';
export const OPTIN_LIST_REQUEST_MORE = '@optin/list-request-more';
export const OPTIN_LIST_RECEIVE = '@optin/list-receive';
export const OPTIN_LIST_APPEND = '@optin/list-append';
export const OPTIN_SELECT = '@optin/entity-select';
export const OPTIN_MODE = '@optin/entity-mode';
export const OPTIN_REQUEST = '@optin/entity-request';
export const OPTIN_RECEIVE = '@optin/entity-receive';
export const OPTIN_ADD = '@optin/entity-add';
export const OPTIN_UPDATE = '@optin/entity-update';
export const OPTIN_DELETE = '@optin/entity-delete';
export const OPTIN_FINISHED = '@optin/finished';

// OptOut
export const OPTOUT_LABELS_GET = '@optout/labels-get';
export const OPTOUT_LABEL_SELECT = '@optout/label-select';
export const OPTOUT_NAVIGATION_SET = '@optout/navigation-set';
export const OPTOUT_LIST_REQUEST = '@optout/list-request';
export const OPTOUT_LIST_REQUEST_MORE = '@optout/list-request-more';
export const OPTOUT_LIST_RECEIVE = '@optout/list-receive';
export const OPTOUT_LIST_APPEND = '@optout/list-append';
export const OPTOUT_SELECT = '@optout/entity-select';
export const OPTOUT_MODE = '@optout/entity-mode';
export const OPTOUT_REQUEST = '@optout/entity-request';
export const OPTOUT_RECEIVE = '@optout/entity-receive';
export const OPTOUT_ADD = '@optout/entity-add';
export const OPTOUT_UPDATE = '@optout/entity-update';
export const OPTOUT_DELETE = '@optout/entity-delete';
export const OPTOUT_FINISHED = '@optout/finished';

// Page
export const PAGE_LABELS_GET = '@page/labels-get';
export const PAGE_LABEL_SELECT = '@page/label-select';
export const PAGE_NAVIGATION_SET = '@page/navigation-set';
export const PAGE_LIST_REQUEST = '@page/list-request';
export const PAGE_LIST_REQUEST_MORE = '@page/list-request-more';
export const PAGE_LIST_RECEIVE = '@page/list-receive';
export const PAGE_LIST_APPEND = '@page/list-append';
export const PAGE_SELECT = '@page/entity-select';
export const PAGE_MODE = '@page/entity-mode';
export const PAGE_REQUEST = '@page/entity-request';
export const PAGE_RECEIVE = '@page/entity-receive';
export const PAGE_ADD = '@page/entity-add';
export const PAGE_UPDATE = '@page/entity-update';
export const PAGE_DELETE = '@page/entity-delete';
export const PAGE_FINISHED = '@page/finished';

// PageAttachment
export const PAGEATTACHMENT_LABELS_GET = '@pageattachment/labels-get';
export const PAGEATTACHMENT_LABEL_SELECT = '@pageattachment/label-select';
export const PAGEATTACHMENT_NAVIGATION_SET = '@pageattachment/navigation-set';
export const PAGEATTACHMENT_LIST_REQUEST = '@pageattachment/list-request';
export const PAGEATTACHMENT_LIST_REQUEST_MORE =
  '@pageattachment/list-request-more';
export const PAGEATTACHMENT_LIST_RECEIVE = '@pageattachment/list-receive';
export const PAGEATTACHMENT_LIST_APPEND = '@pageattachment/list-append';
export const PAGEATTACHMENT_SELECT = '@pageattachment/entity-select';
export const PAGEATTACHMENT_MODE = '@pageattachment/entity-mode';
export const PAGEATTACHMENT_REQUEST = '@pageattachment/entity-request';
export const PAGEATTACHMENT_RECEIVE = '@pageattachment/entity-receive';
export const PAGEATTACHMENT_ADD = '@pageattachment/entity-add';
export const PAGEATTACHMENT_UPDATE = '@pageattachment/entity-update';
export const PAGEATTACHMENT_DELETE = '@pageattachment/entity-delete';
export const PAGEATTACHMENT_FINISHED = '@pageattachment/finished';

// PageCat
export const PAGECAT_LABELS_GET = '@pagecat/labels-get';
export const PAGECAT_LABEL_SELECT = '@pagecat/label-select';
export const PAGECAT_NAVIGATION_SET = '@pagecat/navigation-set';
export const PAGECAT_LIST_REQUEST = '@pagecat/list-request';
export const PAGECAT_LIST_REQUEST_MORE = '@pagecat/list-request-more';
export const PAGECAT_LIST_RECEIVE = '@pagecat/list-receive';
export const PAGECAT_LIST_APPEND = '@pagecat/list-append';
export const PAGECAT_SELECT = '@pagecat/entity-select';
export const PAGECAT_MODE = '@pagecat/entity-mode';
export const PAGECAT_REQUEST = '@pagecat/entity-request';
export const PAGECAT_RECEIVE = '@pagecat/entity-receive';
export const PAGECAT_ADD = '@pagecat/entity-add';
export const PAGECAT_UPDATE = '@pagecat/entity-update';
export const PAGECAT_DELETE = '@pagecat/entity-delete';
export const PAGECAT_FINISHED = '@pagecat/finished';

// PageTag
export const PAGETAG_LABELS_GET = '@pagetag/labels-get';
export const PAGETAG_LABEL_SELECT = '@pagetag/label-select';
export const PAGETAG_NAVIGATION_SET = '@pagetag/navigation-set';
export const PAGETAG_LIST_REQUEST = '@pagetag/list-request';
export const PAGETAG_LIST_REQUEST_MORE = '@pagetag/list-request-more';
export const PAGETAG_LIST_RECEIVE = '@pagetag/list-receive';
export const PAGETAG_LIST_APPEND = '@pagetag/list-append';
export const PAGETAG_SELECT = '@pagetag/entity-select';
export const PAGETAG_MODE = '@pagetag/entity-mode';
export const PAGETAG_REQUEST = '@pagetag/entity-request';
export const PAGETAG_RECEIVE = '@pagetag/entity-receive';
export const PAGETAG_ADD = '@pagetag/entity-add';
export const PAGETAG_UPDATE = '@pagetag/entity-update';
export const PAGETAG_DELETE = '@pagetag/entity-delete';
export const PAGETAG_FINISHED = '@pagetag/finished';

// Parent
export const PARENT_LABELS_GET = '@parent/labels-get';
export const PARENT_LABEL_SELECT = '@parent/label-select';
export const PARENT_NAVIGATION_SET = '@parent/navigation-set';
export const PARENT_LIST_REQUEST = '@parent/list-request';
export const PARENT_LIST_REQUEST_MORE = '@parent/list-request-more';
export const PARENT_LIST_RECEIVE = '@parent/list-receive';
export const PARENT_LIST_APPEND = '@parent/list-append';
export const PARENT_SELECT = '@parent/entity-select';
export const PARENT_MODE = '@parent/entity-mode';
export const PARENT_REQUEST = '@parent/entity-request';
export const PARENT_RECEIVE = '@parent/entity-receive';
export const PARENT_ADD = '@parent/entity-add';
export const PARENT_UPDATE = '@parent/entity-update';
export const PARENT_DELETE = '@parent/entity-delete';
export const PARENT_FINISHED = '@parent/finished';

// Plan
export const PLAN_LABELS_GET = '@plan/labels-get';
export const PLAN_LABEL_SELECT = '@plan/label-select';
export const PLAN_NAVIGATION_SET = '@plan/navigation-set';
export const PLAN_LIST_REQUEST = '@plan/list-request';
export const PLAN_LIST_REQUEST_MORE = '@plan/list-request-more';
export const PLAN_LIST_RECEIVE = '@plan/list-receive';
export const PLAN_LIST_APPEND = '@plan/list-append';
export const PLAN_SELECT = '@plan/entity-select';
export const PLAN_MODE = '@plan/entity-mode';
export const PLAN_REQUEST = '@plan/entity-request';
export const PLAN_RECEIVE = '@plan/entity-receive';
export const PLAN_ADD = '@plan/entity-add';
export const PLAN_UPDATE = '@plan/entity-update';
export const PLAN_DELETE = '@plan/entity-delete';
export const PLAN_FINISHED = '@plan/finished';

// Pricing
export const PRICING_LABELS_GET = '@pricing/labels-get';
export const PRICING_LABEL_SELECT = '@pricing/label-select';
export const PRICING_NAVIGATION_SET = '@pricing/navigation-set';
export const PRICING_LIST_REQUEST = '@pricing/list-request';
export const PRICING_LIST_REQUEST_MORE = '@pricing/list-request-more';
export const PRICING_LIST_RECEIVE = '@pricing/list-receive';
export const PRICING_LIST_APPEND = '@pricing/list-append';
export const PRICING_SELECT = '@pricing/entity-select';
export const PRICING_MODE = '@pricing/entity-mode';
export const PRICING_REQUEST = '@pricing/entity-request';
export const PRICING_RECEIVE = '@pricing/entity-receive';
export const PRICING_ADD = '@pricing/entity-add';
export const PRICING_UPDATE = '@pricing/entity-update';
export const PRICING_DELETE = '@pricing/entity-delete';
export const PRICING_FINISHED = '@pricing/finished';

// Product
export const PRODUCT_LABELS_GET = '@product/labels-get';
export const PRODUCT_LABEL_SELECT = '@product/label-select';
export const PRODUCT_NAVIGATION_SET = '@product/navigation-set';
export const PRODUCT_LIST_REQUEST = '@product/list-request';
export const PRODUCT_LIST_REQUEST_MORE = '@product/list-request-more';
export const PRODUCT_LIST_RECEIVE = '@product/list-receive';
export const PRODUCT_LIST_APPEND = '@product/list-append';
export const PRODUCT_SELECT = '@product/entity-select';
export const PRODUCT_MODE = '@product/entity-mode';
export const PRODUCT_REQUEST = '@product/entity-request';
export const PRODUCT_RECEIVE = '@product/entity-receive';
export const PRODUCT_ADD = '@product/entity-add';
export const PRODUCT_UPDATE = '@product/entity-update';
export const PRODUCT_DELETE = '@product/entity-delete';
export const PRODUCT_FINISHED = '@product/finished';

// ProductAttachment
export const PRODUCTATTACHMENT_LABELS_GET = '@productattachment/labels-get';
export const PRODUCTATTACHMENT_LABEL_SELECT = '@productattachment/label-select';
export const PRODUCTATTACHMENT_NAVIGATION_SET =
  '@productattachment/navigation-set';
export const PRODUCTATTACHMENT_LIST_REQUEST = '@productattachment/list-request';
export const PRODUCTATTACHMENT_LIST_REQUEST_MORE =
  '@productattachment/list-request-more';
export const PRODUCTATTACHMENT_LIST_RECEIVE = '@productattachment/list-receive';
export const PRODUCTATTACHMENT_LIST_APPEND = '@productattachment/list-append';
export const PRODUCTATTACHMENT_SELECT = '@productattachment/entity-select';
export const PRODUCTATTACHMENT_MODE = '@productattachment/entity-mode';
export const PRODUCTATTACHMENT_REQUEST = '@productattachment/entity-request';
export const PRODUCTATTACHMENT_RECEIVE = '@productattachment/entity-receive';
export const PRODUCTATTACHMENT_ADD = '@productattachment/entity-add';
export const PRODUCTATTACHMENT_UPDATE = '@productattachment/entity-update';
export const PRODUCTATTACHMENT_DELETE = '@productattachment/entity-delete';
export const PRODUCTATTACHMENT_FINISHED = '@productattachment/finished';

// ProductGroup
export const PRODUCTGROUP_LABELS_GET = '@productgroup/labels-get';
export const PRODUCTGROUP_LABEL_SELECT = '@productgroup/label-select';
export const PRODUCTGROUP_NAVIGATION_SET = '@productgroup/navigation-set';
export const PRODUCTGROUP_LIST_REQUEST = '@productgroup/list-request';
export const PRODUCTGROUP_LIST_REQUEST_MORE = '@productgroup/list-request-more';
export const PRODUCTGROUP_LIST_RECEIVE = '@productgroup/list-receive';
export const PRODUCTGROUP_LIST_APPEND = '@productgroup/list-append';
export const PRODUCTGROUP_SELECT = '@productgroup/entity-select';
export const PRODUCTGROUP_MODE = '@productgroup/entity-mode';
export const PRODUCTGROUP_REQUEST = '@productgroup/entity-request';
export const PRODUCTGROUP_RECEIVE = '@productgroup/entity-receive';
export const PRODUCTGROUP_ADD = '@productgroup/entity-add';
export const PRODUCTGROUP_UPDATE = '@productgroup/entity-update';
export const PRODUCTGROUP_DELETE = '@productgroup/entity-delete';
export const PRODUCTGROUP_FINISHED = '@productgroup/finished';

// ProductPrice
export const PRODUCTPRICE_LABELS_GET = '@productprice/labels-get';
export const PRODUCTPRICE_LABEL_SELECT = '@productprice/label-select';
export const PRODUCTPRICE_NAVIGATION_SET = '@productprice/navigation-set';
export const PRODUCTPRICE_LIST_REQUEST = '@productprice/list-request';
export const PRODUCTPRICE_LIST_REQUEST_MORE = '@productprice/list-request-more';
export const PRODUCTPRICE_LIST_RECEIVE = '@productprice/list-receive';
export const PRODUCTPRICE_LIST_APPEND = '@productprice/list-append';
export const PRODUCTPRICE_SELECT = '@productprice/entity-select';
export const PRODUCTPRICE_MODE = '@productprice/entity-mode';
export const PRODUCTPRICE_REQUEST = '@productprice/entity-request';
export const PRODUCTPRICE_RECEIVE = '@productprice/entity-receive';
export const PRODUCTPRICE_ADD = '@productprice/entity-add';
export const PRODUCTPRICE_UPDATE = '@productprice/entity-update';
export const PRODUCTPRICE_DELETE = '@productprice/entity-delete';
export const PRODUCTPRICE_FINISHED = '@productprice/finished';

// ProductTag
export const PRODUCTTAG_LABELS_GET = '@producttag/labels-get';
export const PRODUCTTAG_LABEL_SELECT = '@producttag/label-select';
export const PRODUCTTAG_NAVIGATION_SET = '@producttag/navigation-set';
export const PRODUCTTAG_LIST_REQUEST = '@producttag/list-request';
export const PRODUCTTAG_LIST_REQUEST_MORE = '@producttag/list-request-more';
export const PRODUCTTAG_LIST_RECEIVE = '@producttag/list-receive';
export const PRODUCTTAG_LIST_APPEND = '@producttag/list-append';
export const PRODUCTTAG_SELECT = '@producttag/entity-select';
export const PRODUCTTAG_MODE = '@producttag/entity-mode';
export const PRODUCTTAG_REQUEST = '@producttag/entity-request';
export const PRODUCTTAG_RECEIVE = '@producttag/entity-receive';
export const PRODUCTTAG_ADD = '@producttag/entity-add';
export const PRODUCTTAG_UPDATE = '@producttag/entity-update';
export const PRODUCTTAG_DELETE = '@producttag/entity-delete';
export const PRODUCTTAG_FINISHED = '@producttag/finished';

// ProjectAttachment
export const PROJECTATTACHMENT_LABELS_GET = '@projectattachment/labels-get';
export const PROJECTATTACHMENT_LABEL_SELECT = '@projectattachment/label-select';
export const PROJECTATTACHMENT_NAVIGATION_SET =
  '@projectattachment/navigation-set';
export const PROJECTATTACHMENT_LIST_REQUEST = '@projectattachment/list-request';
export const PROJECTATTACHMENT_LIST_REQUEST_MORE =
  '@projectattachment/list-request-more';
export const PROJECTATTACHMENT_LIST_RECEIVE = '@projectattachment/list-receive';
export const PROJECTATTACHMENT_LIST_APPEND = '@projectattachment/list-append';
export const PROJECTATTACHMENT_SELECT = '@projectattachment/entity-select';
export const PROJECTATTACHMENT_MODE = '@projectattachment/entity-mode';
export const PROJECTATTACHMENT_REQUEST = '@projectattachment/entity-request';
export const PROJECTATTACHMENT_RECEIVE = '@projectattachment/entity-receive';
export const PROJECTATTACHMENT_ADD = '@projectattachment/entity-add';
export const PROJECTATTACHMENT_UPDATE = '@projectattachment/entity-update';
export const PROJECTATTACHMENT_DELETE = '@projectattachment/entity-delete';
export const PROJECTATTACHMENT_FINISHED = '@projectattachment/finished';

// ProjectCat
export const PROJECTCAT_LABELS_GET = '@projectcat/labels-get';
export const PROJECTCAT_LABEL_SELECT = '@projectcat/label-select';
export const PROJECTCAT_NAVIGATION_SET = '@projectcat/navigation-set';
export const PROJECTCAT_LIST_REQUEST = '@projectcat/list-request';
export const PROJECTCAT_LIST_REQUEST_MORE = '@projectcat/list-request-more';
export const PROJECTCAT_LIST_RECEIVE = '@projectcat/list-receive';
export const PROJECTCAT_LIST_APPEND = '@projectcat/list-append';
export const PROJECTCAT_SELECT = '@projectcat/entity-select';
export const PROJECTCAT_MODE = '@projectcat/entity-mode';
export const PROJECTCAT_REQUEST = '@projectcat/entity-request';
export const PROJECTCAT_RECEIVE = '@projectcat/entity-receive';
export const PROJECTCAT_ADD = '@projectcat/entity-add';
export const PROJECTCAT_UPDATE = '@projectcat/entity-update';
export const PROJECTCAT_DELETE = '@projectcat/entity-delete';
export const PROJECTCAT_FINISHED = '@projectcat/finished';

// ProjectGroup
export const PROJECTGROUP_LABELS_GET = '@projectgroup/labels-get';
export const PROJECTGROUP_LABEL_SELECT = '@projectgroup/label-select';
export const PROJECTGROUP_NAVIGATION_SET = '@projectgroup/navigation-set';
export const PROJECTGROUP_LIST_REQUEST = '@projectgroup/list-request';
export const PROJECTGROUP_LIST_REQUEST_MORE = '@projectgroup/list-request-more';
export const PROJECTGROUP_LIST_RECEIVE = '@projectgroup/list-receive';
export const PROJECTGROUP_LIST_APPEND = '@projectgroup/list-append';
export const PROJECTGROUP_SELECT = '@projectgroup/entity-select';
export const PROJECTGROUP_MODE = '@projectgroup/entity-mode';
export const PROJECTGROUP_REQUEST = '@projectgroup/entity-request';
export const PROJECTGROUP_RECEIVE = '@projectgroup/entity-receive';
export const PROJECTGROUP_ADD = '@projectgroup/entity-add';
export const PROJECTGROUP_UPDATE = '@projectgroup/entity-update';
export const PROJECTGROUP_DELETE = '@projectgroup/entity-delete';
export const PROJECTGROUP_FINISHED = '@projectgroup/finished';

// ProjectTag
export const PROJECTTAG_LABELS_GET = '@projecttag/labels-get';
export const PROJECTTAG_LABEL_SELECT = '@projecttag/label-select';
export const PROJECTTAG_NAVIGATION_SET = '@projecttag/navigation-set';
export const PROJECTTAG_LIST_REQUEST = '@projecttag/list-request';
export const PROJECTTAG_LIST_REQUEST_MORE = '@projecttag/list-request-more';
export const PROJECTTAG_LIST_RECEIVE = '@projecttag/list-receive';
export const PROJECTTAG_LIST_APPEND = '@projecttag/list-append';
export const PROJECTTAG_SELECT = '@projecttag/entity-select';
export const PROJECTTAG_MODE = '@projecttag/entity-mode';
export const PROJECTTAG_REQUEST = '@projecttag/entity-request';
export const PROJECTTAG_RECEIVE = '@projecttag/entity-receive';
export const PROJECTTAG_ADD = '@projecttag/entity-add';
export const PROJECTTAG_UPDATE = '@projecttag/entity-update';
export const PROJECTTAG_DELETE = '@projecttag/entity-delete';
export const PROJECTTAG_FINISHED = '@projecttag/finished';

// PurchaseContract
export const PURCHASECONTRACT_LABELS_GET = '@purchasecontract/labels-get';
export const PURCHASECONTRACT_LABEL_SELECT = '@purchasecontract/label-select';
export const PURCHASECONTRACT_NAVIGATION_SET =
  '@purchasecontract/navigation-set';
export const PURCHASECONTRACT_LIST_REQUEST = '@purchasecontract/list-request';
export const PURCHASECONTRACT_LIST_REQUEST_MORE =
  '@purchasecontract/list-request-more';
export const PURCHASECONTRACT_LIST_RECEIVE = '@purchasecontract/list-receive';
export const PURCHASECONTRACT_LIST_APPEND = '@purchasecontract/list-append';
export const PURCHASECONTRACT_SELECT = '@purchasecontract/entity-select';
export const PURCHASECONTRACT_MODE = '@purchasecontract/entity-mode';
export const PURCHASECONTRACT_REQUEST = '@purchasecontract/entity-request';
export const PURCHASECONTRACT_RECEIVE = '@purchasecontract/entity-receive';
export const PURCHASECONTRACT_ADD = '@purchasecontract/entity-add';
export const PURCHASECONTRACT_UPDATE = '@purchasecontract/entity-update';
export const PURCHASECONTRACT_DELETE = '@purchasecontract/entity-delete';
export const PURCHASECONTRACT_FINISHED = '@purchasecontract/finished';

// PurchaseContractAttachment
export const PURCHASECONTRACTATTACHMENT_LABELS_GET =
  '@purchasecontractattachment/labels-get';
export const PURCHASECONTRACTATTACHMENT_LABEL_SELECT =
  '@purchasecontractattachment/label-select';
export const PURCHASECONTRACTATTACHMENT_NAVIGATION_SET =
  '@purchasecontractattachment/navigation-set';
export const PURCHASECONTRACTATTACHMENT_LIST_REQUEST =
  '@purchasecontractattachment/list-request';
export const PURCHASECONTRACTATTACHMENT_LIST_REQUEST_MORE =
  '@purchasecontractattachment/list-request-more';
export const PURCHASECONTRACTATTACHMENT_LIST_RECEIVE =
  '@purchasecontractattachment/list-receive';
export const PURCHASECONTRACTATTACHMENT_LIST_APPEND =
  '@purchasecontractattachment/list-append';
export const PURCHASECONTRACTATTACHMENT_SELECT =
  '@purchasecontractattachment/entity-select';
export const PURCHASECONTRACTATTACHMENT_MODE =
  '@purchasecontractattachment/entity-mode';
export const PURCHASECONTRACTATTACHMENT_REQUEST =
  '@purchasecontractattachment/entity-request';
export const PURCHASECONTRACTATTACHMENT_RECEIVE =
  '@purchasecontractattachment/entity-receive';
export const PURCHASECONTRACTATTACHMENT_ADD =
  '@purchasecontractattachment/entity-add';
export const PURCHASECONTRACTATTACHMENT_UPDATE =
  '@purchasecontractattachment/entity-update';
export const PURCHASECONTRACTATTACHMENT_DELETE =
  '@purchasecontractattachment/entity-delete';
export const PURCHASECONTRACTATTACHMENT_FINISHED =
  '@purchasecontractattachment/finished';

// PurchaseContractLine
export const PURCHASECONTRACTLINE_LABELS_GET =
  '@purchasecontractline/labels-get';
export const PURCHASECONTRACTLINE_LABEL_SELECT =
  '@purchasecontractline/label-select';
export const PURCHASECONTRACTLINE_NAVIGATION_SET =
  '@purchasecontractline/navigation-set';
export const PURCHASECONTRACTLINE_LIST_REQUEST =
  '@purchasecontractline/list-request';
export const PURCHASECONTRACTLINE_LIST_REQUEST_MORE =
  '@purchasecontractline/list-request-more';
export const PURCHASECONTRACTLINE_LIST_RECEIVE =
  '@purchasecontractline/list-receive';
export const PURCHASECONTRACTLINE_LIST_APPEND =
  '@purchasecontractline/list-append';
export const PURCHASECONTRACTLINE_SELECT =
  '@purchasecontractline/entity-select';
export const PURCHASECONTRACTLINE_MODE = '@purchasecontractline/entity-mode';
export const PURCHASECONTRACTLINE_REQUEST =
  '@purchasecontractline/entity-request';
export const PURCHASECONTRACTLINE_RECEIVE =
  '@purchasecontractline/entity-receive';
export const PURCHASECONTRACTLINE_ADD = '@purchasecontractline/entity-add';
export const PURCHASECONTRACTLINE_UPDATE =
  '@purchasecontractline/entity-update';
export const PURCHASECONTRACTLINE_DELETE =
  '@purchasecontractline/entity-delete';
export const PURCHASECONTRACTLINE_FINISHED = '@purchasecontractline/finished';

// PurchaseDocAttachment
export const PURCHASEDOCATTACHMENT_LABELS_GET =
  '@purchasedocattachment/labels-get';
export const PURCHASEDOCATTACHMENT_LABEL_SELECT =
  '@purchasedocattachment/label-select';
export const PURCHASEDOCATTACHMENT_NAVIGATION_SET =
  '@purchasedocattachment/navigation-set';
export const PURCHASEDOCATTACHMENT_LIST_REQUEST =
  '@purchasedocattachment/list-request';
export const PURCHASEDOCATTACHMENT_LIST_REQUEST_MORE =
  '@purchasedocattachment/list-request-more';
export const PURCHASEDOCATTACHMENT_LIST_RECEIVE =
  '@purchasedocattachment/list-receive';
export const PURCHASEDOCATTACHMENT_LIST_APPEND =
  '@purchasedocattachment/list-append';
export const PURCHASEDOCATTACHMENT_SELECT =
  '@purchasedocattachment/entity-select';
export const PURCHASEDOCATTACHMENT_MODE = '@purchasedocattachment/entity-mode';
export const PURCHASEDOCATTACHMENT_REQUEST =
  '@purchasedocattachment/entity-request';
export const PURCHASEDOCATTACHMENT_RECEIVE =
  '@purchasedocattachment/entity-receive';
export const PURCHASEDOCATTACHMENT_ADD = '@purchasedocattachment/entity-add';
export const PURCHASEDOCATTACHMENT_UPDATE =
  '@purchasedocattachment/entity-update';
export const PURCHASEDOCATTACHMENT_DELETE =
  '@purchasedocattachment/entity-delete';
export const PURCHASEDOCATTACHMENT_FINISHED = '@purchasedocattachment/finished';

// PurchaseDocLine
export const PURCHASEDOCLINE_LABELS_GET = '@purchasedocline/labels-get';
export const PURCHASEDOCLINE_LABEL_SELECT = '@purchasedocline/label-select';
export const PURCHASEDOCLINE_NAVIGATION_SET = '@purchasedocline/navigation-set';
export const PURCHASEDOCLINE_LIST_REQUEST = '@purchasedocline/list-request';
export const PURCHASEDOCLINE_LIST_REQUEST_MORE =
  '@purchasedocline/list-request-more';
export const PURCHASEDOCLINE_LIST_RECEIVE = '@purchasedocline/list-receive';
export const PURCHASEDOCLINE_LIST_APPEND = '@purchasedocline/list-append';
export const PURCHASEDOCLINE_SELECT = '@purchasedocline/entity-select';
export const PURCHASEDOCLINE_MODE = '@purchasedocline/entity-mode';
export const PURCHASEDOCLINE_REQUEST = '@purchasedocline/entity-request';
export const PURCHASEDOCLINE_RECEIVE = '@purchasedocline/entity-receive';
export const PURCHASEDOCLINE_ADD = '@purchasedocline/entity-add';
export const PURCHASEDOCLINE_UPDATE = '@purchasedocline/entity-update';
export const PURCHASEDOCLINE_DELETE = '@purchasedocline/entity-delete';
export const PURCHASEDOCLINE_FINISHED = '@purchasedocline/finished';

// Rating
export const RATING_LABELS_GET = '@rating/labels-get';
export const RATING_LABEL_SELECT = '@rating/label-select';
export const RATING_NAVIGATION_SET = '@rating/navigation-set';
export const RATING_LIST_REQUEST = '@rating/list-request';
export const RATING_LIST_REQUEST_MORE = '@rating/list-request-more';
export const RATING_LIST_RECEIVE = '@rating/list-receive';
export const RATING_LIST_APPEND = '@rating/list-append';
export const RATING_SELECT = '@rating/entity-select';
export const RATING_MODE = '@rating/entity-mode';
export const RATING_REQUEST = '@rating/entity-request';
export const RATING_RECEIVE = '@rating/entity-receive';
export const RATING_ADD = '@rating/entity-add';
export const RATING_UPDATE = '@rating/entity-update';
export const RATING_DELETE = '@rating/entity-delete';
export const RATING_FINISHED = '@rating/finished';

// Receiver
export const RECEIVER_LABELS_GET = '@receiver/labels-get';
export const RECEIVER_LABEL_SELECT = '@receiver/label-select';
export const RECEIVER_NAVIGATION_SET = '@receiver/navigation-set';
export const RECEIVER_LIST_REQUEST = '@receiver/list-request';
export const RECEIVER_LIST_REQUEST_MORE = '@receiver/list-request-more';
export const RECEIVER_LIST_RECEIVE = '@receiver/list-receive';
export const RECEIVER_LIST_APPEND = '@receiver/list-append';
export const RECEIVER_SELECT = '@receiver/entity-select';
export const RECEIVER_MODE = '@receiver/entity-mode';
export const RECEIVER_REQUEST = '@receiver/entity-request';
export const RECEIVER_RECEIVE = '@receiver/entity-receive';
export const RECEIVER_ADD = '@receiver/entity-add';
export const RECEIVER_UPDATE = '@receiver/entity-update';
export const RECEIVER_DELETE = '@receiver/entity-delete';
export const RECEIVER_FINISHED = '@receiver/finished';

// Relation
export const RELATION_LABELS_GET = '@relation/labels-get';
export const RELATION_LABEL_SELECT = '@relation/label-select';
export const RELATION_NAVIGATION_SET = '@relation/navigation-set';
export const RELATION_LIST_REQUEST = '@relation/list-request';
export const RELATION_LIST_REQUEST_MORE = '@relation/list-request-more';
export const RELATION_LIST_RECEIVE = '@relation/list-receive';
export const RELATION_LIST_APPEND = '@relation/list-append';
export const RELATION_SELECT = '@relation/entity-select';
export const RELATION_MODE = '@relation/entity-mode';
export const RELATION_REQUEST = '@relation/entity-request';
export const RELATION_RECEIVE = '@relation/entity-receive';
export const RELATION_ADD = '@relation/entity-add';
export const RELATION_UPDATE = '@relation/entity-update';
export const RELATION_DELETE = '@relation/entity-delete';
export const RELATION_FINISHED = '@relation/finished';

// ResourceAttachment
export const RESOURCEATTACHMENT_LABELS_GET = '@resourceattachment/labels-get';
export const RESOURCEATTACHMENT_LABEL_SELECT =
  '@resourceattachment/label-select';
export const RESOURCEATTACHMENT_NAVIGATION_SET =
  '@resourceattachment/navigation-set';
export const RESOURCEATTACHMENT_LIST_REQUEST =
  '@resourceattachment/list-request';
export const RESOURCEATTACHMENT_LIST_REQUEST_MORE =
  '@resourceattachment/list-request-more';
export const RESOURCEATTACHMENT_LIST_RECEIVE =
  '@resourceattachment/list-receive';
export const RESOURCEATTACHMENT_LIST_APPEND = '@resourceattachment/list-append';
export const RESOURCEATTACHMENT_SELECT = '@resourceattachment/entity-select';
export const RESOURCEATTACHMENT_MODE = '@resourceattachment/entity-mode';
export const RESOURCEATTACHMENT_REQUEST = '@resourceattachment/entity-request';
export const RESOURCEATTACHMENT_RECEIVE = '@resourceattachment/entity-receive';
export const RESOURCEATTACHMENT_ADD = '@resourceattachment/entity-add';
export const RESOURCEATTACHMENT_UPDATE = '@resourceattachment/entity-update';
export const RESOURCEATTACHMENT_DELETE = '@resourceattachment/entity-delete';
export const RESOURCEATTACHMENT_FINISHED = '@resourceattachment/finished';

// ResourceCat
export const RESOURCECAT_LABELS_GET = '@resourcecat/labels-get';
export const RESOURCECAT_LABEL_SELECT = '@resourcecat/label-select';
export const RESOURCECAT_NAVIGATION_SET = '@resourcecat/navigation-set';
export const RESOURCECAT_LIST_REQUEST = '@resourcecat/list-request';
export const RESOURCECAT_LIST_REQUEST_MORE = '@resourcecat/list-request-more';
export const RESOURCECAT_LIST_RECEIVE = '@resourcecat/list-receive';
export const RESOURCECAT_LIST_APPEND = '@resourcecat/list-append';
export const RESOURCECAT_SELECT = '@resourcecat/entity-select';
export const RESOURCECAT_MODE = '@resourcecat/entity-mode';
export const RESOURCECAT_REQUEST = '@resourcecat/entity-request';
export const RESOURCECAT_RECEIVE = '@resourcecat/entity-receive';
export const RESOURCECAT_ADD = '@resourcecat/entity-add';
export const RESOURCECAT_UPDATE = '@resourcecat/entity-update';
export const RESOURCECAT_DELETE = '@resourcecat/entity-delete';
export const RESOURCECAT_FINISHED = '@resourcecat/finished';

// ResourceGroup
export const RESOURCEGROUP_LABELS_GET = '@resourcegroup/labels-get';
export const RESOURCEGROUP_LABEL_SELECT = '@resourcegroup/label-select';
export const RESOURCEGROUP_NAVIGATION_SET = '@resourcegroup/navigation-set';
export const RESOURCEGROUP_LIST_REQUEST = '@resourcegroup/list-request';
export const RESOURCEGROUP_LIST_REQUEST_MORE =
  '@resourcegroup/list-request-more';
export const RESOURCEGROUP_LIST_RECEIVE = '@resourcegroup/list-receive';
export const RESOURCEGROUP_LIST_APPEND = '@resourcegroup/list-append';
export const RESOURCEGROUP_SELECT = '@resourcegroup/entity-select';
export const RESOURCEGROUP_MODE = '@resourcegroup/entity-mode';
export const RESOURCEGROUP_REQUEST = '@resourcegroup/entity-request';
export const RESOURCEGROUP_RECEIVE = '@resourcegroup/entity-receive';
export const RESOURCEGROUP_ADD = '@resourcegroup/entity-add';
export const RESOURCEGROUP_UPDATE = '@resourcegroup/entity-update';
export const RESOURCEGROUP_DELETE = '@resourcegroup/entity-delete';
export const RESOURCEGROUP_FINISHED = '@resourcegroup/finished';

// SalesContract
export const SALESCONTRACT_LABELS_GET = '@salescontract/labels-get';
export const SALESCONTRACT_LABEL_SELECT = '@salescontract/label-select';
export const SALESCONTRACT_NAVIGATION_SET = '@salescontract/navigation-set';
export const SALESCONTRACT_LIST_REQUEST = '@salescontract/list-request';
export const SALESCONTRACT_LIST_REQUEST_MORE =
  '@salescontract/list-request-more';
export const SALESCONTRACT_LIST_RECEIVE = '@salescontract/list-receive';
export const SALESCONTRACT_LIST_APPEND = '@salescontract/list-append';
export const SALESCONTRACT_SELECT = '@salescontract/entity-select';
export const SALESCONTRACT_MODE = '@salescontract/entity-mode';
export const SALESCONTRACT_REQUEST = '@salescontract/entity-request';
export const SALESCONTRACT_RECEIVE = '@salescontract/entity-receive';
export const SALESCONTRACT_ADD = '@salescontract/entity-add';
export const SALESCONTRACT_UPDATE = '@salescontract/entity-update';
export const SALESCONTRACT_DELETE = '@salescontract/entity-delete';
export const SALESCONTRACT_FINISHED = '@salescontract/finished';

// SalesContractAttachment
export const SALESCONTRACTATTACHMENT_LABELS_GET =
  '@salescontractattachment/labels-get';
export const SALESCONTRACTATTACHMENT_LABEL_SELECT =
  '@salescontractattachment/label-select';
export const SALESCONTRACTATTACHMENT_NAVIGATION_SET =
  '@salescontractattachment/navigation-set';
export const SALESCONTRACTATTACHMENT_LIST_REQUEST =
  '@salescontractattachment/list-request';
export const SALESCONTRACTATTACHMENT_LIST_REQUEST_MORE =
  '@salescontractattachment/list-request-more';
export const SALESCONTRACTATTACHMENT_LIST_RECEIVE =
  '@salescontractattachment/list-receive';
export const SALESCONTRACTATTACHMENT_LIST_APPEND =
  '@salescontractattachment/list-append';
export const SALESCONTRACTATTACHMENT_SELECT =
  '@salescontractattachment/entity-select';
export const SALESCONTRACTATTACHMENT_MODE =
  '@salescontractattachment/entity-mode';
export const SALESCONTRACTATTACHMENT_REQUEST =
  '@salescontractattachment/entity-request';
export const SALESCONTRACTATTACHMENT_RECEIVE =
  '@salescontractattachment/entity-receive';
export const SALESCONTRACTATTACHMENT_ADD =
  '@salescontractattachment/entity-add';
export const SALESCONTRACTATTACHMENT_UPDATE =
  '@salescontractattachment/entity-update';
export const SALESCONTRACTATTACHMENT_DELETE =
  '@salescontractattachment/entity-delete';
export const SALESCONTRACTATTACHMENT_FINISHED =
  '@salescontractattachment/finished';

// SalesContractLine
export const SALESCONTRACTLINE_LABELS_GET = '@salescontractline/labels-get';
export const SALESCONTRACTLINE_LABEL_SELECT = '@salescontractline/label-select';
export const SALESCONTRACTLINE_NAVIGATION_SET =
  '@salescontractline/navigation-set';
export const SALESCONTRACTLINE_LIST_REQUEST = '@salescontractline/list-request';
export const SALESCONTRACTLINE_LIST_REQUEST_MORE =
  '@salescontractline/list-request-more';
export const SALESCONTRACTLINE_LIST_RECEIVE = '@salescontractline/list-receive';
export const SALESCONTRACTLINE_LIST_APPEND = '@salescontractline/list-append';
export const SALESCONTRACTLINE_SELECT = '@salescontractline/entity-select';
export const SALESCONTRACTLINE_MODE = '@salescontractline/entity-mode';
export const SALESCONTRACTLINE_REQUEST = '@salescontractline/entity-request';
export const SALESCONTRACTLINE_RECEIVE = '@salescontractline/entity-receive';
export const SALESCONTRACTLINE_ADD = '@salescontractline/entity-add';
export const SALESCONTRACTLINE_UPDATE = '@salescontractline/entity-update';
export const SALESCONTRACTLINE_DELETE = '@salescontractline/entity-delete';
export const SALESCONTRACTLINE_FINISHED = '@salescontractline/finished';

// SalesDocAttachment
export const SALESDOCATTACHMENT_LABELS_GET = '@salesdocattachment/labels-get';
export const SALESDOCATTACHMENT_LABEL_SELECT =
  '@salesdocattachment/label-select';
export const SALESDOCATTACHMENT_NAVIGATION_SET =
  '@salesdocattachment/navigation-set';
export const SALESDOCATTACHMENT_LIST_REQUEST =
  '@salesdocattachment/list-request';
export const SALESDOCATTACHMENT_LIST_REQUEST_MORE =
  '@salesdocattachment/list-request-more';
export const SALESDOCATTACHMENT_LIST_RECEIVE =
  '@salesdocattachment/list-receive';
export const SALESDOCATTACHMENT_LIST_APPEND = '@salesdocattachment/list-append';
export const SALESDOCATTACHMENT_SELECT = '@salesdocattachment/entity-select';
export const SALESDOCATTACHMENT_MODE = '@salesdocattachment/entity-mode';
export const SALESDOCATTACHMENT_REQUEST = '@salesdocattachment/entity-request';
export const SALESDOCATTACHMENT_RECEIVE = '@salesdocattachment/entity-receive';
export const SALESDOCATTACHMENT_ADD = '@salesdocattachment/entity-add';
export const SALESDOCATTACHMENT_UPDATE = '@salesdocattachment/entity-update';
export const SALESDOCATTACHMENT_DELETE = '@salesdocattachment/entity-delete';
export const SALESDOCATTACHMENT_FINISHED = '@salesdocattachment/finished';

// SalesDocLine
export const SALESDOCLINE_LABELS_GET = '@salesdocline/labels-get';
export const SALESDOCLINE_LABEL_SELECT = '@salesdocline/label-select';
export const SALESDOCLINE_NAVIGATION_SET = '@salesdocline/navigation-set';
export const SALESDOCLINE_LIST_REQUEST = '@salesdocline/list-request';
export const SALESDOCLINE_LIST_REQUEST_MORE = '@salesdocline/list-request-more';
export const SALESDOCLINE_LIST_RECEIVE = '@salesdocline/list-receive';
export const SALESDOCLINE_LIST_APPEND = '@salesdocline/list-append';
export const SALESDOCLINE_SELECT = '@salesdocline/entity-select';
export const SALESDOCLINE_MODE = '@salesdocline/entity-mode';
export const SALESDOCLINE_REQUEST = '@salesdocline/entity-request';
export const SALESDOCLINE_RECEIVE = '@salesdocline/entity-receive';
export const SALESDOCLINE_ADD = '@salesdocline/entity-add';
export const SALESDOCLINE_UPDATE = '@salesdocline/entity-update';
export const SALESDOCLINE_DELETE = '@salesdocline/entity-delete';
export const SALESDOCLINE_FINISHED = '@salesdocline/finished';

// Site
export const SITE_LABELS_GET = '@site/labels-get';
export const SITE_LABEL_SELECT = '@site/label-select';
export const SITE_NAVIGATION_SET = '@site/navigation-set';
export const SITE_LIST_REQUEST = '@site/list-request';
export const SITE_LIST_REQUEST_MORE = '@site/list-request-more';
export const SITE_LIST_RECEIVE = '@site/list-receive';
export const SITE_LIST_APPEND = '@site/list-append';
export const SITE_SELECT = '@site/entity-select';
export const SITE_MODE = '@site/entity-mode';
export const SITE_REQUEST = '@site/entity-request';
export const SITE_RECEIVE = '@site/entity-receive';
export const SITE_ADD = '@site/entity-add';
export const SITE_UPDATE = '@site/entity-update';
export const SITE_DELETE = '@site/entity-delete';
export const SITE_FINISHED = '@site/finished';

// Skill
export const SKILL_LABELS_GET = '@skill/labels-get';
export const SKILL_LABEL_SELECT = '@skill/label-select';
export const SKILL_NAVIGATION_SET = '@skill/navigation-set';
export const SKILL_LIST_REQUEST = '@skill/list-request';
export const SKILL_LIST_REQUEST_MORE = '@skill/list-request-more';
export const SKILL_LIST_RECEIVE = '@skill/list-receive';
export const SKILL_LIST_APPEND = '@skill/list-append';
export const SKILL_SELECT = '@skill/entity-select';
export const SKILL_MODE = '@skill/entity-mode';
export const SKILL_REQUEST = '@skill/entity-request';
export const SKILL_RECEIVE = '@skill/entity-receive';
export const SKILL_ADD = '@skill/entity-add';
export const SKILL_UPDATE = '@skill/entity-update';
export const SKILL_DELETE = '@skill/entity-delete';
export const SKILL_FINISHED = '@skill/finished';

// Tag
export const TAG_LABELS_GET = '@tag/labels-get';
export const TAG_LABEL_SELECT = '@tag/label-select';
export const TAG_NAVIGATION_SET = '@tag/navigation-set';
export const TAG_LIST_REQUEST = '@tag/list-request';
export const TAG_LIST_REQUEST_MORE = '@tag/list-request-more';
export const TAG_LIST_RECEIVE = '@tag/list-receive';
export const TAG_LIST_APPEND = '@tag/list-append';
export const TAG_SELECT = '@tag/entity-select';
export const TAG_MODE = '@tag/entity-mode';
export const TAG_REQUEST = '@tag/entity-request';
export const TAG_RECEIVE = '@tag/entity-receive';
export const TAG_ADD = '@tag/entity-add';
export const TAG_UPDATE = '@tag/entity-update';
export const TAG_DELETE = '@tag/entity-delete';
export const TAG_FINISHED = '@tag/finished';

// TaskAttachment
export const TASKATTACHMENT_LABELS_GET = '@taskattachment/labels-get';
export const TASKATTACHMENT_LABEL_SELECT = '@taskattachment/label-select';
export const TASKATTACHMENT_NAVIGATION_SET = '@taskattachment/navigation-set';
export const TASKATTACHMENT_LIST_REQUEST = '@taskattachment/list-request';
export const TASKATTACHMENT_LIST_REQUEST_MORE =
  '@taskattachment/list-request-more';
export const TASKATTACHMENT_LIST_RECEIVE = '@taskattachment/list-receive';
export const TASKATTACHMENT_LIST_APPEND = '@taskattachment/list-append';
export const TASKATTACHMENT_SELECT = '@taskattachment/entity-select';
export const TASKATTACHMENT_MODE = '@taskattachment/entity-mode';
export const TASKATTACHMENT_REQUEST = '@taskattachment/entity-request';
export const TASKATTACHMENT_RECEIVE = '@taskattachment/entity-receive';
export const TASKATTACHMENT_ADD = '@taskattachment/entity-add';
export const TASKATTACHMENT_UPDATE = '@taskattachment/entity-update';
export const TASKATTACHMENT_DELETE = '@taskattachment/entity-delete';
export const TASKATTACHMENT_FINISHED = '@taskattachment/finished';

// TaskTag
export const TASKTAG_LABELS_GET = '@tasktag/labels-get';
export const TASKTAG_LABEL_SELECT = '@tasktag/label-select';
export const TASKTAG_NAVIGATION_SET = '@tasktag/navigation-set';
export const TASKTAG_LIST_REQUEST = '@tasktag/list-request';
export const TASKTAG_LIST_REQUEST_MORE = '@tasktag/list-request-more';
export const TASKTAG_LIST_RECEIVE = '@tasktag/list-receive';
export const TASKTAG_LIST_APPEND = '@tasktag/list-append';
export const TASKTAG_SELECT = '@tasktag/entity-select';
export const TASKTAG_MODE = '@tasktag/entity-mode';
export const TASKTAG_REQUEST = '@tasktag/entity-request';
export const TASKTAG_RECEIVE = '@tasktag/entity-receive';
export const TASKTAG_ADD = '@tasktag/entity-add';
export const TASKTAG_UPDATE = '@tasktag/entity-update';
export const TASKTAG_DELETE = '@tasktag/entity-delete';
export const TASKTAG_FINISHED = '@tasktag/finished';

// Thread
export const THREAD_LABELS_GET = '@thread/labels-get';
export const THREAD_LABEL_SELECT = '@thread/label-select';
export const THREAD_NAVIGATION_SET = '@thread/navigation-set';
export const THREAD_LIST_REQUEST = '@thread/list-request';
export const THREAD_LIST_REQUEST_MORE = '@thread/list-request-more';
export const THREAD_LIST_RECEIVE = '@thread/list-receive';
export const THREAD_LIST_APPEND = '@thread/list-append';
export const THREAD_SELECT = '@thread/entity-select';
export const THREAD_MODE = '@thread/entity-mode';
export const THREAD_REQUEST = '@thread/entity-request';
export const THREAD_RECEIVE = '@thread/entity-receive';
export const THREAD_ADD = '@thread/entity-add';
export const THREAD_UPDATE = '@thread/entity-update';
export const THREAD_DELETE = '@thread/entity-delete';
export const THREAD_FINISHED = '@thread/finished';

// WorkModel
export const WORKMODEL_LABELS_GET = '@workmodel/labels-get';
export const WORKMODEL_LABEL_SELECT = '@workmodel/label-select';
export const WORKMODEL_NAVIGATION_SET = '@workmodel/navigation-set';
export const WORKMODEL_LIST_REQUEST = '@workmodel/list-request';
export const WORKMODEL_LIST_REQUEST_MORE = '@workmodel/list-request-more';
export const WORKMODEL_LIST_RECEIVE = '@workmodel/list-receive';
export const WORKMODEL_LIST_APPEND = '@workmodel/list-append';
export const WORKMODEL_SELECT = '@workmodel/entity-select';
export const WORKMODEL_MODE = '@workmodel/entity-mode';
export const WORKMODEL_REQUEST = '@workmodel/entity-request';
export const WORKMODEL_RECEIVE = '@workmodel/entity-receive';
export const WORKMODEL_ADD = '@workmodel/entity-add';
export const WORKMODEL_UPDATE = '@workmodel/entity-update';
export const WORKMODEL_DELETE = '@workmodel/entity-delete';
export const WORKMODEL_FINISHED = '@workmodel/finished';
