import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const RoutesConfig = [
  //#region SETTINGS

  //#region -- PROFILE
  {
    exact: true,
    path: '/app/account/profile/edit',
    component: lazy(() => import('src/views/account/profile/EditView'))
  },
  {
    exact: true,
    path: '/app/account/profile/edit-address',
    component: lazy(() => import('src/views/account/profile/EditView/Address'))
  },
  {
    exact: true,
    path: '/app/account/profile/edit-bio',
    component: lazy(() => import('src/views/account/profile/EditView/Bio'))
  },
  {
    exact: true,
    path: '/app/account/profile/view',
    component: lazy(() => import('src/views/account/profile/DetailsView'))
  },
  //#endregion

  //#region -- ORGANIZATION
  {
    exact: true,
    path: '/app/account/organization/edit',
    component: lazy(() => import('src/views/account/organization/EditView'))
  },
  {
    exact: true,
    path: '/app/account/organization/edit-address',
    component: lazy(() =>
      import('src/views/account/organization/EditView/Address')
    )
  },
  {
    exact: true,
    path: '/app/account/organization/edit-payment',
    component: lazy(() =>
      import('src/views/account/organization/EditView/Payment')
    )
  },
  {
    exact: true,
    path: '/app/account/organization/edit-numbering',
    component: lazy(() =>
      import('src/views/account/organization/EditView/Numbering')
    )
  },
  {
    exact: true,
    path: '/app/account/organization/edit-media',
    component: lazy(() =>
      import('src/views/account/organization/EditView/Media')
    )
  },
  {
    exact: true,
    path: '/app/account/organization/edit-description',
    component: lazy(() =>
      import('src/views/account/organization/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/account/organization/view',
    component: lazy(() => import('src/views/account/organization/DetailsView'))
  },
  //#endregion

  //#endregion

  //#region GENERAL
  {
    exact: true,
    path: '/app',
    component: () => <Redirect to="/home" />
  },
  {
    component: () => <Redirect to="/404" />
  }
  //#endregion
];

export default RoutesConfig;
