export const EntityMode = {
  list: 'list',
  add: 'add',
  view: 'view',
  edit: 'edit',
  delete: 'delete'
};

export const EntityAction = {
  select: 'select',
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete'
};

export const SystemEntity = {
  /**
   * INHERITANCE functions
   */

  // Page
  post: 'post',
  posting: 'posting',
  event: 'event',
  action: 'action',
  article: 'article',
  single: 'single',

  // Resource
  resource: 'resource',
  device: 'device',
  location: 'location',
  person: 'person',

  // Project
  project: 'project',
  abonnement: 'abonnement',
  business: 'business',
  //event: 'event',  >> see Event Page
  semester: 'semester',
  solution: 'solution',
  travel: 'travel',

  // Job
  job: 'job',
  bug: 'bug',
  case: 'case',
  complaint: 'complaint',
  course: 'course',
  feature: 'feature',
  idea: 'idea',
  requirement: 'requirement',
  service: 'service',
  show: 'show',
  tour: 'tour',

  // Task
  task: 'task',
  lesson: 'lesson',
  seat: 'seat',
  session: 'session',

  // SalesDoc
  salesdoc: 'salesdoc',
  salesquote: 'salesquote',
  salesorder: 'salesorder',
  salesinvoice: 'salesinvoice',

  // PurchaseDoc
  purchasedoc: 'purchasedoc',
  purchasequote: 'purchasequote',
  purchaseorder: 'purchaseorder',
  purchaseinvoice: 'purchaseinvoice',

  /**
   * SYSTEM functions
   */

  application: 'application',
  appservice: 'appservice',
  country: 'country',
  currency: 'currency',
  deployment: 'deployment',
  deploymentitem: 'deploymentitem',
  function: 'function',
  language: 'language',
  revision: 'revision',
  servicefunction: 'servicefunction',
  servicemodule: 'servicemodule',
  system: 'system',
  webhook: 'webhook',

  /**
   * TENANT functions
   */

  appointment: 'appointment',
  contract: 'contract',
  license: 'license',
  login: 'login',
  member: 'member',
  organization: 'organization',
  tenant: 'tenant',
  user: 'user',

  /**
   * ORGANIZATION functions
   */

  absence: 'absence',
  absencetype: 'absencetype',
  actioncontact: 'actioncontact',
  activity: 'activity',
  activityattachment: 'activityattachment',
  advice: 'advice',
  adviceattachment: 'adviceattachment',
  area: 'area',
  assignment: 'assignment',
  assignmentattachment: 'assignmentattachment',
  assortment: 'assortment',
  assortmentproduct: 'assortmentproduct',
  attachment: 'attachment',
  attend: 'attend',
  attendattachment: 'attendattachment',
  attendee: 'attendee',
  attendeeattachment: 'attendeeattachment',
  attendeecat: 'attendeecat',
  author: 'author',
  billing: 'billing',
  billingrun: 'billingrun',
  blog: 'blog',
  booking: 'booking',
  bookingattachment: 'bookingattachment',
  bundle: 'bundle',
  businesspartner: 'businesspartner',
  businesspartnerattachment: 'businesspartnerattachment',
  businesspartnercat: 'businesspartnercat',
  businesspartnergroup: 'businesspartnergroup',
  calendar: 'calendar',
  capacity: 'capacity',
  catalog: 'catalog',
  channel: 'channel',
  channelsync: 'channelsync',
  channeluser: 'channeluser',
  chapter: 'chapter',
  comment: 'comment',
  commentattachment: 'commentattachment',
  competence: 'competence',
  consumption: 'consumption',
  contact: 'contact',
  contactattachment: 'contactattachment',
  data: 'data',
  delegate: 'delegate',
  delegatesite: 'delegatesite',
  department: 'department',
  doc: 'doc',
  education: 'education',
  email: 'email',
  emailattachment: 'emailattachment',
  emailstate: 'emailstate',
  employee: 'employee',
  employeeattachment: 'employeeattachment',
  eventcat: 'eventcat',
  follow: 'follow',
  forum: 'forum',
  grade: 'grade',
  guide: 'guide',
  guideline: 'guideline',
  installation: 'installation',
  institute: 'institute',
  iteration: 'iteration',
  jobattachment: 'jobattachment',
  jobcat: 'jobcat',
  jobgroup: 'jobgroup',
  jobrelation: 'jobrelation',
  jobrelationtype: 'jobrelationtype',
  jobtag: 'jobtag',
  mailinglist: 'mailinglist',
  mailinglistcontact: 'mailinglistcontact',
  menu: 'menu',
  menuitem: 'menuitem',
  message: 'message',
  messageattachment: 'messageattachment',
  messagetag: 'messagetag',
  newsletter: 'newsletter',
  newsletterattachment: 'newsletterattachment',
  newslettercat: 'newslettercat',
  newslettercontact: 'newslettercontact',
  newslettergroup: 'newslettergroup',
  newslettertag: 'newslettertag',
  notification: 'notification',
  notificationrun: 'notificationrun',
  optin: 'optin',
  optout: 'optout',
  page: 'page',
  pageattachment: 'pageattachment',
  pagecat: 'pagecat',
  pagetag: 'pagetag',
  parent: 'parent',
  plan: 'plan',
  pricing: 'pricing',
  product: 'product',
  productattachment: 'productattachment',
  productgroup: 'productgroup',
  productprice: 'productprice',
  producttag: 'producttag',
  projectattachment: 'projectattachment',
  projectcat: 'projectcat',
  projectgroup: 'projectgroup',
  projecttag: 'projecttag',
  purchasecontract: 'purchasecontract',
  purchasecontractattachment: 'purchasecontractattachment',
  purchasecontractline: 'purchasecontractline',
  purchasedocattachment: 'purchasedocattachment',
  purchasedocline: 'purchasedocline',
  rating: 'rating',
  receiver: 'receiver',
  relation: 'relation',
  resourceattachment: 'resourceattachment',
  resourcecat: 'resourcecat',
  resourcegroup: 'resourcegroup',
  salescontract: 'salescontract',
  salescontractattachment: 'salescontractattachment',
  salescontractline: 'salescontractline',
  salesdocattachment: 'salesdocattachment',
  salesdocline: 'salesdocline',
  site: 'site',
  skill: 'skill',
  tag: 'tag',
  taskattachment: 'taskattachment',
  tasktag: 'tasktag',
  thread: 'thread',
  workmodel: 'workmodel'
};
