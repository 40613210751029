export const Localisation = {
  locale: 'de'
};

export const Calendar = {
  startDayHour: 8,
  endDayHour: 22,
  firstDayOfWeek: 1,
  workWeek: [0, 6],
  currentIndicatorInterval: 60000
};

export const Data = {
  pagelengthmax: 1000,
  pagelength: 10,
  sort: { name: 1 },
  sortCreated: { createdAt: 1 },
  sortCreatedInverse: { createdAt: -1 },
  sortAttachments: { position: 1 },
  //sortAttachments: { featured: -1, position: 1 }, >> cannot be used due to position management
  sortTasks: { start: -1 },
  sortPages: { date: -1 },
  sortPageCats: { weight: 1, name: 1 },
  sortDocuments: { createdAt: -1 },
  sortDocumentLines: { createdAt: 1 },
  sortEducations: { start: -1 }
};

export const Flags = {
  ratio: '66.6%', // 500px x 333px
  path: '/static/images/flags'
};

export const Avatars = {
  ratio: '100%', // 500px x 500px
  path: '/static/images/avatars'
};

export const FormColumnsByScreen = {
  xs: 1,
  sm: 4,
  md: 4,
  lg: 4
};

export const Form = {
  valueChange: 'input'
};

export const Editor = {
  maxContentSize: 5000000,
  draftjs: {
    toolbar: {
      options: [
        'blockType',
        'inline',
        'list',
        'textAlign',
        'link',
        'image',
        'colorPicker',
        'emoji',
        'remove',
        'history'
      ],
      image: {
        uploadEnabled: false,
        alt: { present: true, mandatory: false }
      }
    }
  }
};
