import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/link-context';

import { create } from 'jss';
import rtl from 'jss-rtl';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { createStyles, jssPreset, makeStyles } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { deDE, enUS } from '@material-ui/core/locale';
import 'moment/locale/de';

import Auth from 'src/components/Auth/Auth';
import authService from 'src/services/authService';
import { MediaProvider } from 'src/context/MediaContext';
//import CookiesNotification from 'src/components/CookiesNotification';
//import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import * as AppSettings from 'src/config/Application';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

/**
 * TODO
 * - Server: add all components in Sign-up process
 * - Landing Screen - Alpha version
 * - Welcome sreen after sign-up
 * - Send transactional emails
 * - Password reset + email + password change process
 *
 */

const httpLink = createHttpLink({ uri: AppSettings.GraphQLServer.url });
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem(`app365-api`);
  const token = authService.getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

function App() {
  useStyles();
  const { i18n } = useTranslation();
  const { settings } = useSettings();

  const actualLanguage = i18n.language;
  const muiLocalization = actualLanguage === 'de' ? deDE : enUS;
  moment.locale(actualLanguage);

  return (
    <ThemeProvider theme={createTheme(muiLocalization, settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={actualLanguage}
        >
          <SnackbarProvider maxSnack={1}>
            <ApolloProvider client={apolloClient}>
              <Router history={history}>
                <Auth>
                  <MediaProvider>
                    <ScrollReset />
                    <GoogleAnalytics />
                    {/* <CookiesNotification /> */}
                    {/* <SettingsNotification /> */}
                    <Routes />
                  </MediaProvider>
                </Auth>
              </Router>
            </ApolloProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
